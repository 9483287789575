var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',{staticClass:"h-100"},[_c('v-container',{staticClass:"fill-height",attrs:{"fluid":""}},[_c('v-row',{staticClass:"px-5",attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"pl-0 pr-0",attrs:{"cols":"12","sm":"8","md":"6","lg":"4"}},[_c('v-card',{staticClass:"pa-0 pt-2",staticStyle:{"border":"0","box-shadow":"initial"},attrs:{"elevation":"0"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","align":"center"}},[_c('v-img',{staticClass:"grey lighten-4 rounded-circle",attrs:{"max-width":"100px","max-height":"100px","src":"/img/icons/android-icon-144x144.png","alt":_vm.$store.state.app.name}})],1)],1),_c('v-row',{staticClass:"text-h6 font-weight-bold mt-4",attrs:{"align":"center","justify":"center"}},[_vm._v(" "+_vm._s(_vm.$store.state.app.name)+" ")]),_c('v-row',{staticClass:"text-h5 font-weight-bold blue--text mt-4",attrs:{"align":"center","justify":"center"}},[_vm._v(" 회원가입 ")]),_c('ValidationObserver',{ref:"observer"},[_c('v-form',{staticClass:"mt-6 pa-0",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('div',{staticClass:"mt-10",staticStyle:{"width":"100%","height":"1px"}}),_c('ValidationProvider',{attrs:{"name":"username","rules":"required|min:1|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"* 아이디","placeholder":"아이디를 입력하세요","name":"username","type":"text","counter":100,"autocomplete":"off","error-messages":errors,"disabled":_vm.processing,"outlined":"","dense":""},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"password","rules":"required|min:9|max:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"* 비밀번호","placeholder":"비밀번호를 입력하세요","name":"password","type":"password","counter":20,"autocomplete":"off","error-messages":errors,"disabled":_vm.processing,"outlined":"","dense":""},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"confirmPassword","rules":"required|password:@password|min:9|max:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"* 비밀번호 확인","placeholder":"비밀번호를 다시 입력하세요","name":"confirmPassword","type":"password","counter":20,"autocomplete":"off","error-messages":errors,"disabled":_vm.processing,"outlined":"","dense":""},model:{value:(_vm.form.confirmPassword),callback:function ($$v) {_vm.$set(_vm.form, "confirmPassword", $$v)},expression:"form.confirmPassword"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"name","rules":"required|min:1|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"* 이름","placeholder":"이름을 입력하세요","name":"name","type":"name","counter":100,"autocomplete":"off","error-messages":errors,"disabled":_vm.processing,"outlined":"","dense":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email|min:4|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"* 이메일","placeholder":"이메일 정보를 입력하세요","name":"email","type":"email","counter":100,"autocomplete":"off","error-messages":errors,"disabled":_vm.processing,"outlined":"","dense":""},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"phone","rules":"required|phone|min:12|max:13"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"* 휴대전화","placeholder":"휴대전화 번호를 입력하세요","name":"phone","type":"tel","counter":13,"autocomplete":"off","error-messages":errors,"disabled":_vm.processing,"outlined":"","dense":""},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})]}}])}),_c('v-layout',{attrs:{"row":"","wrap":"","align-center":""}},[_c('v-flex',{staticClass:"mt-6 pl-2 pr-2"},[_c('v-btn',{attrs:{"type":"submit","color":"primary","loading":_vm.processing,"disabled":_vm.processing,"min-width":"100%"}},[_vm._v("가입하기 ")]),_c('v-btn',{staticClass:"mt-1 pa-1",attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.goBack()}}},[_vm._v(" 이미 가입된 회원 정보가 있습니다. ")])],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }