






























































































import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import core from "@/core";
import DataStatusService from "@/services/datastatus/data-status.service";
import DataStatusUtils from "@/core/data-status-utils";
import { TableOrder, TableRequest } from "@/types";
import SensorService from "@/services/sensor/sensor.service";

export default mixins(MixinsPageForm).extend({
  name: "DataStatus",
  components: {},
  data: () => ({
    title: "",
    path: "",
    donutChartData: [] as any,
    lineChartData: [] as any,
    itemKeyAndLabel: {} as any,
    table: {
      selectedTypeId: "",
      selectedType: null as any,
      typeList: [] as any,
      options: [50, 100, 500, 1000, 5000, 10000],
      request: {
        draw: 0,
        start: -1,
        length: 50,
        orderColumnName: "sensingAt",
        order: "desc",
        searchColumns: {},
      } as TableRequest,
      response: {
        isMoreData: true,
        data: [] as any,
      },
    },
  }),
  mounted() {
    this.$nextTick(async () => {
      await DataStatusUtils.reload();

      this.path = this.$route.params.path;
      // console.log("path :", this.path);
      if (this.path === "smartfarm2nd") {
        this.title = "2세대 스마트팜 데이터 현황";
        this.table.typeList.push({
          id: "in",
          name: "내부",
          columns: [
            { title: "측정시간", name: "sensingAt", isTime: true },
            { title: "온도", name: "temp", isTime: false },
            { title: "습도", name: "humidity", isTime: false },
            { title: "CO2", name: "co2", isTime: false },
            { title: "풍속", name: "windDirection", isTime: false },
            { title: "일사량", name: "solarRadiation", isTime: false },
          ] as any,
        });
        this.table.typeList.push({
          id: "ext",
          name: "외부",
          columns: [
            { title: "측정시간", name: "sensingAt", isTime: true },
            { title: "온도", name: "temp", isTime: false },
            { title: "습도", name: "humidity", isTime: false },
            { title: "CO2", name: "co2", isTime: false },
            { title: "풍속", name: "windDirection", isTime: false },
            { title: "일사량", name: "solarRadiation", isTime: false },
          ] as any,
        });
        this.table.typeList.push({
          id: "ns",
          name: "양액",
          columns: [
            { title: "측정시간", name: "sensingAt", isTime: true },
            { title: "온도", name: "temp", isTime: false },
            { title: "습도", name: "humidity", isTime: false },
            { title: "CO2", name: "co2", isTime: false },
            { title: "풍속", name: "windDirection", isTime: false },
            { title: "일사량", name: "solarRadiation", isTime: false },
          ] as any,
        });
        this.table.typeList.push({
          id: "root",
          name: "근권",
          columns: [
            { title: "측정시간", name: "sensingAt", isTime: true },
            { title: "온도", name: "temp", isTime: false },
            { title: "습도", name: "humidity", isTime: false },
            { title: "CO2", name: "co2", isTime: false },
            { title: "풍속", name: "windDirection", isTime: false },
            { title: "일사량", name: "solarRadiation", isTime: false },
          ] as any,
        });
        this.table.selectedTypeId = "in";
        // this.table.response.data.push({ sensingAt: "2023-12-19T00:00:00", temp: 20, humidity: 80, co2: 10 });

        await this.getData("SMARTFARM_2ND", DataStatusUtils.items.smartfarm2nd.itemKeyAndLabel);
      } else if (this.path === "disease") {
        this.title = "병해진단 데이터 현황";
        await this.getData("DISEASE", DataStatusUtils.items.disease.itemKeyAndLabel);
      } else if (this.path === "green-house") {
        this.title = "첨단스마트온실 데이터 현황";
        await this.getData(
          "GREENHOUSE1",
          DataStatusUtils.items.highTechDigitalGreenHouse.itemKeyAndLabel
        );
      } else if (this.path === "aqua") {
        this.title = "아쿠아포닉스 데이터 현황";
        await this.getData("AQUA", DataStatusUtils.items.aqua.itemKeyAndLabel);
      } else if (this.path === "milking") {
        this.title = "축산(착유) 데이터 현황";
        await this.getData("MILKING", DataStatusUtils.items.milking.itemKeyAndLabel);
      } else if (this.path === "file-manager") {
        this.title = "공유파일 데이터 현황";
        await this.getData("OWNCLOUD", DataStatusUtils.items.owncloud.itemKeyAndLabel);
      } else if (this.path === "env-control-status") {
        this.title = "환경제어 데이터 현황";
        await this.getData(
          "ENV_CONTROL_STATUS",
          DataStatusUtils.items.envControlStatus.itemKeyAndLabel
        );
        const convertFunc = (val) => {
          // console.log("val : ", val);
          if (val != null) {
            if (val) {
              return "ON";
            } else {
              return "OFF";
            }
          }
          return "";
        };
        this.table.typeList.push({
          id: "default",
          name: "제어",
          columns: [
            { title: "측정시간", name: "sensingAt", isTime: true },
            { title: "순환펌프1", name: "cyclePump1", isTime: false, convertFunc: convertFunc },
            { title: "순환펌프2", name: "cyclePump2", isTime: false, convertFunc: convertFunc },
            { title: "순환펌프3", name: "cyclePump3", isTime: false, convertFunc: convertFunc },
            { title: "순환펌프4", name: "cyclePump4", isTime: false, convertFunc: convertFunc },
            { title: "순환펌프1", name: "cyclePump1", isTime: false, convertFunc: convertFunc },
            { title: "냉난방기", name: "airConditioner", isTime: false, convertFunc: convertFunc },
            { title: "유동팬", name: "flowFan", isTime: false, convertFunc: convertFunc },
            { title: "배기팬", name: "exhaustFan", isTime: false, convertFunc: convertFunc },
            { title: "분무기", name: "spray", isTime: false, convertFunc: convertFunc },
            {
              title: "훈증기",
              name: "fumigationEquipment",
              isTime: false,
              convertFunc: convertFunc,
            },
            { title: "보광등", name: "auxLight", isTime: false, convertFunc: convertFunc },
            { title: "스프링클러", name: "sprinkler", isTime: false, convertFunc: convertFunc },
            {
              title: "보조 냉난방기",
              name: "subAirConditioner",
              isTime: false,
              convertFunc: convertFunc,
            },
            { title: "co2", name: "co2", isTime: false, convertFunc: convertFunc },
            { title: "좌천창", name: "leftRoof", isTime: false, convertFunc: convertFunc },
            { title: "우천창", name: "rightRoof", isTime: false, convertFunc: convertFunc },
            { title: "커튼1", name: "curtain1", isTime: false, convertFunc: convertFunc },
            { title: "커튼2", name: "curtain2", isTime: false, convertFunc: convertFunc },
            { title: "커튼3", name: "curtain3", isTime: false, convertFunc: convertFunc },
            { title: "커튼4", name: "curtain4", isTime: false, convertFunc: convertFunc },
            { title: "커튼5", name: "curtain5", isTime: false, convertFunc: convertFunc },
          ] as any,
        });
        this.table.selectedTypeId = "default";
      } else if (this.path === "env-power") {
        this.title = "전력사용량 데이터 현황";
        await this.getData("ENV_POWER", DataStatusUtils.items.envPower.itemKeyAndLabel);
        this.table.typeList.push({
          id: "default",
          name: "전력사용량",
          columns: [
            { title: "측정시간", name: "powerAt", isTime: true },
            { title: "누적전력사용량", name: "cumulativePowerUsage", isTime: false },
          ] as any,
        });
        this.table.selectedTypeId = "default";
      } else {
        await this.notFound();
      }
      this.createDonutChart();
      this.createChart();
    });
  },
  watch: {
    "table.selectedTypeId"(typeId) {
      let selectedItem = null as any;
      if (typeId != null) {
        this.table.typeList.forEach((typeItem) => {
          if (typeId === typeItem.id) {
            selectedItem = typeItem;
          }
        });
      }
      // console.log("typeId : ", typeId);
      this.table.selectedType = selectedItem;
      // console.log("selectedItem : ", selectedItem);
    },
  },
  methods: {
    async getDataList() {
      const table = this.table;
      if (this.path === "smartfarm2nd") {
        if (table.selectedTypeId === "in") {
          const params = {
            inFacilityId: 34,
            sensingAtGreaterThanEqual: core.date.instance().format("YYYY-MM-DD"),
            sensingAtLessThan: core.date.instance().add(1, "days").format("YYYY-MM-DD"),
          };
          const response = await SensorService.getInEnvList(params);
          // console.log("response : ", response);
          this.table.response.data = response;
          // table.response.data =
        } else if (table.selectedTypeId === "ext") {
        } else if (table.selectedTypeId === "ns") {
        } else if (table.selectedTypeId === "root") {
        } else {
          console.log("unknown typeId : ", table.selectedTypeId);
        }
      } else if (this.path === "env-control-status") {
        const params = {
          inFacilityId: 34,
          sensingAtGreaterThanEqual: core.date.instance().format("YYYY-MM-DD"),
          sensingAtLessThan: core.date.instance().add(1, "days").format("YYYY-MM-DD"),
          // sensingAtGreaterThanEqual: "2023-06-14",
          // sensingAtLessThan: "2023-06-15",
        };
        const response = await SensorService.getEnvControlStatusList(params);
        // console.log("response : ", response);
        this.table.response.data = response;
      } else if (this.path === "env-power") {
        const params = {
          inFacilityId: 34,
          powerAtGreaterThanEqual: core.date.instance().format("YYYY-MM-DD"),
          powerAtLessThan: core.date.instance().add(1, "days").format("YYYY-MM-DD"),
          // powerAtGreaterThanEqual: "2023-06-14",
          // powerAtLessThan: "2023-06-15",
        };
        const response = await SensorService.getEnvPowerList(params);
        // console.log("response : ", response);
        this.table.response.data = response;
      }
    },
    createDonutChart() {
      const $el = this.$refs.donut as HTMLElement;
      // Themes begin
      am4core.useTheme(am4themes_animated);
      // Themes end

      // Create chart instance
      var chart = am4core.create($el, am4charts.PieChart);

      // Add data
      chart.data = this.donutChartData;

      // Set inner radius
      chart.innerRadius = am4core.percent(50);

      // Add and configure Series
      var pieSeries = chart.series.push(new am4charts.PieSeries());
      pieSeries.dataFields.value = "value";
      pieSeries.dataFields.category = "label";
      pieSeries.slices.template.stroke = am4core.color("#fff");
      pieSeries.slices.template.strokeWidth = 2;
      pieSeries.slices.template.strokeOpacity = 1;

      // This creates initial animation
      pieSeries.hiddenState.properties.opacity = 1;
      pieSeries.hiddenState.properties.endAngle = -90;
      pieSeries.hiddenState.properties.startAngle = -90;
    },
    createChart() {
      const $el = this.$refs.chart as HTMLElement;
      // Themes begin
      am4core.useTheme(am4themes_animated);
      // Themes end

      // Create chart instance
      const chart = am4core.create($el, am4charts.XYChart) as any;

      // chart.dateFormatter.dateFormat = "yyyy-MM-dd HH:mm";
      chart.paddingRight = 20;

      chart.customItem = {};
      const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      chart.customItem.dateAxis = dateAxis;
      // default
      // day: "MMM dd"
      // hour: "HH:mm"
      // millisecond: "mm:ss SSS"
      // minute: "HH:mm"
      // month: "MMM"
      // second: "HH:mm:ss"
      // week: "MMM dd"
      // year: "yyyy"
      dateAxis.dateFormats.setKey("day", "MM월 dd일");
      dateAxis.dateFormats.setKey("month", "MM월");
      dateAxis.dateFormats.setKey("week", "MM월 dd일");
      dateAxis.dateFormats.setKey("year", "yyyy년");

      // default
      // day: "MMM dd"
      // hour: "MMM dd"
      // millisecond: "mm:ss SSS"
      // minute: "HH:mm"
      // month: "MMM yyyy"
      // second: "HH:mm:ss"
      // week: "MMM dd"
      dateAxis.periodChangeDateFormats.setKey("day", "MM월 dd일");
      dateAxis.periodChangeDateFormats.setKey("hour", "MM월 dd일");
      dateAxis.periodChangeDateFormats.setKey("month", "yyyy년 MM월");
      dateAxis.periodChangeDateFormats.setKey("week", "MM월 dd일");

      // dateAxis.tooltipDateFormat = "MM월 dd일 HH시 mm분";
      dateAxis.tooltipDateFormat = "MM월 dd일";

      dateAxis.tooltip.label.padding(5, 10, 5, 10);
      dateAxis.tooltip.label.fill = am4core.color("#fff");

      dateAxis.renderer.minGridDistance = 80;
      dateAxis.renderer.grid.template.location = 0;
      dateAxis.renderer.axisFills.template.disabled = true;
      dateAxis.renderer.ticks.template.disabled = true;

      // const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      // // valueAxis.min = 0;
      // // valueAxis.max = 100;
      // valueAxis.marginLeft = 0;
      // valueAxis.marginRight = 0;
      // valueAxis.paddingLeft = 0;
      // valueAxis.paddingRight = 0;
      // valueAxis.tooltip.disabled = true;
      // valueAxis.renderer.minWidth = 5;
      // valueAxis.renderer.axisFills.template.disabled = true;
      // valueAxis.renderer.ticks.template.disabled = true;
      // chart.customItem.valueAxis = valueAxis;
      chart.paddingRight = 0;
      chart.paddingLeft = 0;
      chart.marginLeft = 0;
      chart.marginRight = 0;

      const seriesList = [] as any;
      const dateFieldName = "date";

      const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.marginLeft = 0;
      valueAxis.marginRight = 0;
      valueAxis.paddingLeft = 0;
      valueAxis.paddingRight = 0;
      valueAxis.renderer.line.strokeOpacity = 1;
      valueAxis.renderer.line.strokeWidth = 2;
      // valueAxis.renderer.line.stroke = series.stroke;
      // valueAxis.renderer.labels.template.fill = series.stroke;
      valueAxis.renderer.opposite = true;

      for (const key of Object.keys(this.itemKeyAndLabel)) {
        const name = this.itemKeyAndLabel[key];
        //console.log("key : ", key, ", name : ", name);
        seriesList.push(
          this.createLineSeries(chart, valueAxis, key, dateFieldName, name, null, "건")
        );
      }

      // const scrollbarX = new am4core.Scrollbar();
      chart.legend = new am4charts.Legend();
      chart.cursor = new am4charts.XYCursor();
      const scrollbarX = new am4charts.XYChartScrollbar();

      seriesList.forEach((series) => {
        scrollbarX.series.push(series);
      });
      chart.scrollbarX = scrollbarX;

      // // Pre zoom
      // chart.events.on("datavalidated", function () {
      //   //var series = chart.custom.series;
      //   let minMoment: any = null,
      //     maxMoment: any = null;
      //   if (chart.data != null && chart.data.length > 0) {
      //     chart.data.forEach((data) => {
      //       const moment = core.date.instance(data.date);
      //       if (minMoment == null) {
      //         minMoment = moment;
      //       } else if (moment.isBefore(minMoment)) {
      //         minMoment = moment;
      //       }
      //
      //       if (maxMoment == null) {
      //         maxMoment = moment;
      //       } else if (moment.isAfter(maxMoment)) {
      //         maxMoment = moment;
      //       }
      //     });
      //
      //     const strMaxTime = maxMoment.format("YYYY-MM-DD HH:mm:ss");
      //     chart.customItem.dateAxis.zoomToDates(
      //       core.date.instance(strMaxTime).add(-1, "hours").toDate(),
      //       maxMoment.add(1, "minutes").toDate()
      //     );
      //   }
      // });

      // Add data
      chart.data = this.lineChartData;
    },
    createLineSeries(chart, valueAxis, field, dateField, name, colorHex, unit?) {
      const series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = field;
      series.dataFields.dateX = dateField;
      series.name = name;
      series.strokeWidth = 2;
      series.minBulletDistance = 60;
      series.tooltipText = "{name} [bold font-size: 20px]{valueY} [font-size: 12px]" + unit;
      series.legendSettings.valueText = "{valueY}";
      if (colorHex != null) {
        series.stroke = am4core.color(colorHex);
        series.fill = am4core.color(colorHex);
      }

      series.sequencedInterpolation = true;
      const bullet = series.bullets.push(new am4charts.CircleBullet());
      bullet.circle.fill = am4core.color("#fff");
      // bullet.circle.stroke = am4core.color("#fff");
      bullet.circle.strokeWidth = 3;

      series.yAxis = valueAxis;

      return series;
    },
    async getData(type, itemKeyAndLabel) {
      this.itemKeyAndLabel = itemKeyAndLabel;

      {
        const result = (await DataStatusService.get(type)) as any;
        console.log("result : ", result);
        for (const key of Object.keys(itemKeyAndLabel)) {
          const label = itemKeyAndLabel[key];
          this.donutChartData.push({ label: label, value: result[key] });
        }
      }
      {
        const params = {
          draw: 0,
          start: 0,
          length: 200,
          orderColumnName: "standardAt",
          order: TableOrder.ASC,
          searchColumns: {
            type: type,
            dateType: "D",
            standardAtGreaterThanEqual: core.date
              .instance()
              .add(-1, "months")
              .format("YYYY-MM-DD 00:00:00"),
          },
        };
        const result = (await DataStatusService.getTable(params)) as any;
        const dataList = [] as any;
        result.data.forEach((item) => {
          const data = item.data;
          data.date = core.date.instance(item.standardAt).toDate();
          dataList.push(data);
        });
        //console.log("dataList : ", dataList);
        this.lineChartData = dataList;
        // console.log("result : ", result);
      }
    },
  },
});
