































































import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import Smartfarm2ndComponent from "@/components/digital-agriculture/Smartfarm2ndComponent.vue";
import store from "@/store";
import GreenhouseComponent from "@/components/digital-agriculture/GreenhouseComponent.vue";
import AquaComponent from "@/components/digital-agriculture/AquaComponent.vue";
import ElectricalComponent from "@/components/digital-agriculture/GreenhouseElectricalComponent.vue";

export default mixins(MixinsPageForm).extend({
  name: "DigitalAgriculture",
  components: { ElectricalComponent, AquaComponent, GreenhouseComponent, Smartfarm2ndComponent },
  data: () => ({
    menu: true,
    height: 300,
    app: store.state.app,
    iframeSrc: "",
    path: "",
    subPath: "",
    items: [
      {
        id: "green-house",
        title: "첨단스마트온실Ⅰ",
        path: "/digital-agriculture/green-house",
        subItems: [
          {
            title: "온실환경 모니터링",
            path: "/status",
          },
          {
            title: "온습도 균일화 모니터링",
            path: "/monitoring",
          },
        ],
      },
      {
        id: "smartfarm2nd",
        title: "2세대스마트팜",
        path: "/digital-agriculture/smartfarm2nd",
      },
      {
        title: "아쿠아포닉스",
        path: "/digital-agriculture/aqua",
      },
      {
        title: "그린수소",
      },
      {
        title: "첨단스마트온실 전력량 데이터",
        path: "/digital-agriculture/electrical",
      },
    ],
  }),
  mounted() {
    this.resizeEvent();
    this.$nextTick(async () => {
      this.path = this.$route.params.path;
      this.subPath = this.$route.params.subPath;
      console.log("path : ", this.path, ", subPath :", this.subPath);
    });
  },
  watch: {
    "app.size"() {
      this.resizeEvent();
    },
    menu(menu) {
      console.log("changed menu : ", menu);
      this.resizeEvent();
    },
  },
  methods: {
    resizeEvent() {
      if (this.menu) {
        const height = this.app.size.height - 180;
        if (height < 500) {
          this.height = 500;
        } else {
          this.height = height;
        }
      } else {
        const height = this.app.size.height - 100;
        if (height < 500) {
          this.height = 500;
        } else {
          this.height = height;
        }
      }
    },
  },
});
