




























import mixins from "vue-typed-mixins";
import MixinsComponent from "@/mixins/component";
import DiseaseImageAnnotationComponent from "@/components/disease/analysis/DiseaseImageAnnotationComponent.vue";
import DiseaseAreaGalleryComponent from "@/components/disease/analysis/DiseaseAreaGalleryComponent.vue";
import DiseaseAreaCountMapComponent from "@/components/disease/analysis/DiseaseAreaCountMapComponent.vue";
import DiseaseImageComponent from "@/components/disease/analysis/DiseaseImageComponent.vue";
import SearchAddressModal from "@/modals/core/SearchAddressModal.vue";
import SearchAddressComponent from "@/components/address/SearchAddressComponent.vue";
import UpdateEvent from "@/models";
import core from "@/core";
import DiseaseService from "@/services/disease/disease.service";

export default mixins(MixinsComponent).extend({
  name: "DiseaseManagerAnalysisComponent",
  components: {
    DiseaseImageAnnotationComponent,
    DiseaseAreaGalleryComponent,
    DiseaseAreaCountMapComponent,
    DiseaseImageComponent,
    SearchAddressModal,
    SearchAddressComponent,
  },
  props: {
    properties: {
      type: Object,
      default: () => {
        return {
          cropCode: "",
          updateEvent: null as UpdateEvent | null,
        };
      },
    },
  },
  data: () => ({
    ready: false,
    component: {
      disease: {
        cropCode: null as any,
        analysis: null as any,
      },
      area: {
        cropCode: null as any,
        analysisStatus: "UNKNOWN",
        updateEvent: null as UpdateEvent | null,
      },
      gallery: {
        areaText: "전국",
        searchColumns: { status: "UNKNOWN" } as any,
        updateEvent: null as UpdateEvent | null,
      },
    },
  }),
  mounted() {
    this.$nextTick(async () => {
      if (core.utils.validate.isNotBlank(this.properties.cropCode)) {
        this.changeCropCode(this.properties.cropCode);
      }
      //console.log("cropCode : ", this.properties.cropCode);
    });
  },
  watch: {
    "properties.cropCode"(cropCode) {
      //console.log("cropCode : ", this.properties.cropCode);
      this.changeCropCode(cropCode);
    },

    "component.area.updateEvent"(event) {
      if (event != null) {
        const item = event.item;
        //console.log("event : ", event);
        this.component.gallery.searchColumns = {
          sidoCode: item.sidoCode,
          sggCode: item.sggCode,
          cropCode: item.cropCode,
          status: "UNKNOWN",
        };
        // 지역 텍스트 추가
        this.component.gallery.areaText = `${item.sidoName} ${item.sggName}`;
      }
    },
    "component.gallery.updateEvent"(event) {
      if (event != null) {
        const item = event.item;
        console.log("item : ", item);
        this.component.disease.analysis = item;
        // 스크롤바 위로 올리기
        window.scrollTo(0, 0);
      }
    },
  },
  methods: {
    async download() {
      core.loader.show("데이터 요청중...");
      try {
        const downloadUri = (await DiseaseService.getAnnotationFile(
          this.component.disease.cropCode
        )) as string;
        //console.log("downloadUri : ", downloadUri);
        core.loader.show("다운로드중...");
        await core.http.downloadFile(downloadUri);
      } catch (e) {
        console.log(e);
      }
      core.loader.hide();
    },
    async changeCropCode(cropCode) {
      this.component.disease.cropCode = cropCode;
      this.component.area.cropCode = cropCode;

      this.component.gallery.searchColumns = {
        cropCode: this.component.area.cropCode,
        status: "UNKNOWN",
      };
      this.component.gallery.areaText = "전국";

      if (!this.ready) {
        this.ready = true;
      }
    },
  },
});
