import DataStatusService from "@/services/datastatus/data-status.service";

class DataStatusUtils {
  items = {
    // 첨단디지털온실 환경제어
    envControlStatus: {
      name: "첨단디지털온실 환경제어",
      dataCount: 20,
      imageCount: 0,
      tooltipText: "",
      path: "/data-status/env-control-status",
      itemKeyAndLabel: {
        cyclePump1Count: "순환펌프1",
        cyclePump2Count: "순환펌프2",
        cyclePump3Count: "순환펌프3",
        cyclePump4Count: "순환펌프4",
        airConditionerCount: "냉난방기",
        flowFanCount: "유동팬",
        exhaustFanCount: "배기팬",
        sprayCount: "분무기",
        fumigationEquipmentCount: "훈증기",
        auxLightCount: "보광등",
        sprinklerCount: "스프링클러",
        subAirConditionerCount: "보조 냉난방기",
        co2Count: "co2",
        leftRoofCount: "좌천창",
        rightRoofCount: "우천창",
        curtain1Count: "커튼1",
        curtain2Count: "커튼2",
        curtain3Count: "커튼3",
        curtain4Count: "커튼4",
        curtain5Count: "커튼5",
      },
    },
    // 전력량 데이터
    envPower: {
      name: "전력 사용량",
      dataCount: 1,
      imageCount: 0,
      tooltipText: "",
      path: "/data-status/env-power",
      itemKeyAndLabel: {
        cumulativePowerUsageCount: "누적전력사용량",
      },
    },
    // 첨단스마트온실
    highTechDigitalGreenHouse: {
      name: "첨단스마트온실",
      dataCount: 10,
      imageCount: 0,
      tooltipText: "",
      path: "/data-status/green-house",
      itemKeyAndLabel: {
        inTempCount: "내부 온도",
        inHumidityCount: "내부 습도",
        inSolarRadiationCount: "내부 일사량",
        inCo2Count: "내부 CO2",

        extTempCount: "외부 온도",
        extSolarRadiationCount: "외부 일사량",
        extWindDirectionCount: "외부 풍향",
        extWindSpeedCount: "외부 풍향",

        soilTempCount: "근권부 온도",
        soilHumidityCount: "근권부 습도",
      },
    },
    // 2세대 스마트팜
    smartfarm2nd: {
      name: "2세대 스마트팜",
      dataCount: 21,
      imageCount: 0,
      tooltipText: "",
      path: "/data-status/smartfarm2nd",

      itemKeyAndLabel: {
        inTempCount: "내부 온도",
        inHumidityCount: "내부 습도",
        inSolarRadiationCount: "내부 일사량",
        inCo2Count: "내부 CO2",
        inWindSpeedCount: "내부 풍속",

        extTempCount: "외부 온도",
        extHumidityCount: "외부 습도",
        extRainFallCount: "감우",
        extSolarRadiationCount: "외부 일사량",
        extWindDirectionCount: "외부 풍향",
        extWindSpeedCount: "외부 풍향",

        nsConstConcenCount: "양액 성분농도",
        nsFlowRateCount: "양액 공급유량",
        nsEcCount: "양액 EC",
        nsPhCount: "양액 PH",

        soilTempCount: "근권부 온도",
        soilHumidityCount: "근권부 습도",
        soilDrainageConstConcenCount: "근권부 성분농도",
        soilDrainageFlowRateCount: "근권부 공급유량",
        soilDrainagePhCount: "근권부 PH",
        soilDrainageEcCount: "근권부 EC",
      },
    },
    disease: {
      name: "병해진단",
      dataCount: 0,
      imageCount: 2,
      tooltipText: "",
      path: "/data-status/disease",
      itemKeyAndLabel: {
        tomatoCount: "토마토",
        strawberryCount: "딸기",
      },
    },
    aqua: {
      name: "아쿠아포닉스",
      dataCount: 6,
      imageCount: 0,
      tooltipText: "온도, 습도, PH, EC, DO, ORP",
      path: "/data-status/aqua",

      itemKeyAndLabel: {
        tempCount: "온도",
        humidityCount: "습도",
        phCount: "PH",
        ecCount: "EC",
        doCount: "DO",
        orpCount: "ORP",
      },
    },
    milking: {
      name: "축산(착유)",
      dataCount: 16,
      imageCount: 0,
      tooltipText: "",
      path: "/data-status/milking",

      itemKeyAndLabel: {
        teatseqCount: "젖꼭지 시퀀스 수",
        yieldCount: "착유량 수",
        conductivityCount: "전도도 수",
        flowspeedCount: "유속 수",
        fatCount: "지방 수",
        proteinCount: "단백질 수",
        lactoseCount: "유당 수",
        mastitisnumberCount: "유방염 수",
        bloodCount: "피 수",
        maxconductivityCount: "최고전도도 수",
        maxtemperatureCount: "최고온도 수",
        maxflowspeedCount: "최고유속 수",
        timeofmaxspeedCount: "분당 최고 유속 수",
        durationCount: "기간 수",
        milkingchecktmCount: "젖꼭지 세척후 우유 감지시간 수",
        teatmilkingtmCount: "유방별 착유 시간 수",
      },
    },
    owncloud: {
      name: "공유파일관리",
      dataCount: 0,
      imageCount: 0,
      tooltipText: "",
      path: "/data-status/file-manager",

      itemKeyAndLabel: {},
    },
  };

  constructor() {
    for (const key of Object.keys(this.items)) {
      const item = this.items[key];
      let tooltipText = "";
      for (const itemKey of Object.keys(item.itemKeyAndLabel)) {
        const label = item.itemKeyAndLabel[itemKey];
        tooltipText += label + ", ";
      }
      if (tooltipText.length > 0) {
        tooltipText = tooltipText.substr(0, tooltipText.length - 2);
      }
      item.tooltipText = tooltipText;
      //console.log("tooltipText : ", tooltipText);
    }
  }

  async reload() {
    {
      const owncloud = this.items.owncloud;
      // owncloud 데이터 조회
      const result = (await DataStatusService.get("OWNCLOUD")) as any;
      //console.log("result : ", result);
      let tooltipText = "";
      let count = 0;

      owncloud.itemKeyAndLabel = {};
      for (const key of Object.keys(result)) {
        //console.log("key : ", key);
        //const value = result[key];
        tooltipText += key + ", ";
        count++;
        owncloud.itemKeyAndLabel[key] = key;
      }
      if (tooltipText.length > 0) {
        tooltipText = tooltipText.substr(0, tooltipText.length - 2);
      }
      owncloud.tooltipText = tooltipText;
      owncloud.dataCount = count;
    }
  }
}

export default new DataStatusUtils();
