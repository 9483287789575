









































































































































































































import mixins from "vue-typed-mixins";
import MixinsModalForm from "@/mixins/modal-form";
import { localize } from "vee-validate";
import Constant from "@/store/constant";
import { UpdateEventType } from "@/types";
import GrowthService from "@/services/growth/growth.service";
import core from "@/core";
import { cloneDeep } from "lodash";

localize("ko", {
  messages: Constant.validate.language.korea.messages,
  names: {
    facility: "시설",
    inFacility: "내부시설",
    sampleNumber: "샘플번호",
    plantLength: "초장(cm)",
    stemDiameter: "경경(mm)",
    leafLength: "엽장(cm)",
    leafWidth: "엽폭(cm)",
    flowerCluster: "화방(번째)",
    distanceGrowingFlower: "생장점과 화방간 거리",
    growthAt: "측정일자",
  },
});

export default mixins(MixinsModalForm).extend({
  name: "GrowthAddEditModal",
  data: () => ({
    title: "생육정보 추가",
    type: "",
    processing: false,

    form: {
      facilityId: "",
      inFacilityId: "",
      // 샘플번호
      sampleNumber: "",
      // 초장(cm)
      plantLength: "",
      // 경경(mm)
      stemDiameter: "",
      // 엽장(cm)
      leafLength: "",
      // 엽폭(cm)
      leafWidth: "",
      // 화방(번째)
      flowerCluster: "",
      // 생장점과 화방간 거리
      distanceGrowingFlower: "",
      growthAt: "",
      remark: "",
    },
    datePicker: {
      visible: false,
      date: "",
    },
    facilityList: [] as any,
    selectedFacility: null as any,
    growth: null as any,
  }),
  mounted() {
    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      this.type = this.properties.params.type;
      this.growth = this.properties.params.item;
      this.facilityList = this.properties.params.facilityList;

      console.log("growth : ", this.growth);

      if (this.type === "edit") {
        const growth = this.growth;
        const form = this.form;
        form.facilityId = growth.facilityId;
        this.facilityList.some((facility) => {
          if (facility.id === form.facilityId) {
            this.selectedFacility = facility;
            return true;
          }
        });
        form.inFacilityId = growth.inFacilityId;
        form.sampleNumber = growth.sampleNumber;
        form.plantLength = growth.plantLength;
        form.stemDiameter = growth.stemDiameter;
        form.leafLength = growth.leafLength;
        form.leafWidth = growth.leafWidth;
        form.flowerCluster = growth.flowerCluster;
        form.distanceGrowingFlower = growth.distanceGrowingFlower;
        form.growthAt = growth.growthAt;
        form.remark = growth.remark;
        this.datePicker.date = core.date.instance(growth.growthAt).format("YYYY-MM-DD");
      }
    });
  },
  watch: {
    "form.plantLength"(val) {
      if (this.isNotBlank(val)) {
        this.form.plantLength = core.utils.format.numberAndDot(val);
      }
    },
    "form.stemDiameter"(val) {
      if (this.isNotBlank(val)) {
        this.form.stemDiameter = core.utils.format.numberAndDot(val);
      }
    },
    "form.leafLength"(val) {
      if (this.isNotBlank(val)) {
        this.form.leafLength = core.utils.format.numberAndDot(val);
      }
    },
    "form.leafWidth"(val) {
      if (this.isNotBlank(val)) {
        this.form.leafWidth = core.utils.format.numberAndDot(val);
      }
    },
    "form.flowerCluster"(val) {
      if (this.isNotBlank(val)) {
        this.form.flowerCluster = core.utils.format.onlyNumber(val);
      }
    },
    "form.distanceGrowingFlower"(val) {
      if (this.isNotBlank(val)) {
        this.form.distanceGrowingFlower = core.utils.format.numberAndDot(val);
      }
    },
    type(type) {
      if (type === "add") {
        this.title = "생육정보 추가";
      } else if (type === "edit") {
        this.title = "생육정보 변경";
      }
    },
    "form.facilityId"(facilityId) {
      var selectedFacility = null as any;
      if (this.isNotBlank(facilityId)) {
        //console.log("facilityId : ", facilityId);
        this.facilityList.some((facility) => {
          if (facility.id === facilityId) {
            selectedFacility = facility;
            return true;
          }
        });
        if (selectedFacility != null) {
          if (selectedFacility.inFacilityList != null) {
            selectedFacility.inFacilityList.forEach((inFacility) => {
              inFacility.text = `${inFacility.dongNo}동`;
            });
          }
        }
        // console.log("selectedFacility : ", selectedFacility);
      }
      this.selectedFacility = selectedFacility;
    },
  },
  methods: {
    async deleteItem() {
      const item = this.growth;
      if (this.type === "edit" && item != null) {
        const result = await core.alert.show({
          title: "확인",
          body: `정보를 삭제하시겠습니까?`,
          showCancelButton: true,
        });
        if (result === "confirm") {
          try {
            await GrowthService.delete(item.id);
          } catch (e) {
            console.log(e);
          }
          this.close(UpdateEventType.CONFIRM);
        }
      }
    },
    async submit() {
      if (await this.validate()) {
        this.processing = true;

        try {
          const params = cloneDeep(this.form) as any;
          // console.log("params : ", params);
          params.growthAt = core.date.instance(this.datePicker.date).format("YYYY-MM-DD 00:00:00");

          if (this.type === "add") {
            const growth = (await GrowthService.create(params)) as any;
            console.log("create growth : ", growth);
          } else if (this.type === "edit") {
            const growth = (await GrowthService.update(this.growth.id, params)) as any;
            console.log("update growth : ", growth);
          }

          //console.log("params : ", params);
          // if (this.type === "add") {
          //   const user = await UserService.create(params);
          // } else if (this.type === "edit") {
          //   if (!this.changePassword) {
          //     delete params.password;
          //     delete params.confirmPassword;
          //   }
          //   const user = await UserService.update(this.user.id, params);
          // }
          // await this.$store.dispatch("auth/register", params);

          this.close(UpdateEventType.CONFIRM);
        } catch (e) {
          console.log("error : ", e);
        }
        this.processing = false;
      }
    },
  },
});
