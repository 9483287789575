
























































import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import core from "@/core";
import { TableOrder, UpdateEventType } from "@/types";
import GrowthService from "@/services/growth/growth.service";
import GrowthAddEditModal from "@/modals/GrowthAddEditModal.vue";
import UpdateEvent from "@/models";
import GrowthUploadExcelModal from "@/modals/GrowthUploadExcelModal.vue";
import FacilityService from "@/services/facility/facility.service";

export default mixins(MixinsPageForm).extend({
  name: "GrowthManagement",
  components: { GrowthUploadExcelModal, GrowthAddEditModal },
  data: () => ({
    ready: false,
    loading: false,
    headers: [
      {
        text: "번호",
        align: "start",
        sortable: true,
        value: "id",
        width: "80px",
      },
      {
        text: "생성시간",
        value: "createdAt",
        sortable: true,
        width: "120px",
      },
      {
        text: "측정일자",
        value: "growthAt",
        sortable: true,
        width: "120px",
      },
      {
        text: "시설",
        value: "facilityId",
        sortable: false,
        width: "100px",
      },
      {
        text: "내부시설(동)",
        value: "inFacilityId",
        sortable: false,
        width: "60px",
      },
      {
        text: "샘플번호",
        value: "sampleNumber",
        sortable: false,
        width: "80px",
      },
      {
        text: "초장(cm)",
        value: "plantLength",
        sortable: false,
      },
      {
        text: "경경(mm)",
        value: "stemDiameter",
        sortable: false,
      },
      {
        text: "엽장(cm)",
        value: "leafLength",
        sortable: false,
      },
      {
        text: "엽폭(cm)",
        value: "leafWidth",
        sortable: false,
      },
      {
        text: "화방(번째)",
        value: "flowerCluster",
        sortable: false,
        width: "70px",
      },
      {
        text: "생장점과 화방간 거리",
        value: "distanceGrowingFlower",
        sortable: false,
        width: "90px",
      },
      {
        text: "비고",
        value: "remark",
        sortable: false,
        width: "120px",
      },
    ],
    facilityList: null as any,
    facilityMap: {} as { [key: number]: any },
    inFacilityMap: {} as { [key: number]: any },
    dataList: [] as any,
    options: {} as any,
    totalDataLength: 0,
    footerProps: {
      itemsPerPageOptions: [10, 20, 50, 100],
      disablePagination: false,
      disableItemsPerPage: false,
    },
    visible: {
      addButton: false,
    },
    modal: {
      growthAddEdit: {
        visible: false,
        params: {
          type: "add",
          item: null as any,
          facilityList: [] as any,
        },
        updateEvent: null as UpdateEvent | null,
      },
      uploadExcel: {
        visible: false,
        params: {
          facilityList: [] as any,
        },
        updateEvent: null as UpdateEvent | null,
      },
    },
  }),
  watch: {
    options: {
      handler() {
        if (this.ready && !this.loading) {
          this.getDataList();
        }
      },
      deep: true,
    },
    "modal.uploadExcel.updateEvent"() {
      const event = this.getComponentUpdateEvent(this.modal.uploadExcel);
      if (event != null) {
        // console.log("event : ", event);
        if (event.result === UpdateEventType.CONFIRM) {
          if (this.ready && !this.loading) {
            this.getDataList();
          }
        }
      }
    },
    "modal.growthAddEdit.updateEvent"() {
      const event = this.getComponentUpdateEvent(this.modal.growthAddEdit);
      if (event != null) {
        // console.log("event : ", event);
        if (event.result === UpdateEventType.CONFIRM) {
          if (this.ready && !this.loading) {
            this.getDataList();
          }
        }
      }
    },
  },
  mounted() {
    this.$nextTick(async () => {
      const user = await this.$store.getters["auth/user"]();
      if (user != null && user.roles != null) {
        let hasManagerRole = false;
        user.roles.forEach((role) => {
          if (role === "MANAGER" || role === "ADMIN") {
            hasManagerRole = true;
          }
        });

        if (hasManagerRole) {
          this.visible.addButton = true;
          this.headers.push({
            text: "Actions",
            value: "actions",
            sortable: false,
            width: "10%",
          });
        }
      }

      try {
        const facilityList = await FacilityService.getList();
        console.log("facilityList : ", facilityList);
        this.facilityList = facilityList;
        facilityList.forEach((facility) => {
          this.facilityMap[facility.id] = facility;
          if (facility.inFacilityList != null) {
            facility.inFacilityList.forEach((inFacility) => {
              this.inFacilityMap[inFacility.id] = inFacility;
            });
          }
        });
      } catch (e) {
        console.log(e);
      }

      this.ready = true;

      await this.getDataList();
    });
  },

  methods: {
    facilityToString(facilityId) {
      const facility = this.facilityMap[facilityId];
      if (facility != null) {
        return facility.name;
      }
      return "";
    },
    inFacilityToString(inFacilityId) {
      const inFacility = this.inFacilityMap[inFacilityId];
      if (inFacility != null) {
        return inFacility.dongNo + "동";
      }
      return "";
    },
    async uploadExcel() {
      // 추가 modal open
      const modal = this.modal.uploadExcel;
      modal.params.facilityList = this.facilityList;
      modal.visible = true;
      // this.$router.push("/growth-management/add");
    },
    addItem() {
      // 추가 modal open
      const modal = this.modal.growthAddEdit;
      modal.params.facilityList = this.facilityList;
      modal.params.type = "add";
      modal.visible = true;
      // this.$router.push("/growth-management/add");
    },
    editItem(item) {
      // 수정 modal open
      const modal = this.modal.growthAddEdit;
      modal.params.facilityList = this.facilityList;
      modal.params.type = "edit";
      modal.params.item = item;
      modal.visible = true;
      // console.log("item : ", item);
      // this.$router.push("/data-management/edit?id=" + item.id);
    },
    async deleteItem(item) {
      const result = await core.alert.show({
        title: "확인",
        body: `정보를 삭제하시겠습니까?`,
        showCancelButton: true,
      });
      if (result === "confirm") {
        try {
          await GrowthService.delete(item.id);
          await this.getDataList();
        } catch (e) {
          console.log(e);
        }
      }
    },
    async getDataList() {
      this.loading = true;

      const options = this.options;
      console.log("options : ", options);
      let sortDesc = true;
      let orderColumnName = "id";
      if (options.sortBy != null && options.sortBy.length > 0) {
        orderColumnName = options.sortBy[0];
      }
      if (options.sortDesc != null && options.sortDesc.length > 0) {
        sortDesc = options.sortDesc[0];
      }
      const params = {
        draw: 0,
        start: (options.page - 1) * options.itemsPerPage,
        length: options.itemsPerPage,
        orderColumnName: orderColumnName,
        order: sortDesc ? TableOrder.DESC : TableOrder.ASC,
        searchColumns: {} as any,
      };
      const searchColumns = params.searchColumns;
      const response = (await GrowthService.getTable(params)) as any;
      this.dataList = response.data;
      this.totalDataLength = response.recordsTotal;
      // this.dataList = [{ id: 1, title: "토마토 병해충 자료", createdAt: "2021-10-28 00:00:00" }];
      // this.totalDataLength = this.dataList.length;

      this.loading = false;
    },
  },
});
