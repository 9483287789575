

































































































































































































































































import { cloneDeep, findIndex, orderBy } from "lodash";
import Popper from "vue-popperjs";
import "vue-popperjs/dist/css/vue-popper.css";
import VueImageLightboxCarousel from "vue-image-lightbox-carousel";
import mixins from "vue-typed-mixins";
import core from "@/core";

export default mixins().extend({
  name: "VueUploadMultipleImage",
  props: {
    dragText: {
      type: String,
      default: "드래그 이미지(다수)",
    },
    browseText: {
      type: String,
      default: "(또는) 선택",
    },
    primaryText: {
      type: String,
      default: "기본값",
    },
    markIsPrimaryText: {
      type: String,
      default: "기본값으로 설정",
    },
    popupText: {
      type: String,
      default: "이 이미지는 기본값으로 표시됩니다.",
    },
    dropText: {
      type: String,
      default: "이 이미지를 추가합니다.",
    },
    accept: {
      type: String,
      default: "image/gif,image/jpeg,image/png,image/bmp,image/jpg",
    },
    dataImages: {
      type: Array,
      default: () => {
        return [];
      },
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    showPrimary: {
      type: Boolean,
      default: true,
    },
    maxImage: {
      type: Number,
      default: 5,
    },
    idUpload: {
      type: String,
      default: "image-upload",
    },
    idEdit: {
      type: String,
      default: "image-edit",
    },
    showEdit: {
      type: Boolean,
      default: true,
    },
    showDelete: {
      type: Boolean,
      default: true,
    },
    showAdd: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Popper,
    VueImageLightboxCarousel,
  },
  data: () => ({
    currentIndexImage: 0,
    images: [] as any,
    isDragover: false,
    showLightbox: false,
    arrLightBox: [] as any,
  }),
  // computed: {
  //   imagePreview() {
  //     let index = findIndex(this.images, { highlight: 1 });
  //     if (index > -1) {
  //       return this.images[index].path;
  //     } else {
  //       return this.images.length ? this.images[0].path : "";
  //     }
  //   },
  //   imageDefault() {
  //     if (this.images[this.currentIndexImage]) {
  //       return this.images[this.currentIndexImage].default;
  //     }
  //   },
  // },
  watch: {
    dataImages: {
      handler: function (newVal) {
        this.images = cloneDeep(newVal);
      },
      deep: true,
    },
  },
  created() {
    this.images = [] as any;
    this.images = cloneDeep(this.dataImages);
  },
  mounted() {
    document.body.addEventListener("dragleave", (event) => {
      event.stopPropagation();
      event.preventDefault();
      this.isDragover = false;
    });
  },
  methods: {
    imagePreview() {
      let index = findIndex(this.images, { highlight: 1 });
      if (index > -1) {
        return this.images[index].path;
      } else {
        return this.images.length ? this.images[0].path : "";
      }
    },
    imageDefault() {
      if (this.images[this.currentIndexImage]) {
        return this.images[this.currentIndexImage].default;
      }
    },
    preventEvent(e) {
      e.preventDefault();
      e.stopPropagation();
    },
    async onDrop(e) {
      this.isDragover = false;
      e.stopPropagation();
      e.preventDefault();
      let files = e.dataTransfer.files;
      if (!files.length) {
        return false;
      }
      if (!this.isValidNumberOfImages(files.length)) {
        return false;
      }

      const size = files.length;
      for (let i = 0; i < size; i++) {
        const value = files[i];
        core.loader.show(`이미지 추가중... ${i + 1}/${size}`);
        await this.createImage(value);
        if (!this.multiple) {
          core.loader.hide();
          return false;
        }
      }
      core.loader.hide();
      if (document.getElementById(this.idUpload)) {
        (document.getElementById(this.idUpload) as any).value = [];
      }
    },
    onDragover() {
      this.isDragover = true;
    },
    async createImage(file: any) {
      if (this.disabled) return;
      const image = (await core.utils.image.getImageBlob(file, 1980)) as any;

      if (!this.images.length) {
        this.images.push({
          name: file.name,
          path: image.dataUri,
          highlight: 1,
          default: 1,
          blob: image.blob,
        });
        this.currentIndexImage = 0;
      } else {
        this.images.push({
          name: file.name,
          path: image.dataUri,
          highlight: 0,
          default: 0,
          blob: image.blob,
        });
      }
      this.$emit("upload-success", this.images.length - 1, this.images);
    },
    async editImage(file) {
      if (this.disabled) return;

      const image = (await core.utils.image.getImageBlob(file, 1980)) as any;

      if (this.images.length && this.images[this.currentIndexImage]) {
        this.images[this.currentIndexImage].path = image.dataUri;
        this.images[this.currentIndexImage].name = file.name;
        this.images[this.currentIndexImage].blob = image.blob;
      }
      this.$emit("edit-image", this.currentIndexImage, this.images);
    },
    async uploadFieldChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return false;
      }
      if (!this.isValidNumberOfImages(files.length)) {
        return false;
      }
      const size = files.length;
      for (let i = 0; i < size; i++) {
        const value = files[i];
        core.loader.show(`이미지 추가중... ${i + 1}/${size}`);
        await this.createImage(value);
      }
      core.loader.hide();
      if (document.getElementById(this.idUpload)) {
        (document.getElementById(this.idUpload) as any).value = [];
      }
    },
    async editFieldChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return false;
      }
      if (!this.isValidNumberOfImages(files.length)) {
        return false;
      }

      const size = files.length;
      for (let i = 0; i < size; i++) {
        const value = files[i] as any;
        await this.editImage(value);
      }

      if (document.getElementById(this.idEdit)) {
        (document.getElementById(this.idEdit) as any).value = "";
      }
    },
    changeHighlight(currentIndex) {
      this.currentIndexImage = currentIndex;
      let arr = this.images;
      this.images = [];
      arr.map((item, index) => {
        if (currentIndex === index) {
          item.highlight = 1;
        } else {
          item.highlight = 0;
        }
        return item;
      });
      this.images = arr;
    },
    markIsPrimary(currentIndex) {
      this.images.map((item, index) => {
        if (currentIndex === index) {
          item.highlight = 1;
          item.default = 1;
        } else {
          item.highlight = 0;
          item.default = 0;
        }
        return item;
      });
      this.currentIndexImage = 0;
      this.images = orderBy(this.images, "default", "desc");
      this.$emit("mark-is-primary", currentIndex, this.images);
    },
    deleteImage(currentIndex) {
      this.$emit(
        "before-remove",
        currentIndex,
        () => {
          if (this.images[currentIndex].default === 1) {
            this.images[0].default = 1;
          }
          this.images.splice(currentIndex, 1);
          this.currentIndexImage = 0;
          if (this.images.length) {
            this.images[0].highlight = 1;
          }
        },
        this.images
      );
    },
    openGallery(index) {
      this.showLightbox = true;
      (this.$refs.lightbox as any).showImage(index);
    },
    onOpenedLightBox(value) {
      if (value) {
        this.showLightbox = true;
      } else {
        this.showLightbox = false;
      }
    },
    isValidNumberOfImages(amount) {
      if (amount > this.maxImage) {
        this.$emit("limit-exceeded", amount);
        return false;
      } else {
        return true;
      }
    },
  },
});
