










































































































import mixins from "vue-typed-mixins";
import AppBarProps from "@/components/core/AppBarProps.vue";
import core from "@/core";
import store from "@/store";
import { UserModel } from "@/models/user/user.model";

export default mixins(AppBarProps).extend({
  name: "AppBar",
  data: () => ({
    showMenu: false,
    companyId: 0,
    menuList: [],
    app: store.state.app,
    auth: store.state.auth,
    items: [
      {
        name: "Home",
        path: "/home",
        icon: "mdi-home-variant",
        roles: ["ADMIN", "MANAGER", "FARMER", "USER"],
      },
      {
        name: "디지털농업",
        path: "/digital-agriculture",
        icon: "mdi-google-analytics",
        roles: ["ADMIN", "MANAGER", "FARMER"],
      },
      { name: "병해진단", path: "/disease", icon: "mdi-camera" },
      {
        name: "병해진단전문가",
        path: "/disease-manager",
        icon: "mdi-camera",
        roles: ["ADMIN", "MANAGER"],
      },
      {
        name: "생육관리",
        path: "/growth-management",
        icon: "mdi-monitor",
        roles: ["ADMIN", "MANAGER"],
      },
      { name: "데이터관리", path: "/data-management", icon: "mdi-monitor" },
      { name: "사용자관리", path: "/users", icon: "mdi-account-multiple", roles: ["ADMIN"] },
      { name: "파일관리", path: "/cloud", icon: "mdi-file", roles: ["ADMIN", "MANAGER"] },
    ],
    view: {
      currentDateText: "",
    },
    interval: null as any,
    tab: null as any,
  }),
  computed: {
    title() {
      return this.$store.getters["topToolbar/title"];
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.changedPath(this.$route.path);

      this.prop.closeNavigation = false;

      if (this.app.size.width > 960) {
        this.startCounter();
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    this.stopCounter();
    next();
  },
  watch: {
    $route(to, from) {
      this.changedPath(to.path);
    },
    "prop.closeNavigation"(closeNavigation) {
      if (closeNavigation) {
        this.showMenu = false;
      }
      this.prop.closeNavigation = false;
    },
    // tab(tab, preTab) {
    //   console.log("tab : ", tab);
    // },
    "app.size"(size) {
      if (size.width > 960) {
        this.startCounter();
      } else {
        this.stopCounter();
      }
    },
  },
  methods: {
    changedPath(path) {
      let display = "none";
      this.items.some((item) => {
        if (path.startsWith(item.path)) {
          display = "block";
          return true;
        }
      });
      const el = (this.$refs.tab as any).$el;
      el.querySelector(".v-tabs-slider-wrapper").style.display = display;
    },
    checkMenuRole(menu) {
      //console.log("menu : ", menu);
      if (menu.roles != null) {
        const user: UserModel | null = this.$store.state.auth.user;
        if (user == null) {
          return false;
        }
        let result = false;
        //console.log("user.roles : ", user.roles);
        menu.roles.forEach((role) => {
          if (user.roles.indexOf(role) > -1) {
            result = true;
          }
        });
        return result;
      }
      return true;
    },
    startCounter() {
      if (this.interval == null) {
        //console.log("startCounter");
        const updateCurrentDate = this.updateCurrentDate;
        this.interval = setInterval(() => {
          updateCurrentDate();
        }, 300);
        updateCurrentDate();
      }
    },
    stopCounter() {
      if (this.interval != null) {
        //console.log("stopCounter");
        clearInterval(this.interval);
        this.interval = null;
      }
    },
    updateCurrentDate() {
      this.view.currentDateText = core.date.instance().format("YYYY년 MM월 DD일(ddd) a h:mm:ss");
    },
    goBack() {
      this.$router.back();
    },
    async logout() {
      const result = await core.alert.show({
        title: "확인",
        body: "현재 인증된 정보가 해제됩니다.<br>계속 진행하시겠습니까?",
        showCancelButton: true,
      });
      if (result === "confirm") {
        await this.$router.push("/logout");
      }
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
  },
});
