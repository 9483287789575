


































































































































































































































import mixins from "vue-typed-mixins";
import store from "@/store";
import MixinsPageForm from "@/mixins/page-form";

import { localize } from "vee-validate";
import Constant from "@/store/constant";
import core from "@/core";

localize("ko", {
  messages: Constant.validate.language.korea.messages,
  names: {
    username: "아이디 ",
    password: "비밀번호 ",
  },
});

export default mixins(MixinsPageForm).extend({
  name: "Login",

  data: () => ({
    app: store.state.app,
    processing: false,
    form: {
      rememberMe: true,
      username: "",
      password: "",
    },
  }),

  mounted() {
    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      if (!this.app.isMobile) {
        this.form.rememberMe = false;
      }

      const query = this.$route.query;
      if (core.utils.validate.isNotBlank(query.result)) {
        this.replaceState();
      }
    });
  },
  methods: {
    async submit() {
      if (await this.validate()) {
        this.processing = true;

        try {
          const user = await this.$store.dispatch("auth/login", this.form);
          if (user != null) {
            await this.$router.push("/");
          }
        } catch (e) {
          // console.log(e);
          this.errorSubmit(e);
        }
        this.processing = false;
      }
    },
  },
});
