import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import ko from "vuetify/src/locale/ko";
import "@mdi/font/css/materialdesignicons.css";
import "font-awesome/css/font-awesome.css";
import "@/assets/flaticon/flaticon-custom.css";
import "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { ko },
    current: "ko",
  },
  icons: {
    iconfont: "mdi",
  },
});
