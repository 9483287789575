import core from "@/core";

import { createClient, WebDAVClient } from "webdav";
import axios from "axios";
import AbstractTableService from "@/services/core/abstract-table.service";

class OpenDataService extends AbstractTableService {
  constructor(url: string, itemKey: string) {
    super(url, itemKey);
  }
}

export default new OpenDataService("/api/open-data", "openData");
