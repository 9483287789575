

































































































































































































































import mixins from "vue-typed-mixins";
import MixinsModalForm from "@/mixins/modal-form";
import { localize } from "vee-validate";
import Constant from "@/store/constant";
import core from "@/core";
import _ from "lodash";
import { UpdateEventType } from "@/types";
import UserService from "@/services/user/user.service";
import DepartmentService from "@/services/department/department.service";

localize("ko", {
  messages: Constant.validate.language.korea.messages,
  names: {
    username: "아이디 ",
    password: "비밀번호 ",
    confirmPassword: "비밀번호 확인 ",
    email: "이메일 ",
    name: "이름 ",
    phone: "휴대전화 ",
    roles: "사용자 유형 ",
  },
});

export default mixins(MixinsModalForm).extend({
  name: "user-add-edit-modal",
  data: () => ({
    title: "",
    type: "",
    processing: false,
    changePassword: false,
    department: {
      model: null as any,
      search: null as any,
      list: [] as any,
      editing: null as any,
      editingIndex: -1,
    },

    form: {
      username: "",
      password: "",
      confirmPassword: "",
      email: "",
      name: "",
      phone: "",
      roles: [] as any,
    },
    roles: [
      { id: "ADMIN", text: "관리자" },
      { id: "MANAGER", text: "매니저" },
      { id: "BUSINESS_PARTNER", text: "협력사" },
      { id: "DEVICE_ADMIN", text: "장치관리자" },
      { id: "FARMER", text: "농업인" },
      { id: "USER", text: "사용자" },
    ],
    user: null as any,
  }),
  mounted() {
    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      this.type = this.properties.params.type;

      // 부서 정보 조회
      this.department.list = await DepartmentService.getList();

      const user = (this.user = this.properties.params.user);
      if (this.type == "edit") {
        //console.log("user : ", user);
        const form = this.form;
        form.username = user.username;
        form.email = user.email;
        form.name = user.name;
        form.phone = user.phone;
        form.roles = user.roles;
        if (user.department != null) {
          this.department.model = user.department;
        }
      }
    });
  },
  watch: {
    async "department.model"(model, preModel) {
      console.log("model : ", model);
      if (typeof model === "string") {
        const department = this.getDepartment(model);
        if (department == null) {
          const result = await core.alert.show({
            title: "확인",
            body: `"${model}" 부서를 추가하시겠습니까?`,
            showCancelButton: true,
          });
          if (result === "confirm") {
            this.department.search = model;
            await this.createDepartment();
          } else {
            this.department.search = null;
            this.department.model = preModel;
          }
        } else {
          this.department.model = department;
        }
      }
    },
    type(type) {
      if (type === "add") {
        this.title = "사용자 추가";
      } else if (type === "edit") {
        this.title = "사용자 정보 변경";
      }
    },
    "form.phone"() {
      this.form.phone = core.utils.format.hyphenPhone(this.form.phone);
    },
  },
  methods: {
    async submit() {
      if (await this.validate()) {
        this.processing = true;

        try {
          const params = _.cloneDeep(this.form) as any;
          if (this.isBlank(params.email)) {
            delete params.email;
          }
          if (this.department.model != null) {
            params.departmentId = this.department.model.id;
          }
          //console.log("params : ", params);
          if (this.type === "add") {
            const user = await UserService.create(params);
          } else if (this.type === "edit") {
            if (!this.changePassword) {
              delete params.password;
              delete params.confirmPassword;
            }
            const user = await UserService.update(this.user.id, params);
          }
          // await this.$store.dispatch("auth/register", params);
          //
          // const alertResult = await core.alert.show({
          //   title: "알림",
          //   body: "회원가입이 완료되었습니다.",
          //   confirmButtonText: "확인",
          // });

          this.close(UpdateEventType.CONFIRM);
        } catch (e) {
          console.log("error : ", e);
        }
        this.processing = false;
      }
    },

    editDone() {
      this.department.editing = null;
      this.department.editingIndex = -1;
    },

    async deleteItem(index, item) {
      try {
        const result = await DepartmentService.delete(item.id);
        const list = this.department.list;
        // this.department.list = await DepartmentService.getList();
        for (let i = 0; i < list.length; i++) {
          const _item = list[i];
          if (_item.id === item.id) {
            list.splice(i, 1);
            break;
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    async edit(index, item) {
      if (!this.department.editing) {
        item.edit = item.name;
        this.department.editing = item;
        this.department.editingIndex = index;
      } else {
        await this.updateDepartment(item);

        item.edit = null;
        this.department.editing = null;
        this.department.editingIndex = -1;
      }
    },

    getDepartment(name) {
      let item = null as any;
      this.department.list.some((_item) => {
        if (_item.name === name) {
          item = _item;
          return true;
        }
      });
      return item;
    },

    async createDepartment() {
      const name = this.department.search;
      // console.log("search : ", this.department.search);
      const params = { name: name };

      try {
        const department = (await DepartmentService.create(params)) as any;
        this.department.list.push(department);
        this.department.model = department;
      } catch (e) {
        console.log(e);
        try {
          this.department.list = await DepartmentService.getList();
        } catch (e) {
          console.log(e);
        }
      }

      // console.log("result : ", result);
    },

    async updateDepartment(item) {
      const params = { name: item.edit };

      try {
        const department = (await DepartmentService.update(item.id, params)) as any;
        item.name = department.name;
        return true;
      } catch (e) {
        console.log(e);
      }
      return false;
    },
  },
});
