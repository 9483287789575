
















































































































































































































































































































































































































































































































































































































import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import VueUploadMultipleImage from "@/components/core/VueUploadMultipleImage.vue";
import core from "@/core";
import DiseaseService from "@/services/disease/disease.service";
import CodeService from "@/services/code/code.service";

export default mixins(MixinsPageForm).extend({
  name: "DiseaseAnalysisRequestViewComponent",
  components: { VueUploadMultipleImage },
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    view: {
      address: "",
      user: "",
    },
    visible: {
      edit: false,
      complete: false,
    },
    images: [] as any,
    requestAnalysis: null as any,
    ready: false,
    cropCodeList: [] as any,
  }),
  mounted() {
    this.$nextTick(async () => {
      const id = Number(this.id);
      try {
        core.loader.show();
        if (!isNaN(id)) {
          const user = await this.$store.getters["auth/user"]();

          const requestAnalysis = (await DiseaseService.getRequestAnalysis(id)) as any;
          if (user.id === requestAnalysis.authorUser.id) {
            this.visible.edit = true;
          }
          this.cropCodeList = await CodeService.getCropCodeList();

          if (user.roles.indexOf("MANAGER") > -1 || user.roles.indexOf("ADMIN") > -1) {
            this.visible.complete = true;
          }

          if (requestAnalysis.address2 != null) {
            this.view.address = `${requestAnalysis.address1} ${requestAnalysis.address2}`;
          } else {
            this.view.address = `${requestAnalysis.address1}`;
          }
          const authorUser = requestAnalysis.authorUser;
          if (authorUser.email != null) {
            this.view.user = authorUser.name + "(" + authorUser.email + ")";
          } else {
            this.view.user = authorUser.name + "(" + authorUser.username + ")";
          }

          requestAnalysis.imageList.forEach((image, index) => {
            //console.log("image : ", image);
            const isDefault = index === 0;

            this.images.push({
              path: image.downloadUri,
              default: isDefault,
              highlight: 1,
              id: image.id,
            });
          });
          this.requestAnalysis = requestAnalysis;
        } else {
          console.log("id : ", id);
          await this.notFound();
          return;
        }
      } catch (e) {
        console.log(e);
        await this.notFound();
        return;
      }
      core.loader.hide();
      this.ready = true;
    });
  },
  methods: {
    renderCropCode(item) {
      let cropName = "";
      this.cropCodeList.some((cropCode) => {
        if (item.cropCode === cropCode.code) {
          cropName = cropCode.koName;
          return true;
        }
      });
      return cropName;
    },
    renderKindCode(item) {
      if (item.kindCode === "S") {
        return "단동";
      } else if (item.kindCode === "M") {
        return "연동";
      }
      return "알수없음";
    },
    showEditPage() {
      // /disease/analysis-request/edit
      this.$router.push({
        path: "/disease/analysis-request/edit",
        query: {
          id: this.requestAnalysis.id,
        },
      });
    },
    async updateComplete(complete) {
      console.log("change complete  : ", complete);
      const requestDiseaseAnalysis = await DiseaseService.updateRequestDiseaseAnalysisComplete(
        this.requestAnalysis.id,
        complete
      );
      this.requestAnalysis = requestDiseaseAnalysis;
    },
    isSelectedCrop(crop) {
      let cropCode = null as any;
      this.cropCodeList.some((_crop) => {
        if (_crop.enName === crop) {
          cropCode = _crop;
          return true;
        }
      });

      if (cropCode != null) {
        return this.requestAnalysis.cropCode === cropCode.code;
      }
      return false;
    },
    getCropPositionStyle(position) {
      position = String(position);
      //console.log("get crop position : ", position);
      if (this.requestAnalysis.cropPosition === position) {
        return "border: 2px solid #38ff00; background: rgba(0, 0, 0, 0.8)";
      }
      return "";
    },
    getHousePositionStyle(position) {
      position = String(position);
      if (this.requestAnalysis.housePosition === position) {
        return "border: 2px solid #38ff00; background: rgba(0, 0, 0, 0.8)";
      }
      return "";
    },
  },
});
