























import Vue from "vue";
import store from "@/store";
import AppBar from "@/components/core/AppBar.vue";
import AlertModal from "@/modals/core/AlertModal.vue";
import { RouteMeta } from "@/router";
import core from "@/core";

export default Vue.extend({
  name: "App",
  components: { AlertModal, AppBar },
  data: () => ({
    transitionName: "forward",
    app: store.state.app,
    prop: {
      menuType: "",
      closeNavigation: false,
    },
    redirect: {
      path: null as string | null,
      interval: null as any,
    },
  }),
  mounted() {
    (window as any).mobileRedirectPath = this.mobileRedirectPath;
    (window as any).showModal = this.showModal;
    // (window as any).showToast = this.showToast;
    // (window as any).occurNotification = this.occurNotification;
    // (window as any).changedUserCompany = this.changedUserCompany;

    const windowResize = this.windowResize;
    window.addEventListener("resize", () => {
      windowResize();
    });
    windowResize();

    const platform = core.utils.platform();
    if (platform === "android" || platform === "ios") {
      this.app.isMobile = true;
    }
    this.app.isApp = core.mobile.isApp();
  },
  watch: {
    $route(to, from) {
      if (to.params["stackKey-dir"] === "forward") {
        this.transitionName = "forward";
      } else {
        this.transitionName = "back";
      }
      const meta = to.meta as RouteMeta;
      this.prop.menuType = meta.menuType;
      this.prop.closeNavigation = true;
      this.windowResize();
    },
    "redirect.path"(path) {
      const redirect = this.redirect;
      if (redirect.path != null && redirect.interval == null) {
        const auth = this.$store.state.auth;
        const router = this.$router;
        const vm = this as any;

        redirect.interval = setInterval(() => {
          if (auth.user != null) {
            const path = redirect.path as string;
            redirect.path = null;
            console.log("redirect : ", path);
            try {
              router.push({
                path: path,
              });
            } catch (e) {
              console.log(e);
            }
          }

          if (redirect.path == null || vm._isDestroyed) {
            clearInterval(redirect.interval as number);
            redirect.interval = null;
          }
        }, 200);
      }
    },
  },
  methods: {
    windowResize() {
      // console.log(`resize width '${window.innerWidth}', '${window.innerHeight}'`);
      // console.log(`resize width '${window.outerWidth}', '${window.outerHeight}'`);
      this.app.size = {
        width: window.innerWidth,
        height: window.innerHeight,
      };
      // document.body.style.minHeight = this.app.size.height + "px";
      //document.documentElement.style.setProperty("--vh", `${this.app.size.height * 0.01}px`);
    },
    showModal: function (params) {
      return core.alert.show(params);
    },
    mobileRedirectPath: function (path: string) {
      console.log("mobileRedirectPath : ", path);
      if (path != null && path.length > 0) {
        this.redirect.path = path;
      }
    },
  },
});
