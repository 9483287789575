


























import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import SearchAddressComponent from "@/components/address/SearchAddressComponent.vue";
import SearchAddressModal from "@/modals/core/SearchAddressModal.vue";
import DiseaseImageComponent from "@/components/disease/analysis/DiseaseImageComponent.vue";
import CodeService from "@/services/code/code.service";
import DiseaseAreaCountMapComponent from "@/components/disease/analysis/DiseaseAreaCountMapComponent.vue";
import DiseaseAreaGalleryComponent from "@/components/disease/analysis/DiseaseAreaGalleryComponent.vue";
import DiseaseImageAnnotationComponent from "@/components/disease/analysis/DiseaseImageAnnotationComponent.vue";
import DiseaseManagerAnalysisComponent from "@/components/disease-manager/AnalysisComponent.vue";
import DiseaseManagerInfoComponent from "@/components/disease-manager/InfoComponent.vue";
import DiseaseManagerInfoAddEditComponent from "@/components/disease-manager/info/AddEditComponent.vue";
import DiseaseManagerMenuComponent from "@/components/disease-manager/MenuComponent.vue";

export default mixins(MixinsPageForm).extend({
  name: "DiseaseManager",
  components: {
    DiseaseManagerInfoAddEditComponent,
    DiseaseManagerMenuComponent,
    DiseaseManagerInfoComponent,
    DiseaseManagerAnalysisComponent,
    DiseaseImageAnnotationComponent,
    DiseaseAreaGalleryComponent,
    DiseaseAreaCountMapComponent,
    DiseaseImageComponent,
    SearchAddressModal,
    SearchAddressComponent,
  },
  data: () => ({
    path: "",
    subPath: "",
    component: {
      analysis: {
        cropCode: "",
      },
    },
    cropCodeList: [] as any,
  }),
  mounted() {
    this.$nextTick(async () => {
      this.path = this.$route.params.path;
      this.subPath = this.$route.params.subPath;
      //console.log("path : ", this.path, ", subPath :", this.subPath);

      // this.path = this.$route.params.path;
      // console.log("path :", this.path);
      if (this.path === "tomato" || this.path === "strawberry" || this.path === "paprika") {
        this.cropCodeList = await CodeService.getCropCodeList();
        //console.log("cropCodeList : ", this.cropCodeList);
        this.changeCrop(this.path);
      } else {
        // console.log("subPath : ", this.subPath);
      }
    });
  },
  methods: {
    changeCrop(cropName) {
      //console.log("cropName : ", cropName);
      this.cropCodeList.forEach((crop) => {
        if (crop.enName === cropName) {
          this.component.analysis.cropCode = crop.code;
          //console.log("changed crop code : ", crop.code);
        }
      });
    },
  },
});
