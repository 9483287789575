


















import mixins from "vue-typed-mixins";
import MixinsModal from "@/mixins/modal";

export default mixins(MixinsModal).extend({
  name: "AlertModal",
  props: {
    properties: {
      type: Object,
      default: () => {
        return {
          visible: false,
          showCancelButton: false,
          title: "제목",
          body: "내용",
          cancelButtonText: "취소",
          confirmButtonText: "확인",
          promise: {
            resolve: null,
            reject: null,
          },
          allowBackCloseEvent: true,
        };
      },
    },
  },
  data: () => ({
    keyEvent: null as any,
  }),
  mounted() {
    // 페이지 로딩 후 호출
    const startDate = new Date();
    const cancel = this.cancel;
    const confirm = this.confirm;
    this.keyEvent = (e: any) => {
      const curDate = new Date();
      const diffTimeMillis = curDate.getTime() - startDate.getTime();
      if (diffTimeMillis < 500) {
        return;
      }
      const code = e.code;
      if (code === "Escape") {
        cancel();
      } else if (code === "Enter" || code === "NumpadEnter" || code === "Space") {
        confirm();
      }
    };
    window.addEventListener("keydown", this.keyEvent);
  },
  destroyed() {
    window.removeEventListener("keydown", this.keyEvent);
  },
  methods: {
    confirm() {
      //console.log('confirm');
      this.properties.visible = false;
      if (this.properties.promise != null) {
        this.properties.promise.resolve("confirm");
      }
    },

    cancel() {
      //console.log('cancel');
      this.properties.visible = false;
      if (this.properties.promise != null) {
        this.properties.promise.resolve("cancel");
      }
    },
  },
});
