import core from "@/core";

class SensorService {
  getExtEnvList(params) {
    const urlParams = {
      facilityId: params.facilityId,
      sensingAtGreaterThanEqual: params.sensingAtGreaterThanEqual,
      sensingAtLessThan: params.sensingAtLessThan,
    };
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`/api/sensor/ext-env`, null, urlParams)
        .then((data) => {
          if (data.extEnvList) {
            resolve(data.extEnvList);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  getExtEnvAvgList(params) {
    const urlParams = {
      facilityId: params.facilityId,
      type: params.type,
      sensingAtGreaterThanEqual: params.sensingAtGreaterThanEqual,
      sensingAtLessThan: params.sensingAtLessThan,
    };
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`/api/sensor/ext-env/avg`, null, urlParams)
        .then((data) => {
          if (data.extEnvList) {
            resolve(data.extEnvList);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  getInEnvList(params) {
    const urlParams = {
      inFacilityId: params.inFacilityId,
      sensingAtGreaterThanEqual: params.sensingAtGreaterThanEqual,
      sensingAtLessThan: params.sensingAtLessThan,
    };
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`/api/sensor/in-env`, null, urlParams)
        .then((data) => {
          if (data.inEnvList) {
            resolve(data.inEnvList);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  getInEnvAvgList(params) {
    const urlParams = {
      inFacilityId: params.inFacilityId,
      type: params.type,
      sensingAtGreaterThanEqual: params.sensingAtGreaterThanEqual,
      sensingAtLessThan: params.sensingAtLessThan,
    };
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`/api/sensor/in-env/avg`, null, urlParams)
        .then((data) => {
          if (data.inEnvList) {
            resolve(data.inEnvList);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  getRootEnvList(params) {
    const urlParams = {
      inFacilityId: params.inFacilityId,
      sensingAtGreaterThanEqual: params.sensingAtGreaterThanEqual,
      sensingAtLessThan: params.sensingAtLessThan,
    };
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`/api/sensor/root-env`, null, urlParams)
        .then((data) => {
          if (data.rootEnvList) {
            resolve(data.rootEnvList);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  getRootEnvAvgList(params) {
    const urlParams = {
      inFacilityId: params.inFacilityId,
      type: params.type,
      sensingAtGreaterThanEqual: params.sensingAtGreaterThanEqual,
      sensingAtLessThan: params.sensingAtLessThan,
    };
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`/api/sensor/root-env/avg`, null, urlParams)
        .then((data) => {
          if (data.rootEnvList) {
            resolve(data.rootEnvList);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  getNutrientSolutionList(params) {
    const urlParams = {
      inFacilityId: params.inFacilityId,
      sensingAtGreaterThanEqual: params.sensingAtGreaterThanEqual,
      sensingAtLessThan: params.sensingAtLessThan,
    };
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`/api/sensor/nutrient-solution`, null, urlParams)
        .then((data) => {
          if (data.nutrientSolutionList) {
            resolve(data.nutrientSolutionList);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  getNutrientSolutionAvgList(params) {
    const urlParams = {
      inFacilityId: params.inFacilityId,
      type: params.type,
      sensingAtGreaterThanEqual: params.sensingAtGreaterThanEqual,
      sensingAtLessThan: params.sensingAtLessThan,
    };
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`/api/sensor/nutrient-solution/avg`, null, urlParams)
        .then((data) => {
          if (data.nutrientSolutionList) {
            resolve(data.nutrientSolutionList);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  getEnvControlStatusList(params) {
    const urlParams = {
      inFacilityId: params.inFacilityId,
      sensingAtGreaterThanEqual: params.sensingAtGreaterThanEqual,
      sensingAtLessThan: params.sensingAtLessThan,
    };
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`/api/sensor/env-control-status`, null, urlParams)
        .then((data) => {
          if (data.envControlStatusList) {
            resolve(data.envControlStatusList);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  getEnvPowerList(params) {
    const urlParams = {
      inFacilityId: params.inFacilityId,
      powerAtGreaterThanEqual: params.powerAtGreaterThanEqual,
      powerAtLessThan: params.powerAtLessThan,
    };
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`/api/sensor/env-power`, null, urlParams)
        .then((data) => {
          if (data.envPowerList) {
            resolve(data.envPowerList);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }
}

export default new SensorService();
