class TranspirationCalculator {
  recommendTable = {
    temp: {
      12: { min: 45, max: 70 },
      13: { min: 50, max: 75 },
      14: { min: 55, max: 75 },
      15: { min: 55, max: 75 },
      16: { min: 60, max: 75 },
      17: { min: 60, max: 80 },
      18: { min: 65, max: 80 },
      19: { min: 65, max: 80 },
      20: { min: 70, max: 80 },
      21: { min: 70, max: 85 },
      22: { min: 70, max: 85 },
      23: { min: 75, max: 85 },
      24: { min: 75, max: 85 },
      25: { min: 75, max: 85 },
      26: { min: 80, max: 85 },
      27: { min: 80, max: 85 },
      28: { min: 80, max: 85 },
      29: { min: 80, max: 90 },
      30: { min: 85, max: 90 },
    },
    humidity: {
      45: { min: 14, max: 14 },
      50: { min: 12, max: 13 },
      55: { min: 12, max: 15 },
      60: { min: 12, max: 17 },
      65: { min: 12, max: 19 },
      70: { min: 12, max: 22 },
      75: { min: 13, max: 25 },
      80: { min: 17, max: 29 },
      85: { min: 21, max: 30 },
      90: { min: 29, max: 30 },
    },
  };

  recommend(temp, humidity, hd) {
    if (hd == null) {
      // 포화수증기압 계산
      const saturatedWaterVaporPressure = this.saturatedWaterVaporPressure(temp);
      // 절대습도 계산
      const absoluteHumidity = this.absoluteHumidity(saturatedWaterVaporPressure, humidity);
      // 수분부족분(hd) 계산
      hd = this.humidityDeficit(saturatedWaterVaporPressure, absoluteHumidity);
    }
    const result = {};
    if (hd < 3) {
      // 온도 올리거나, 습도 낮춤
      // 1도씩 증가 후 온도값 계산
      let recommendTemp = Math.round(temp);
      for (; recommendTemp < 100; recommendTemp++) {
        // 포화수증기압 계산
        const saturatedWaterVaporPressure = this.saturatedWaterVaporPressure(recommendTemp);
        // 절대습도 계산
        const absoluteHumidity = this.absoluteHumidity(saturatedWaterVaporPressure, humidity);
        // 수분부족분(hd) 계산
        const hd = this.humidityDeficit(saturatedWaterVaporPressure, absoluteHumidity);
        if (hd > 3) {
          break;
        }
      }

      let recommendHumidity = Math.round(humidity);
      for (; recommendHumidity >= 0; recommendHumidity--) {
        // 포화수증기압 계산
        const saturatedWaterVaporPressure = this.saturatedWaterVaporPressure(temp);
        // 절대습도 계산
        const absoluteHumidity = this.absoluteHumidity(
          saturatedWaterVaporPressure,
          recommendHumidity
        );
        // 수분부족분(hd) 계산
        const hd = this.humidityDeficit(saturatedWaterVaporPressure, absoluteHumidity);
        if (hd > 3) {
          break;
        }
      }
      return {
        temp: recommendTemp,
        humidity: recommendHumidity,
      };
    } else if (8 < hd) {
      // 온도 낮추거나, 습도 올림

      // 1도씩 증가 후 온도값 계산
      let recommendTemp = Math.round(temp);
      for (; recommendTemp >= 10; recommendTemp--) {
        // 포화수증기압 계산
        const saturatedWaterVaporPressure = this.saturatedWaterVaporPressure(recommendTemp);
        // 절대습도 계산
        const absoluteHumidity = this.absoluteHumidity(saturatedWaterVaporPressure, humidity);
        // 수분부족분(hd) 계산
        const hd = this.humidityDeficit(saturatedWaterVaporPressure, absoluteHumidity);
        if (hd < 8) {
          break;
        }
      }

      let recommendHumidity = Math.round(humidity);
      for (; recommendHumidity < 100; recommendHumidity++) {
        // 포화수증기압 계산
        const saturatedWaterVaporPressure = this.saturatedWaterVaporPressure(temp);
        // 절대습도 계산
        const absoluteHumidity = this.absoluteHumidity(
          saturatedWaterVaporPressure,
          recommendHumidity
        );
        // 수분부족분(hd) 계산
        const hd = this.humidityDeficit(saturatedWaterVaporPressure, absoluteHumidity);
        if (hd < 8) {
          break;
        }
      }
      return {
        temp: recommendTemp,
        humidity: recommendHumidity,
      };
    }
    return {
      temp: temp,
      humidity: humidity,
    };
  }

  newInstance(params) {
    // params = {
    //   dryBulbTemp: 0,
    //   relativeHumidity: 0,
    // };
    //console.log("params : ", params);
    if (params.dryBulbTemp == null) {
      throw Error("not found dryBulbTemp value");
    } else if (params.relativeHumidity == null) {
      throw Error("not found relativeHumidity value");
    }
    const dryBulbTemp = params.dryBulbTemp;
    const wetBulbTemp = params.wetBulbTemp;
    const relativeHumidity = params.relativeHumidity;

    // 이슬점 온도 계산
    const dewPointTemp = this.dewPointTemp(dryBulbTemp, relativeHumidity);
    // 포화수증기압 계산
    const saturatedWaterVaporPressure = this.saturatedWaterVaporPressure(dryBulbTemp);
    // 절대습도 계산
    const absoluteHumidity = this.absoluteHumidity(saturatedWaterVaporPressure, relativeHumidity);
    // 수분부족분(hd) 계산
    const humidityDeficit = this.humidityDeficit(saturatedWaterVaporPressure, absoluteHumidity);

    // 수증기압 포차(VPD)
    const vaporPressureDeficit = this.vaporPressureDeficit(humidityDeficit);

    const transpiration = {} as any;
    transpiration.dryBulbTemp = this.convertDecimalPlacesValue(dryBulbTemp);
    transpiration.wetBulbTemp =
      wetBulbTemp != null ? this.convertDecimalPlacesValue(wetBulbTemp) : null;
    transpiration.relativeHumidity = this.convertDecimalPlacesValue(relativeHumidity);
    transpiration.dewPointTemp = this.convertDecimalPlacesValue(dewPointTemp);
    transpiration.saturatedWaterVaporPressure = this.convertDecimalPlacesValue(
      saturatedWaterVaporPressure
    );
    transpiration.absoluteHumidity = this.convertDecimalPlacesValue(absoluteHumidity);
    transpiration.hd = this.convertDecimalPlacesValue(humidityDeficit);

    const hd = transpiration.hd;
    if (3 <= hd && hd < 8) {
      transpiration.hdColor = "bg-color-green-dark";
      transpiration.hdLabel = "적절";
    } else if (8 <= hd) {
      transpiration.hdColor = "bg-color-red";
      transpiration.hdLabel = "과다";
    } else {
      transpiration.hdColor = "bg-color-orange";
      transpiration.hdLabel = "부족";
    }

    transpiration.vpd = this.convertDecimalPlacesValue(vaporPressureDeficit);
    const vpd = transpiration.vpd;
    if (0.5 <= vpd && vpd < 1.2) {
      transpiration.vpdColor = "bg-color-green-dark";
      transpiration.vpdLabel = "적절";
    } else if (1.2 <= vpd) {
      transpiration.vpdColor = "bg-color-red";
      transpiration.vpdLabel = "과다";
    } else {
      transpiration.vpdColor = "bg-color-orange";
      transpiration.vpdLabel = "부족";
    }

    transpiration.recommend = this.recommend(dryBulbTemp, relativeHumidity, humidityDeficit);

    return transpiration;
  }

  convertDecimalPlacesValue(value) {
    return Math.round(value * 100) / 100;
  }

  // 상대습도(rh) 계산
  relativeHumidity(dryBulbTemp, wetBulbTemp) {
    const ed = 6.112 * Math.pow(2.71828182845903, (17.502 * dryBulbTemp) / (240.97 + dryBulbTemp));
    const ew = 6.112 * Math.pow(2.71828182845903, (17.502 * wetBulbTemp) / (240.97 + wetBulbTemp));
    return (
      ((ew - 0.6687451584 * (1 + 0.00115 * wetBulbTemp) * (dryBulbTemp - wetBulbTemp)) / ed) * 100
    );
  }

  // 이슬점 온도 계산
  dewPointTemp(temp, relativeHumidity) {
    const sat = 6.112 * Math.exp((17.67 * temp) / (temp + 243.5));
    const actual = (sat * relativeHumidity) / 100;
    const tmp = Math.log(actual / 6.112);
    return (243.5 * tmp) / (17.67 - tmp);
  }

  // 포화수증기압(pws) 계산
  saturatedWaterVaporPressure(dryBulbTemp) {
    return (
      0.00036 * Math.pow(dryBulbTemp, 3) +
      0.00543 * Math.pow(dryBulbTemp, 2) +
      0.37067 * dryBulbTemp +
      4.81865
    );
  }

  // 수증기압(pw) 계산
  waterVaporPressure(saturatedWaterVaporPressure, relativeHumidity) {
    return (saturatedWaterVaporPressure * relativeHumidity) / 100;
  }

  // 절대 습도(ah) 계산
  absoluteHumidity(saturatedWaterVaporPressure, relativeHumidity) {
    // return (2.16679 * waterVaporPressure * 100) / (273.15 + dryBulbTemp);
    return saturatedWaterVaporPressure * (relativeHumidity / 100);
  }

  // 포화수분함량(smc) 계산
  saturatedMoistureContent(dryBulbTemp, saturatedWaterVaporPressure) {
    return (2.16679 * saturatedWaterVaporPressure * 100) / (273.15 + dryBulbTemp);
  }

  // 수분부족분(hd) 계산
  humidityDeficit(saturatedWaterVaporPressure, absoluteHumidity) {
    return saturatedWaterVaporPressure - absoluteHumidity;
  }

  vaporPressureDeficit(humidityDeficit) {
    return humidityDeficit * (2 / 3) * (1 / 5);
  }
}

export default new TranspirationCalculator();
