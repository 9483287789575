






















import mixins from "vue-typed-mixins";
import MixinsComponent from "@/mixins/component";

export default mixins(MixinsComponent).extend({
  name: "ElectricalComponent",
  props: {
    menu: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  data: () => ({
    iframeSrc: "http://222.105.187.66:25380",
  }),
  methods: {
    showPage() {
      window.open("http://222.105.187.66:25380", "_blank");
    },
    toggleMenu() {
      this.$emit("update:menu", !this.menu);
    },
  },
});
