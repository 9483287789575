import core from "@/core";

import { createClient, WebDAVClient } from "webdav";
import axios from "axios";

class OwncloudService {
  authDate = null as Date | null;
  client = null as WebDAVClient | null;

  getUserInfo() {
    return new Promise((resolve, reject) => {
      core.http
        .get(`/api/user/owncloud`)
        .then((data) => {
          //console.log('result: ', data);
          resolve(data.owncloud);
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  async isLogin() {
    if (this.authDate == null) return false;
    const date = new Date();
    const elapsedTimeMillis = date.getTime() - this.authDate.getTime();
    if (elapsedTimeMillis > 300000) {
      // 5분 이상 경과 됬을 경우 세션 체크
      try {
        await this.client?.exists("/");
        return true;
      } catch (e) {
        console.log("error : ", e);
        return false;
      }
    }
    return true;
  }

  async owncloudLogin(): Promise<boolean> {
    try {
      //console.log("login");
      if (!(await this.isLogin())) {
        const user = (await this.getUserInfo()) as any;
        const client = await createClient(`/owncloud/remote.php/dav/files/${user.username}/`, {
          username: user.username,
          password: user.password,
        });
        //console.log("client : ", client.getHeaders());
        await client.exists("/");
        client.setHeaders({});

        this.client = client;
        this.authDate = new Date();
        return true;
      } else {
        return true;
      }
    } catch (e) {
      console.log(e);
    }
    return false;
  }

  async owncloudLogout() {
    try {
      const resp = await axios.get("/owncloud/index.php/logout");
      const data = resp.data;
      //console.log("인증 해제됨");
    } catch (e) {
      if (e.response) {
        if (e.response.status === 401) {
          //console.log("인증 해제됨");
        }
      } else {
        console.log(e);
      }
    } finally {
      this.client = null;
      this.authDate = null;
    }
  }

  async getDirectoryContents(path) {
    //console.log("getDirectoryContents : ", path);
    if (this.client == null) {
      //console.log("login check");
      if (!(await this.owncloudLogin())) {
        console.log("인증 실패");
        this.client = null;
        this.authDate = null;
        return null;
      }
    }
    try {
      const directory = await this.client?.getDirectoryContents(path);
      this.authDate = new Date();
      return directory;
    } catch (e) {
      console.log("error : ", e);
      this.client = null;
      this.authDate = null;
      return this.getDirectoryContents(path);
    }
  }

  async getPublicLink(path) {
    if (this.client == null) {
      //console.log("login check");
      if (!(await this.owncloudLogin())) {
        console.log("인증 실패");
        this.client = null;
        this.authDate = null;
        return null;
      }
    }
    let downloadUrl = null as any;
    try {
      const params = {
        format: "json",
        reshares: true,
        path: path,
      };
      const response = await core.http.get(
        `/owncloud/ocs/v2.php/apps/files_sharing/api/v1/shares`,
        null,
        params
      );
      console.log("response : ", response);
      if (response.ocs) {
        const ocsDataList = response.ocs.data;
        if (ocsDataList != null) {
          ocsDataList.forEach((data) => {
            // console.log("data : ", data);
            if (data.name === "system") {
              const url = data.url;
              const el = document.createElement("a");
              el.href = url;
              downloadUrl = el.pathname + "/download";
            }
          });
        }
      }
    } catch (e) {
      console.log("error : ", e);
    }

    if (downloadUrl == null) {
      return await this.createPublicLink(path);
    }
    return downloadUrl;
  }

  async createPublicLink(path) {
    const params = {
      expireDate: "",
      permissions: 1,
      name: "system",
      shareType: 3,
      path: path,
    };
    const strParams = JSON.stringify(params);
    try {
      const response = await core.http.postJson(
        `/owncloud/ocs/v2.php/apps/files_sharing/api/v1/shares?format=json`,
        strParams
      );
      console.log("response : ", response);
      if (response.ocs) {
        const data = response.ocs.data;
        const url = data.url;
        const el = document.createElement("a");
        el.href = url;
        return el.pathname + "/download";
      }
    } catch (e) {
      console.log("error : ", e);
    }
    return null;
  }
}

export default new OwncloudService();
