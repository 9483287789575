
































import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import CodeService from "@/services/code/code.service";
import DiseaseAnalysisComponent from "@/components/disease/AnalysisComponent.vue";
import DiseaseMenuComponent from "@/components/disease/MenuComponent.vue";
import DiseaseAnalysisRequestComponent from "@/components/disease/AnalysisRequestComponent.vue";
import DiseaseAnalysisRequestAddEditComponent from "@/components/disease/analysis-request/AddEditComponent.vue";
import DiseaseAnalysisRequestViewComponent from "@/components/disease/analysis-request/ViewComponent.vue";

export default mixins(MixinsPageForm).extend({
  name: "Disease",
  components: {
    DiseaseAnalysisRequestViewComponent,
    DiseaseAnalysisRequestAddEditComponent,
    DiseaseAnalysisRequestComponent,
    DiseaseMenuComponent,
    DiseaseAnalysisComponent,
  },
  data: () => ({
    path: "",
    subPath: "",
    component: {
      analysis: {
        cropCode: "",
        cropName: "",
      },
    },
    cropCodeList: [] as any,
  }),
  mounted() {
    this.$nextTick(async () => {
      this.path = this.$route.params.path;
      this.subPath = this.$route.params.subPath;
      if (this.path === "tomato" || this.path === "strawberry" || this.path === "paprika") {
        this.cropCodeList = await CodeService.getCropCodeList();
        //console.log("cropCodeList : ", this.cropCodeList);
        this.changeCrop(this.path);
      } else {
        // console.log("subPath : ", this.subPath);
      }
    });
  },
  methods: {
    changeCrop(cropName) {
      //console.log("cropName : ", cropName);
      let crop = null as any;
      this.cropCodeList.forEach((_crop) => {
        if (_crop.enName === cropName) {
          crop = _crop;
          return;
        }
      });
      if (crop != null) {
        this.component.analysis.cropName = cropName;
        this.component.analysis.cropCode = crop.code;
        console.log("changed crop code : ", crop.code);
      } else {
        console.log("unknown cropName : ", cropName);
      }
    },
  },
});
