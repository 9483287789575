String.prototype.replaceAll = function (org, dest) {
  return this.split(org).join(dest);
};

class FormatUtils {
  private readonly validate: any;

  constructor(validate: any) {
    this.validate = validate;
  }

  filesize(si: boolean, fileSize: number, decimalPlaces: number) {
    const unit = si ? 1000 : 1024;
    if (fileSize < unit) return fileSize + " B";
    const exp = parseInt(String(Math.log(fileSize) / Math.log(unit)));
    const pre = (si ? "kMGTPE" : "KMGTPE").charAt(exp - 1) + (si ? "" : "i");
    if (decimalPlaces == null || decimalPlaces < 0) {
      decimalPlaces = 0;
    }
    return (fileSize / Math.pow(unit, exp)).toFixed(decimalPlaces) + " " + pre + "B";
  }

  money(str: string) {
    if (str == null) return "";
    return this.onlyNumber(str)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  moneyKor(str: string) {
    // const money = Number(this.onlyNumber(str)) / 10000;
    // return String(money).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "만원";
    let money = Number(this.onlyNumber(str));
    if (str.toString().startsWith("-")) {
      money = money * -1;
    }
    const temp = money / 10000;
    if (temp == parseInt(String(temp))) {
      return parseInt(String(temp)) + "만원";
    }
    const tmp = money % 10000;
    if (parseInt(String(temp)) !== 0) {
      return parseInt(String(temp)) + "만 " + tmp + "원";
    }
    return tmp + "원";
  }

  textToHtml(content: string) {
    if (this.validate.isBlank(content)) return content;
    const regexpList = [
      new RegExp(
        "(0(2|3[1-3]|4[1-4]|5[1-5]|6[1-4]|70|1[0|1|6|7|8|9])(\\s*)[.-]?(\\s*)(\\d{4}|\\d{3})(\\s*)[.-]?(\\s*)(\\d{4}))",
        "g"
      ),
      new RegExp("1(5|6|8)(\\d{2})[.-]?(\\s*)(\\d{4})", "g"),
    ];
    const array = content.split("\n");
    let html = "";
    const vm = this;
    array.forEach((content: any) => {
      regexpList.some((regexp) => {
        const match = content.match(regexp);
        if (match) {
          match.forEach((phone: string) => {
            const newPhone = vm.hyphenPhone(phone);
            content = content.replace(phone, `<a href="tel:${newPhone}">${phone}</a>`);
          });
          return true;
        }
      });
      html += `<p>${content}</p>`;
    });
    return html;
  }

  textToPhoneNumber(content: string) {
    if (this.validate.isBlank(content)) return null;
    const regexpList = [
      new RegExp(
        "(0(2|3[1-3]|4[1-4]|5[1-5]|6[1-4]|70|1[0|1|6|7|8|9])(\\s*)[.-]?(\\s*)(\\d{4}|\\d{3})(\\s*)[.-]?(\\s*)(\\d{4}))",
        "g"
      ),
      new RegExp("1(5|6|8)(\\d{2})[.-]?(\\s*)(\\d{4})", "g"),
    ];
    const list = [] as string[];
    const array = content.split("\n");
    let replaceContent = content;
    const vm = this;
    array.forEach((content: any) => {
      regexpList.some((regexp) => {
        const match = content.match(regexp);
        if (match) {
          match.forEach((strMatch: string) => {
            replaceContent = replaceContent.replaceAll(strMatch, "").trim();
            list.push(vm.hyphenPhone(strMatch).replaceAll("-", ""));
          });
          return true;
        }
      });
    });
    if (list.length > 0) {
      return {
        replaceContent: replaceContent,
        list: list,
      };
    }
    return null;
  }

  textToEmail(content: string) {
    if (this.validate.isBlank(content)) return null;
    const regexp = new RegExp("([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\\.[a-zA-Z0-9._-]+)", "gi");
    const list = [] as string[];
    const array = content.split("\n");
    let replaceContent = content;
    array.forEach((content: any) => {
      const match = content.match(regexp);
      if (match) {
        match.forEach((strMatch: string) => {
          replaceContent = replaceContent.replaceAll(strMatch, "").trim();
          list.push(strMatch);
        });
      }
    });
    if (list.length > 0) {
      return {
        replaceContent: replaceContent,
        list: list,
      };
    }
    return null;
  }

  textToDongHo(content: string) {
    if (this.validate.isBlank(content)) return null;

    const regexp = new RegExp("(\\d+)(\\s*)[동.-](\\s*)(\\d+)(\\s*)[호]?", "g");

    const dongRegexp = new RegExp("(\\d+)(\\s*)[동.-]", "g");
    const hoRegexp = new RegExp("[동.-](\\s*)(\\d+)(\\s*)[호]?", "g");

    const list = [] as string[];
    const array = content.split("\n");
    let replaceContent = content;
    const vm = this;
    array.forEach((content: any) => {
      const match = content.match(regexp);
      if (match) {
        match.forEach((strMatch: string) => {
          replaceContent = replaceContent.replaceAll(strMatch, "").trim();
          const result = {
            dong: null,
            ho: null,
          } as any;
          const dongMatch = strMatch.match(dongRegexp);
          if (dongMatch) {
            dongMatch.forEach((strDongMatch: string) => {
              result.dong = vm.onlyNumber(strDongMatch);
            });
          }
          const hoMatch = strMatch.match(hoRegexp);
          if (hoMatch) {
            hoMatch.forEach((strHoMatch: string) => {
              result.ho = vm.onlyNumber(strHoMatch);
            });
          }

          if (result.dong && result.ho) {
            list.push(result);
          }
        });
        return true;
      }
    });
    if (list.length > 0) {
      return {
        replaceContent: replaceContent,
        list: list,
      };
    }
    return null;
  }

  hyphenPhone(strParam: string) {
    if (strParam == null) return "";
    const str = strParam.toString().replace(/[^0-9]/g, "");
    let tmp = "";
    const isServiceNumber = str.startsWith("1");
    if (isServiceNumber) {
      const startLength = 4;
      if (str.length < 4) {
        return str;
      } else {
        tmp += str.substr(0, startLength);
        tmp += "-";
        tmp += str.substr(startLength);
        //console.log("tmp : ", tmp);
        return tmp;
      }
    } else {
      const isSeoul = str.startsWith("02");
      const startLength = isSeoul ? 2 : 3;
      if (str.length < 4) {
        if (isSeoul && str.length === 3) {
          tmp += str.substr(0, startLength);
          tmp += "-";
          tmp += str.substr(startLength);
          return tmp;
        }
        return str;
      } else if (str.length < 7) {
        tmp += str.substr(0, startLength);
        tmp += "-";
        tmp += str.substr(startLength);
        return tmp;
      } else if (str.length < startLength + 8) {
        tmp += str.substr(0, startLength);
        tmp += "-";
        tmp += str.substr(startLength, 3);
        tmp += "-";
        tmp += str.substr(startLength + 3);
        return tmp;
      } else {
        tmp += str.substr(0, startLength);
        tmp += "-";
        tmp += str.substr(startLength, 4);
        tmp += "-";
        tmp += str.substr(startLength + 4, 4);
        return tmp;
      }
    }
  }

  numberAndDash(strParam: string) {
    if (strParam == null) return "";
    return strParam.toString().replace(/[^0-9\\-]/g, "");
  }

  numberAndDot(strParam: string) {
    if (strParam == null) return "";
    return strParam.toString().replace(/[^0-9\\.]/g, "");
  }

  onlyNumber(str: string) {
    if (str == null) return "";
    return str.toString().replace(/[^0-9]/g, "");
  }

  reformatHost(str: string) {
    return str.replace(/([^:]\/)\/+/g, "$1");
  }
}

class ValidateUtils {
  phone(phoneNumber: string) {
    return /^01(?:0|1|[6-9])[.-]?(\d{3}|\d{4})[.-]?(\d{4})$/.test(phoneNumber);
  }

  isBlank(value) {
    if (value != null) {
      if (typeof value === "object") {
        return false;
      } else {
        return String(value).trim().length === 0;
      }
    }
    return true;
  }

  isNotBlank(value) {
    return !this.isBlank(value);
  }
}

class ImageUtils {
  dataURItoBlob(dataURI: string, type?: string) {
    if (type == null) type = "image/jpeg";
    const bytes =
      dataURI.split(",")[0].indexOf("base64") >= 0
        ? atob(dataURI.split(",")[1])
        : unescape(dataURI.split(",")[1]);
    // const mime = dataURI
    //   .split(",")[0]
    //   .split(":")[1]
    //   .split(";")[0];
    const max = bytes.length;
    const ia = new Uint8Array(max);
    for (let i = 0; i < max; i++) ia[i] = bytes.charCodeAt(i);
    try {
      return new Blob([ia], { type: type });
    } catch (e) {
      return null;
    }
  }

  getImageBlob(file: any, maxSize?: number) {
    const vm = this;
    return new Promise((resolve: any, reject) => {
      try {
        const reader = new FileReader();
        reader.onload = (event: any) => {
          const dataUri = event.target.result;
          if (maxSize != null) {
            const image = new Image();
            image.src = dataUri;
            image.onload = () => {
              let width = image.width;
              let height = image.height;
              if (width > height && width > maxSize) {
                height *= maxSize / width;
                width = maxSize;
              } else if (height > maxSize) {
                width *= maxSize / height;
                height = maxSize;
              }
              const canvas = document.createElement("canvas") as any;
              canvas.width = width;
              canvas.height = height;
              canvas.getContext("2d").drawImage(image, 0, 0, width, height);
              const dataUri = canvas.toDataURL(file.type);
              resolve({
                filename: file.name,
                blob: vm.dataURItoBlob(dataUri),
                dataUri: dataUri,
              });
            };
          } else {
            resolve({
              filename: file.name,
              blob: vm.dataURItoBlob(dataUri),
              dataUri: dataUri,
            });
          }
        };
        reader.readAsDataURL(file);
      } catch (e) {
        reject(e);
      }
    });
  }
}

export default class CoreUtils {
  validate = new ValidateUtils();
  format = new FormatUtils(this.validate);
  image = new ImageUtils();

  resetObject(obj: any) {
    // Handle Array
    if (obj instanceof Array) {
      for (let i = 0, len = obj.length; i < len; i++) {
        const value = obj[i];
        if (value instanceof Object || value instanceof Array) {
          this.resetObject(value);
        } else if (value instanceof String) {
          obj[i] = "";
        } else {
          obj[i] = null;
        }
      }
      return;
    }

    // Handle Object
    else if (obj instanceof Object) {
      for (const attr in obj) {
        if (obj.hasOwnProperty(attr)) {
          const value = obj[attr];
          if (value instanceof Object || value instanceof Array) {
            this.resetObject(value);
          } else if (value instanceof String) {
            obj[attr] = "";
          } else {
            obj[attr] = null;
          }
        }
      }
      return;
    }

    throw new Error("Unable to copy obj! Its type isn't supported.");
  }

  // 2021-09-30 lodash 라이브러리 호출로 변경
  // deepCopy(src: any): any | null {
  //   // Handle the 3 simple types, and null or undefined
  //   if (null == src || "object" != typeof src) return src;
  //
  //   // Handle Date
  //   if (src instanceof Date) {
  //     const copy: any = new Date();
  //     copy.setTime(src.getTime());
  //     return copy;
  //   }
  //
  //   // Handle Array
  //   if (src instanceof Array) {
  //     const copy: any = [];
  //     for (let i = 0, len = src.length; i < len; i++) {
  //       copy[i] = this.deepCopy(src[i]);
  //     }
  //     return copy;
  //   }
  //
  //   // Handle Object
  //   if (src instanceof Object) {
  //     const copy: any = {};
  //     for (const attr in src) {
  //       if (src.hasOwnProperty(attr)) {
  //         copy[attr] = this.deepCopy(src[attr]);
  //       }
  //     }
  //     return copy;
  //   }
  //
  //   throw new Error("Unable to copy obj! Its type isn't supported.");
  // }
  //
  // 2021-09-30 lodash 라이브러리 호출로 변경
  // copy(src: any, dest: any) {
  //   for (const key of Object.keys(src)) {
  //     const value = src[key];
  //     if (typeof value === "object") {
  //       if (dest[key] == null) {
  //         dest[key] = this.deepCopy(src[key]);
  //       } else {
  //         this.copy(value, dest[key]);
  //       }
  //     } else {
  //       dest[key] = value;
  //     }
  //   }
  // }

  platform() {
    const userAgent = navigator.userAgent.toLowerCase();
    let platform = "";
    if (userAgent.indexOf("android") > -1) {
      platform = "android";
    } else if (
      userAgent.indexOf("iphone") > -1 ||
      userAgent.indexOf("ipad") > -1 ||
      userAgent.indexOf("ipod") > -1
    ) {
      // IOS
      platform = "ios";
    } else if (userAgent.indexOf("windows") > -1) {
      platform = "windows";
    } else {
      // 윈도우, 아이폰, 안드로이드 외
      platform = "other";
    }
    return platform;
  }

  redirectWebviewBrowser(router: any) {
    const userAgent = navigator.userAgent.toLowerCase();
    let webView = false;
    let isSccWebView = false;
    let platform = "";

    if (userAgent.indexOf("android") > -1) {
      platform = "android";
      if (userAgent.indexOf("wv") > -1) {
        webView = true;
      }
      if (userAgent.indexOf("scc") > -1) {
        isSccWebView = true;
      }
    } else if (
      userAgent.indexOf("iphone") > -1 ||
      userAgent.indexOf("ipad") > -1 ||
      userAgent.indexOf("ipod") > -1
    ) {
      // IOS
      platform = "ios";
      webView = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(navigator.userAgent);
    } else if (userAgent.indexOf("windows") > -1) {
      platform = "windows";
    } else {
      // 윈도우, 아이폰, 안드로이드 외
      platform = "other";
    }

    if (webView && !isSccWebView) {
      if (platform === "android") {
        const host = this.format.reformatHost(location.href.substring(location.protocol.length));
        const scheme = location.protocol.substr(0, location.protocol.length - 1);
        router.replace("/error/not-support-browser");
        setTimeout(() => {
          location.href = `intent:${host}#Intent;package=com.android.chrome;scheme=${scheme};end`;
        }, 500);
        return true;
      }
    }
    return false;
  }

  loadScript(url: string) {
    return new Promise((resolve: any, reject) => {
      const script = document.createElement("script") as any;
      script.src = url;
      let isLoadScript = false;
      const onLoadScript = function () {
        if (isLoadScript) return;
        isLoadScript = true;
        resolve();
      };
      script.onreadystatechange = function () {
        if (this.readyState === "complete" || this.readyState === "loaded") onLoadScript();
      };
      script.onload = onLoadScript;
      const head = document.querySelector("head") as any;
      head.appendChild(script);
    });
  }

  getValueFromObjectId(obj: any, id: string): any {
    const pos = id.indexOf(".");
    if (pos > -1) {
      const _id = id.substr(0, pos);
      const value = obj[_id];
      if (typeof value === "object") {
        return this.getValueFromObjectId(value, id.substr(pos + 1));
      }
      return value;
    } else {
      return obj[id];
    }
  }

  getCookie(name: string): string | null {
    let value = null as string | null;
    if (document.cookie) {
      const array = document.cookie.split(escape(name) + "=");
      if (array.length >= 2) {
        const arraySub = array[1].split(";");
        value = unescape(arraySub[0]);
      }
    }
    return value;
  }

  getUrlParams(): any {
    const params = {};
    const items = location.search.substr(1).split("&");
    items.forEach((item) => {
      const temp = item.split("=");
      params[temp[0]] = temp[1];
    });
    return params;
  }

  deleteCookie(name: string) {
    const value = this.getCookie(name);
    if (value) {
      this.setCookie(name, value, new Date(1));
    }
  }

  setCookie(
    name: string,
    value: string,
    expire: Date,
    path?: string,
    domain?: string,
    secure?: string
  ) {
    let cookieText = escape(name) + "=" + escape(value);
    cookieText += expire ? "; EXPIRES=" + expire.toUTCString() : "";
    cookieText += path ? "; PATH=" + path : "";
    cookieText += domain ? "; DOMAIN=" + domain : "";
    cookieText += secure ? "; SECURE" : "";
    document.cookie = cookieText;
  }
}
