





































import mixins from "vue-typed-mixins";
import MixinsComponent from "@/mixins/component";
import core from "@/core";
import { TableOrder } from "@/types";
import CodeService from "@/services/code/code.service";
import DiseaseService from "@/services/disease/disease.service";

export default mixins(MixinsComponent).extend({
  name: "DiseaseManagerInfoComponent",
  data: () => ({
    ready: false,
    loading: false,
    headers: [
      {
        text: "번호",
        align: "start",
        sortable: true,
        value: "id",
        width: "90px",
      },
      {
        text: "작물",
        value: "cropCode",
        width: "90px",
      },
      {
        text: "생성시간",
        value: "createdAt",
        width: "110px",
      },
      {
        text: "수정시간",
        value: "updatedAt",
        width: "110px",
      },
      {
        text: "농장주명",
        value: "farmerName",
        width: "110px",
        sortable: false,
      },
      {
        text: "주소",
        width: "150px",
        value: "address",
        sortable: false,
      },
      {
        text: "병해명",
        value: "diseaseName",
        width: "140px",
      },
      {
        text: "병해단계",
        value: "diseaseStep",
        width: "100px",
      },
      {
        text: "작물상 위치",
        value: "cropPosition",
        width: "120px",
      },
      {
        text: "온실내 위치",
        value: "housePosition",
        width: "120px",
      },
      {
        text: "측정날짜",
        value: "date",
        width: "100px",
      },
      {
        text: "측정온도",
        value: "temp",
        width: "100px",
      },
      {
        text: "측정습도",
        value: "humidity",
        width: "100px",
      },
    ],
    dataList: [] as any,
    options: {} as any,
    totalDataLength: 0,
    footerProps: {
      itemsPerPageOptions: [10, 20, 50, 100],
      disablePagination: false,
      disableItemsPerPage: false,
    },
    cropCodeList: [] as any,
  }),
  watch: {
    options: {
      handler() {
        if (this.ready && !this.loading) {
          this.getDataList();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.$nextTick(async () => {
      this.cropCodeList = await CodeService.getCropCodeList();

      await this.getDataList();

      this.ready = true;
    });
  },

  methods: {
    renderAddress(item) {
      if (item.address1 != null) {
        if (item.address2 != null) {
          return `${item.address1} ${item.address2}`;
        }
        return item.address1;
      }
      return "";
    },
    renderCropCode(item) {
      let cropName = "";
      this.cropCodeList.some((cropCode) => {
        if (item.cropCode === cropCode.code) {
          cropName = cropCode.koName;
          return true;
        }
      });
      return cropName;
    },
    renderDiseaseStep(item) {
      const value = item.diseaseStep;
      if (value === "1") return "초기";
      else if (value === "2") return "중기";
      else if (value === "3") return "말기";
      return "";
    },
    renderCropPosition(item) {
      const value = item.cropPosition;
      if (value === "1") return "꽃";
      else if (value === "2") return "잎";
      else if (value === "3") return "과실";
      else if (value === "4") return "뿌리";
      return "";
    },
    moveViewPage(item) {
      console.log("item : ", item);
      this.$router.push("/disease-manager/info/" + item.id);
    },
    addItem() {
      this.$router.push("/disease-manager/info/add");
    },
    editItem(item) {
      this.$router.push("/disease-manager/info/edit?id=" + item.id);
    },
    async deleteItem(item) {
      const result = await core.alert.show({
        title: "확인",
        body: `정보를 삭제하시겠습니까?`,
        showCancelButton: true,
      });
      if (result === "confirm") {
        try {
          // await OpenDataService.delete(item.id);
          await this.getDataList();
        } catch (e) {
          console.log(e);
        }
      }
    },
    async getDataList() {
      this.loading = true;

      const options = this.options;
      //console.log("options : ", options);
      const params = {
        draw: 0,
        start: (options.page - 1) * options.itemsPerPage,
        length: options.itemsPerPage,
        orderColumnName: "id",
        order: TableOrder.DESC,
      };
      const response = (await DiseaseService.getInfoTableList(params)) as any;
      // const response = (await OpenDataService.getTable(params)) as any;
      this.dataList = response.data;
      this.totalDataLength = response.recordsTotal;
      // this.dataList = [{ id: 1, title: "토마토 병해충 자료", createdAt: "2021-10-28 00:00:00" }];
      // this.totalDataLength = this.dataList.length;

      this.loading = false;
    },
  },
});
