import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { AppState, RootState } from "@/types";
import { RouterBeforeTask } from "@/plugins/vue-page-stack/router-before-task";
import Constant from "@/store/constant";
import { UserCompanyModel } from "@/models/company/user-company.model";
import store from "@/store";

const state: AppState = {
  name: Constant.appName,
  fromRoute: undefined,
  size: { width: 0, height: 0 },
  routerBeforeTask: new RouterBeforeTask(),
  modal: {
    alert: {
      visible: false,
      title: "알림",
      body: "내용",
      showCancelButton: false,
      cancelButtonText: "취소",
      confirmButtonText: "확인",
      promise: {
        resolve: null,
        reject: null,
      },
      allowBackCloseEvent: true,
    },
  },
  printClass: null,
  company: null,
  isApp: false,
  isMobile: false,
};

export const getters: GetterTree<AppState, RootState> = {};

export const actions: ActionTree<AppState, RootState> = {
  addPrint({ commit }, deviceType: string) {
    if (state.printClass == null) {
      document.documentElement.classList.add("print");
      document.documentElement.classList.add(deviceType);
      state.printClass = deviceType;
    }
  },
  clearPrint({ commit }) {
    if (state.printClass) {
      document.documentElement.classList.remove("print");
      document.documentElement.classList.remove("mobile");
      document.documentElement.classList.remove("pc");
      state.printClass = null;
    }
  },
  async updateCompany({ commit }, company: UserCompanyModel) {
    //console.log("update company : ", company);
    // const items = await store.dispatch("storage/getStorageItem", "selected-user-company");
    await store.dispatch("storage/setStorageItem", {
      type: "selected-user-company",
      items: company,
    });
    mutations.userCompany(state, company);
  },
};

const mutations: MutationTree<AppState> = {
  async userCompany(theState, company: UserCompanyModel) {
    theState.company = company;
    //console.log("change company : ", company);
  },
};

export const app: Module<AppState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
