





























import mixins from "vue-typed-mixins";
import MixinsModal from "@/mixins/modal";
import SelectFileComponent from "@/components/core/SelectFileComponent.vue";
import UpdateEvent from "@/models";
import { UpdateEventType } from "@/types";

export default mixins(MixinsModal).extend({
  name: "select-file-modal",
  components: { SelectFileComponent },
  data: () => ({
    title: "파일 선택",
    selectedFileList: [] as any,
    component: {
      selectFile: {
        updateEvent: null as UpdateEvent | null,
      },
    },
  }),
  mounted() {
    // 페이지 로딩 후 호출
    this.$nextTick(async () => {});
  },
  watch: {
    "component.selectFile.updateEvent"(event) {
      //console.log("event : ", event);
      if (event != null) {
        this.selectedFileList = event.item;
      }
    },
  },
  methods: {
    confirm() {
      this.close(UpdateEventType.CONFIRM, this.selectedFileList);
    },
  },
});
