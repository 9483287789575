import UserService from "@/services/user/user.service";
import core from "@/core";
import { AuthState, RootState } from "@/types";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import store from "@/store";
import { UserModel } from "@/models/user/user.model";
import OwnCloudService from "@/services/owncloud/owncloud.service";

const state: AuthState = {
  user: null,
  sessionCheckTime: null,
};

export const getters: GetterTree<AuthState, RootState> = {
  user: (theState: AuthState) => {
    return async (reload: boolean | null): Promise<UserModel | null> => {
      if (reload == null) reload = false;

      const curTime = new Date();
      if (theState.sessionCheckTime == null) {
        theState.sessionCheckTime = new Date(0);
      }

      if (!reload) {
        //console.log("call getUser");
        const elapsedTimeMillis = curTime.getTime() - theState.sessionCheckTime.getTime();
        //console.log(elapsedTimeMillis);
        if (elapsedTimeMillis < 60000) return theState.user;
      }

      try {
        theState.sessionCheckTime = curTime;
        const user = await UserService.getMyInfo();
        mutations["login"](theState, user);
        try {
          const _selectedCompany = await store.dispatch(
            "storage/getStorageItem",
            "selected-user-company"
          );
          //console.log("selectedCompany : ", _selectedCompany);
          if (_selectedCompany != null) {
            if (user.companyList != null && user.companyList.length > 0) {
              let selectedCompany = null as any;
              user.companyList.some((company) => {
                if (_selectedCompany.id == company.id) {
                  selectedCompany = company;
                  return true;
                }
              });
              if (selectedCompany != null) {
                store.dispatch("app/updateCompany", selectedCompany);
              } else {
                store.dispatch("app/updateCompany", user.companyList[0]);
              }
            }
          }
        } catch (e) {
          console.log(e);
        }
        return user;

        // if (user.roles.indexOf("MANAGER") > -1) {
        //
        // } else {
        //   console.log("is not support role : ", user.roles);
        //   await UserService.logout();
        // }
      } catch (reason) {
        if (reason === 401) {
          // await store.dispatch("auth/logout");
        }
      }
      return null;
    };
  },
};

export const actions: ActionTree<AuthState, RootState> = {
  async login({ commit }, params) {
    const result = await UserService.login(params);
    state.sessionCheckTime = new Date(0);

    const user = await store.getters["auth/user"](true);
    //console.log("user : ", user);
    if (user != null) {
      return user;
    }
    await core.alert.show({
      title: "알림",
      body: "아이디 또는 비밀번호가 올바르지 않습니다(1)",
      confirmButtonText: "확인",
    });
  },

  async logout({ commit }) {
    if (state.user != null) {
      try {
        return await UserService.logout();
      } catch (e) {
        console.log(e);
      } finally {
        commit("logout");
      }
    }
  },

  async register({ commit }, params) {
    return await UserService.register(params);
  },
};

const mutations: MutationTree<AuthState> = {
  async login(theState, user: UserModel) {
    // console.log("mutations[login]: ", theState, user);
    console.log("update user info");
    const preUser = state.user;
    theState.sessionCheckTime = new Date();
    theState.user = user;
    OwnCloudService.owncloudLogout();
  },
  logout(theState) {
    //console.log('mutations[logout]: ', state.user);
    if (theState.user != null) {
      console.log("logout!");
      theState.sessionCheckTime = new Date();
      theState.user = null;
    }
    OwnCloudService.owncloudLogout();
  },
};

export const auth: Module<AuthState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
