import core from "@/core";

abstract class AbstractCoreService {
  protected readonly url: string;
  protected readonly itemKey: string;

  protected constructor(url: string, itemKey: string) {
    this.url = url;
    this.itemKey = itemKey;
  }

  get(id) {
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`${this.url}/${id}`)
        .then((data) => {
          if (typeof data === "object") {
            const item = data[this.itemKey];
            if (item != null) {
              resolve(item);
            } else {
              reject({
                message: "not found item",
              });
            }
          } else {
            reject({
              message: "data type error",
            });
          }
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  getList() {
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`${this.url}`)
        .then((data) => {
          if (typeof data === "object") {
            const item = data[this.itemKey + "List"];
            if (item != null) {
              resolve(item);
            } else {
              reject({
                message: "not found item",
              });
            }
          } else {
            reject({
              message: "data type error",
            });
          }
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  create(params) {
    const strParams = JSON.stringify(params);
    return new Promise((resolve, reject) => {
      core.http
        .postJson(`${this.url}`, strParams)
        .then((data) => {
          if (typeof data === "object") {
            const item = data[this.itemKey];
            if (item != null) {
              resolve(item);
            } else {
              reject({
                message: "not found item",
              });
            }
          } else {
            reject({
              message: "data type error",
            });
          }
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  update(id, params) {
    return new Promise((resolve, reject) => {
      const strJson = JSON.stringify(params);
      core.http
        .putJson(`${this.url}/${id}`, strJson)
        .then((data) => {
          if (typeof data === "object") {
            const item = data[this.itemKey];
            if (item != null) {
              resolve(item);
            } else {
              reject({
                message: "not found item",
              });
            }
          } else {
            reject({
              message: "data type error",
            });
          }
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  delete(id) {
    return new Promise((resolve, reject) => {
      core.http
        .delete(`${this.url}/${id}`)
        .then((data) => {
          resolve(data);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }
}

export default AbstractCoreService;
