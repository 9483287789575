








































































































































































import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import { localize } from "vee-validate";
import Constant from "@/store/constant";
import core from "@/core";
import _ from "lodash";

localize("ko", {
  messages: Constant.validate.language.korea.messages,
  names: {
    username: "아이디 ",
    password: "비밀번호 ",
    confirmPassword: "비밀번호 확인 ",
    email: "이메일 ",
    name: "이름 ",
    phone: "휴대전화 ",
    certificationNumber: "인증번호",
  },
});

export default mixins(MixinsPageForm).extend({
  name: "Register",
  data: () => ({
    processing: false,
    form: {
      username: "",
      password: "",
      confirmPassword: "",
      email: "",
      name: "",
      phone: "",
    },
  }),
  watch: {
    "form.phone"() {
      this.form.phone = core.utils.format.hyphenPhone(this.form.phone);
    },
  },
  mounted() {},
  methods: {
    async submit() {
      if (await this.validate()) {
        this.processing = true;

        try {
          const params = _.cloneDeep(this.form) as any;
          if (this.isBlank(params.email)) {
            delete params.email;
          }

          await this.$store.dispatch("auth/register", params);

          const alertResult = await core.alert.show({
            title: "알림",
            body: "회원가입이 완료되었습니다.",
            confirmButtonText: "확인",
          });
          this.$router.push("/login");
        } catch (e) {
          console.log("error : ", e);
          this.errorSubmit(e);
        }
        this.processing = false;
      }
    },
  },
});
