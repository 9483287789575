














































import mixins from "vue-typed-mixins";
import MixinsComponent from "@/mixins/component";
import GreenHouseStatusComponent from "@/components/digital-agriculture/green-house/StatusComponent.vue";
import GreenHouseTotalStatusComponent from "@/components/digital-agriculture/green-house/TotalStatusComponent.vue";
import { TableOrder } from "@/types";
import GreenHouseService from "@/services/greenhouse/green-house.service";
import TranspirationCalculator from "@/core/transpiration-calculator";

export default mixins(MixinsComponent).extend({
  name: "GreenhouseComponent",
  props: {
    menu: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  components: { GreenHouseTotalStatusComponent, GreenHouseStatusComponent },
  data: () => ({
    title: "",
    iframeSrc: null as any,
    subPath: "",
    component: {
      greenhouse: {
        itemList: [
          {
            text: "전체",
            color: "blue",
            dark: true,
            active: false,
            devId: 0,
            data: null as any,
          },
          {
            text: "온실1",
            color: "blue",
            dark: true,
            active: false,
            devId: 1,
            data: null as any,
          },
          {
            text: "온실2",
            color: "blue",
            dark: true,
            active: false,
            devId: 2,
            data: null as any,
          },
          {
            text: "온실3",
            color: "blue",
            dark: true,
            active: false,
            devId: 3,
            data: null as any,
          },
          {
            text: "온실4",
            color: "blue",
            dark: true,
            active: false,
            devId: 4,
            data: null as any,
          },
          {
            text: "온실5",
            color: "blue",
            dark: true,
            active: false,
            devId: 5,
            data: null as any,
          },
        ],
        selectedButtonItem: null as any,
        params: {
          devId: 0,
          updated: false,
        },
      },
    },
    isDestroyed: false,
    timeoutMillis: 10000,
  }),
  beforeDestroy() {
    this.isDestroyed = true;
  },
  mounted() {
    const subPath = (this.subPath = this.properties.params.subPath);
    //console.log("subPath : ", this.subPath);
    this.$nextTick(async () => {
      if (subPath === "monitoring") {
        this.iframeSrc = "/proxy/monitoring/";
        this.title = "첨단스마트온실Ⅰ - 온습도 균일화 모니터링";
      } else if (subPath === "status") {
        // this.iframeSrc = "http://222.105.187.66:25080";
        this.changeGreenHouse1(this.component.greenhouse.itemList[0]);
        this.title = "첨단스마트온실Ⅰ - 온실환경 모니터링";
        this.loadGreenHouseData();
        await this.getGreenHouseDataList();
        // }
      } else {
        console.log("unknown subpath : ", subPath);
      }
    });
  },
  methods: {
    showPage() {
      if (this.subPath === "monitoring") {
        window.open("http://222.105.187.66:25181", "_blank");
      } else if (this.subPath.indexOf("status") > -1) {
        window.open("http://222.105.187.66:25080", "_blank");
      }
    },
    toggleMenu() {
      this.$emit("update:menu", !this.menu);
    },
    changeGreenHouse1(item) {
      const component = this.component.greenhouse;
      const itemList = component.itemList;
      itemList.forEach((greenHouse) => {
        greenHouse.active = false;
      });
      item.active = true;
      component.selectedButtonItem = item;
      component.params.devId = item.devId;
    },
    loadGreenHouseData() {
      if (!this.isDestroyed) {
        setTimeout(async () => {
          if (!this.isDestroyed) {
            await this.getGreenHouseDataList();
            this.loadGreenHouseData();
          } else {
            console.log("destroyed");
          }
        }, this.timeoutMillis);
      }
    },
    async getGreenHouseDataList() {
      console.log("getGreenHouse dataList");

      const component = this.component.greenhouse;

      let updated = false;
      for (let i = 1; i <= 5; i++) {
        const item = component.itemList[i];
        const params = {
          draw: 0,
          start: 0,
          length: 1,
          orderColumnName: "createdAt",
          order: TableOrder.DESC,
          searchColumns: {
            devId: i,
          },
        } as any;

        if (item.data != null) {
          params.searchColumns.idGreaterThan = item.data.id;
        }
        const result = (await GreenHouseService.getTable(params)) as any;
        const data = result.data.length > 0 ? result.data[0] : null;

        if (data != null) {
          const displayData = data.display;

          displayData.name = "온실" + i;

          const tc = TranspirationCalculator.newInstance({
            dryBulbTemp: displayData.inTemp,
            relativeHumidity: displayData.inHumidity,
          });
          const recommend = tc.recommend;
          if (
            displayData.inTemp == recommend.temp &&
            displayData.inHumidity == recommend.humidity
          ) {
            displayData.color = "blue";
            displayData.colorClass = "blue--text";
          } else {
            if (displayData.inTemp < recommend.temp) {
              // 노랑
              displayData.color = "orange";
              displayData.colorClass = "orange--text";
            } else if (displayData.inTemp > recommend.temp) {
              // 빨강
              displayData.color = "red";
              displayData.colorClass = "red--text";
            }

            if (displayData.inHumidity < recommend.humidity) {
              // 빨강
              displayData.color = "red";
              displayData.colorClass = "red--text";
            } else if (displayData.inHumidity > recommend.humidity) {
              // 노랑
              displayData.color = "orange";
              displayData.colorClass = "orange--text";
            }
          }

          item.color = displayData.color;
          item.data = data;
          updated = true;
        }
      }

      if (updated) {
        component.params.updated = true;
      }
    },
  },
});
