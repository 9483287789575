


































import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import core from "@/core";
import OpenDataService from "@/services/opendata/open-data.service";
import { TableOrder } from "@/types";

export default mixins(MixinsPageForm).extend({
  name: "DataManagement",
  components: {},
  data: () => ({
    ready: false,
    loading: false,
    headers: [
      {
        text: "번호",
        align: "start",
        sortable: true,
        value: "id",
        width: "10%",
      },
      {
        text: "제목",
        value: "title",
        sortable: true,
        width: "60%",
      },
      {
        text: "생성시간",
        value: "createdAt",
        sortable: true,
        width: "20%",
      },
    ],
    dataList: [] as any,
    options: {} as any,
    totalDataLength: 0,
    footerProps: {
      itemsPerPageOptions: [10, 20, 50, 100],
      disablePagination: false,
      disableItemsPerPage: false,
    },
    visible: {
      addButton: false,
    },
  }),
  watch: {
    options: {
      handler() {
        if (this.ready && !this.loading) {
          this.getDataList();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.$nextTick(async () => {
      const user = await this.$store.getters["auth/user"]();
      if (user != null && user.roles != null) {
        let hasManagerRole = false;
        user.roles.forEach((role) => {
          if (role === "MANAGER" || role === "ADMIN") {
            hasManagerRole = true;
          }
        });

        if (hasManagerRole) {
          this.visible.addButton = true;
          this.headers.push({
            text: "Actions",
            value: "actions",
            sortable: false,
            width: "10%",
          });
        }
      }

      this.ready = true;

      await this.getDataList();
    });
  },

  methods: {
    addItem() {
      this.$router.push("/data-management/add");
    },
    editItem(item) {
      this.$router.push("/data-management/edit?id=" + item.id);
    },
    async deleteItem(item) {
      const result = await core.alert.show({
        title: "확인",
        body: `정보를 삭제하시겠습니까?`,
        showCancelButton: true,
      });
      if (result === "confirm") {
        try {
          await OpenDataService.delete(item.id);
          await this.getDataList();
        } catch (e) {
          console.log(e);
        }
      }
    },
    async getDataList() {
      this.loading = true;

      const options = this.options;
      //console.log("options : ", options);
      const params = {
        draw: 0,
        start: (options.page - 1) * options.itemsPerPage,
        length: options.itemsPerPage,
        orderColumnName: "createdAt",
        order: TableOrder.DESC,
        searchColumns: {} as any,
      };
      const searchColumns = params.searchColumns;
      const response = (await OpenDataService.getTable(params)) as any;
      this.dataList = response.data;
      this.totalDataLength = response.recordsTotal;
      // this.dataList = [{ id: 1, title: "토마토 병해충 자료", createdAt: "2021-10-28 00:00:00" }];
      // this.totalDataLength = this.dataList.length;

      this.loading = false;
    },
  },
});
