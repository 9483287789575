































































import store from "@/store";
import core from "@/core";
import UserService from "@/services/user/user.service";

import Constant from "@/store/constant";
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import { localize } from "vee-validate";

localize("ko", {
  messages: Constant.validate.language.korea.messages,
  names: {
    username: "아이디 ",
    password: "비밀번호 ",
  },
});

export default mixins(MixinsPageForm).extend({
  name: "UserPassword",
  components: {},
  data: () => ({
    app: store.state.app,
    processing: false,
    form: {
      currentPassword: "",
      newPassword: "",
      newConfirmPassword: "",
    },
  }),
  mounted() {
    this.$nextTick(() => {});
  },
  methods: {
    async submit() {
      if (await this.validate()) {
        this.processing = true;
        try {
          const form = this.form;
          await UserService.updatePassword(
            form.currentPassword,
            form.newPassword,
            form.newConfirmPassword
          );

          await core.alert.show({
            title: "알림",
            body: "비밀번호가 변경되었습니다.",
          });
          await this.$router.back();
        } catch (e) {
          this.errorSubmit(e);
        }
        this.processing = false;
      }
    },
  },
});
