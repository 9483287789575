
























































































import mixins from "vue-typed-mixins";
import MixinsModalForm from "@/mixins/modal-form";
import { localize } from "vee-validate";
import Constant from "@/store/constant";
import { UpdateEventType } from "@/types";
import core from "@/core";
import GrowthService from "@/services/growth/growth.service";

localize("ko", {
  messages: Constant.validate.language.korea.messages,
  names: {
    facility: "시설",
    inFacility: "내부시설",

    excelFile: "엑셀파일",

    // sampleNumber: "샘플번호",
    // plantLength: "초장(cm)",
    // stemDiameter: "경경(mm)",
    // leafLength: "엽장(cm)",
    // leafWidth: "엽폭(cm)",
    // flowerCluster: "화방(번째)",
    // distanceGrowingFlower: "생장점과 화방간 거리",
  },
});

export default mixins(MixinsModalForm).extend({
  name: "GrowthUploadExcelModal",
  data: () => ({
    title: "엑셀 업로드",
    type: "",
    processing: false,
    form: {
      facilityId: "",
      inFacilityId: "",
      excelFile: null as any,
      // 샘플번호
      sampleNumber: "",
      // 초장(cm)
      plantLength: "",
      // 경경(mm)
      stemDiameter: "",
      // 엽장(cm)
      leafLength: "",
      // 엽폭(cm)
      leafWidth: "",
      // 화방(번째)
      flowerCluster: "",
      // 생장점과 화방간 거리
      distanceGrowingFlower: "",
    },
    facilityList: [] as any,
    selectedFacility: null as any,
  }),
  mounted() {
    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      this.type = this.properties.params.type;
      this.facilityList = this.properties.params.facilityList;
      // console.log("facilityList : ", this.facilityList);
    });
  },
  watch: {
    "form.facilityId"(facilityId) {
      var selectedFacility = null as any;
      if (this.isNotBlank(facilityId)) {
        //console.log("facilityId : ", facilityId);
        this.facilityList.some((facility) => {
          if (facility.id === facilityId) {
            selectedFacility = facility;
            return true;
          }
        });
        if (selectedFacility != null) {
          if (selectedFacility.inFacilityList != null) {
            selectedFacility.inFacilityList.forEach((inFacility) => {
              inFacility.text = `${inFacility.dongNo}동`;
            });
          }
        }
        // console.log("selectedFacility : ", selectedFacility);
      }
      this.selectedFacility = selectedFacility;
    },
  },
  methods: {
    async downloadSampleExcelFile() {
      try {
        await core.http.downloadFile("/dn/excel-form/생육조사표.xlsx");
      } catch (e) {
        console.log(e);
      }
    },
    async submit() {
      if (await this.validate()) {
        this.processing = true;

        try {
          core.loader.show();
          const formData = new FormData();
          formData.append("facilityId", this.form.facilityId);
          formData.append("inFacilityId", this.form.inFacilityId);
          formData.append("fileObj", this.form.excelFile);

          const result = (await GrowthService.uploadExcel(formData)) as any;
          console.log("result : ", result);
          if (result.errorMsgList != null && result.errorMsgList.length > 0) {
            let errorMsgText = "";
            result.errorMsgList.forEach((errorMsg: string) => {
              if (errorMsgText.length > 0) errorMsgText += "<br>";
              errorMsgText += errorMsg;
            });
            await core.alert.show({
              title: "알림",
              body: errorMsgText,
            });
          }
          this.close(UpdateEventType.CONFIRM);
        } catch (e) {
          console.log("error : ", e);
        }
        core.loader.hide();
        this.processing = false;
      }
    },
  },
});
