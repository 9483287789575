




















import mixins from "vue-typed-mixins";
import MixinsComponent from "@/mixins/component";
import DiseaseImageAnnotationComponent from "@/components/disease/analysis/DiseaseImageAnnotationComponent.vue";
import DiseaseAreaGalleryComponent from "@/components/disease/analysis/DiseaseAreaGalleryComponent.vue";
import DiseaseAreaCountMapComponent from "@/components/disease/analysis/DiseaseAreaCountMapComponent.vue";
import DiseaseImageComponent from "@/components/disease/analysis/DiseaseImageComponent.vue";
import SearchAddressModal from "@/modals/core/SearchAddressModal.vue";
import SearchAddressComponent from "@/components/address/SearchAddressComponent.vue";
import UpdateEvent from "@/models";
import core from "@/core";

export default mixins(MixinsComponent).extend({
  name: "DiseaseAnalysisComponent",
  components: {
    DiseaseImageAnnotationComponent,
    DiseaseAreaGalleryComponent,
    DiseaseAreaCountMapComponent,
    DiseaseImageComponent,
    SearchAddressModal,
    SearchAddressComponent,
  },
  props: {
    properties: {
      type: Object,
      default: () => {
        return {
          cropCode: "",
          cropName: "",
          updateEvent: null as UpdateEvent | null,
        };
      },
    },
  },
  data: () => ({
    ready: false,
    component: {
      disease: {
        cropCode: null as any,
        engine: {
          visible: false,
          versionList: [] as any,
        },
      },
      area: {
        cropCode: null as any,
        updateEvent: null as UpdateEvent | null,
      },
      gallery: {
        areaText: "전국",
        searchColumns: { status: "DISEASE" } as any,
      },
    },
  }),
  mounted() {
    this.$nextTick(async () => {
      console.log(this.properties);
      if (core.utils.validate.isNotBlank(this.properties.cropCode)) {
        this.changeCropCode(this.properties.cropCode, this.properties.cropName);
      }
      //console.log("cropCode : ", this.properties.cropCode);
    });
  },
  watch: {
    "properties.cropCode"(cropCode) {
      //console.log("cropCode : ", this.properties.cropCode);
      this.changeCropCode(this.properties.cropCode, this.properties.cropName);
    },
    "component.area.updateEvent"(event) {
      if (event != null) {
        const item = event.item;
        //console.log("event : ", event);
        this.component.gallery.searchColumns = {
          sidoCode: item.sidoCode,
          sggCode: item.sggCode,
          cropCode: item.cropCode,
          status: "DISEASE",
        };
        // 지역 텍스트 추가
        this.component.gallery.areaText = `${item.sidoName} ${item.sggName}`;
      }
    },
  },
  methods: {
    async changeCropCode(cropCode, cropName) {
      this.component.disease.cropCode = cropCode;
      if (cropName === "strawberry") {
        this.component.disease.engine = {
          visible: true,
          versionList: [
            { id: "v1", text: "V1" },
            { id: "v2", text: "V2" },
          ],
        };
      } else {
        this.component.disease.engine = {
          visible: false,
          versionList: [],
        };
      }
      this.component.area.cropCode = cropCode;

      this.component.gallery.searchColumns = {
        cropCode: this.component.area.cropCode,
        status: "DISEASE",
      };
      this.component.gallery.areaText = "전국";

      if (!this.ready) {
        this.ready = true;
      }
    },
  },
});
