










































































import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import { UserModel } from "@/models/user/user.model";

export default mixins(MixinsPageForm).extend({
  name: "Sitemap",
  components: {},
  data: () => ({
    isFarmerRole: false,
    isManagerRole: false,
    isAdminRole: false,
  }),
  mounted() {
    this.$nextTick(() => {
      const user: UserModel | null = this.$store.state.auth.user;
      if (user == null) {
        return false;
      }

      this.isAdminRole = user.roles.indexOf("ADMIN") > -1;
      if (this.isAdminRole) {
        this.isManagerRole = true;
        this.isFarmerRole = true;
      } else {
        this.isManagerRole = user.roles.indexOf("MANAGER") > -1;
        if (this.isManagerRole) {
          this.isFarmerRole = true;
        } else {
          this.isFarmerRole = user.roles.indexOf("FARMER") > -1;
        }
      }
    });
  },
  methods: {},
});
