






















import mixins from "vue-typed-mixins";
import MixinsComponent from "@/mixins/component";

export default mixins(MixinsComponent).extend({
  name: "Smartfarm2ndComponent",
  props: {
    menu: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  data: () => ({
    iframeSrc: null as any,
  }),
  mounted() {
    this.$nextTick(async () => {
      const user = await this.$store.getters["auth/user"]();
      if (user != null && user.roles != null) {
        let hasFarmerRole = false;
        user.roles.forEach((role) => {
          if (role === "FARMER") {
            hasFarmerRole = true;
          }
        });

        if (hasFarmerRole) {
          this.iframeSrc = "/proxy/smartfarm2nd/";
        }
      }
    });
  },
  methods: {
    showPage() {
      window.open("http://222.105.187.66:50050", "_blank");
    },
    toggleMenu() {
      this.$emit("update:menu", !this.menu);
    },
  },
});
