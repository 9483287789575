






















import mixins from "vue-typed-mixins";
import MixinsComponent from "@/mixins/component";

export default mixins(MixinsComponent).extend({
  name: "AquaComponent",
  props: {
    menu: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  data: () => ({
    iframeSrc: "http://222.105.187.66:55011",
  }),
  mounted() {
    if (window.location.protocol.indexOf("https") > -1) {
      this.iframeSrc = "https://aqua.smartfarm.scc20.co.kr";
    }
  },
  methods: {
    showPage() {
      window.open("http://222.105.187.66:55011", "_blank");
    },
    toggleMenu() {
      this.$emit("update:menu", !this.menu);
    },
  },
});
