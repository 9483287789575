












import CoreKakaoMap from "@/core/core-kakao-map";
import DiseaseService from "@/services/disease/disease.service";
import mixins from "vue-typed-mixins";
import core from "@/core";
import Constant from "@/store/constant";
import { UpdateEventType } from "@/types";
import UpdateEvent from "@/models";

declare let kakao: any;

export default mixins().extend({
  name: "DiseaseAreaCountMapComponent",
  props: {
    properties: {
      type: Object,
      default: () => {
        return {
          cropCode: null as any,
          analysisStatus: null as any,
          updateEvent: null as UpdateEvent | null,
        };
      },
    },
  },
  data() {
    return {
      markerList: null as [] | null,
      kakaoMap: null as CoreKakaoMap | null,
      map: {
        default: {
          address: "선택된 위치가 없습니다",
          latitude: 37.5653888,
          longitude: 126.9783866,
          level: 12,
        },
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      const defaultLocation = this.map.default;
      const location = {
        address1: defaultLocation.address,
        latitude: defaultLocation.latitude,
        longitude: defaultLocation.longitude,
        level: defaultLocation.level,
      };
      // console.log("cropCode : ", this.properties.cropCode);
      this.initMap(location);
      if (this.properties.cropCode != null) {
        this.redrawMarkers(this.properties.cropCode);
      }
    });
  },
  watch: {
    "properties.cropCode"(val) {
      //console.log("cropCode : ", val);
      this.redrawMarkers(val);
    },
  },
  methods: {
    async redrawMarkers(cropCode) {
      if (this.markerList != null) {
        this.markerList.forEach((marker) => {
          this.kakaoMap?.clearMarker(marker);
        });
      }

      let params = null as any;
      if (this.properties.analysisStatus) {
        params = { analysisStatus: this.properties.analysisStatus };
      }
      const areaList = (await DiseaseService.getAreaList(cropCode, params)) as any;
      //console.log("areaList : ", areaList);
      const markerList = [] as any;
      const bounds = new kakao.maps.LatLngBounds();
      areaList.forEach((area) => {
        const text = `${area.sidoName} ${area.sggName} <span style="font-size: 120%; font-weight: bold; color: red">${area.count}</span>건`;
        const properties = this.properties;
        const kakaoMap = this.kakaoMap;
        const marker = kakaoMap?.addMarkerAndOverlay(area.latitude, area.longitude, text, () => {
          properties.updateEvent = new UpdateEvent(UpdateEventType.UPDATE, "", {
            sidoCode: area.sidoCode,
            sggCode: area.sggCode,
            sidoName: area.sidoName,
            sggName: area.sggName,
            cropCode: area.cropCode,
          });

          if (marker.overlay != null) {
            marker.overlay.setMap(null);
          }
          marker.setMap(null);

          marker.setMap(kakaoMap.map);
          marker.overlay.setMap(kakaoMap.map);

          //console.log("click marker : ", properties.callback);
        });
        markerList.push(marker);
        bounds.extend(marker.getPosition());
      });
      this.markerList = markerList;

      if (areaList.length > 0) {
        this.kakaoMap?.map.setBounds(bounds, 100, 50, 0, 50);
      } else {
        const defaultMap = this.map.default;
        this.kakaoMap?.setCenterAndLevel(
          defaultMap.latitude,
          defaultMap.longitude,
          defaultMap.level
        );
      }
    },
    initMap(params) {
      //console.log("init map");
      const mapData = this.map;

      const mapElement = this.$refs.map;

      const kakaoMap = (this.kakaoMap = new CoreKakaoMap(
        {
          map: {
            element: mapElement,
            option: {
              center: new kakao.maps.LatLng(params.latitude, params.longitude),
              level: params.level,
            },
          },
          callback: {},
        },
        core,
        Constant.kakao.restApiKey
      ));
    },
  },
});
