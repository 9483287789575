


















import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";

export default mixins(MixinsPageForm).extend({
  name: "DiseaseManagerMenuComponent",
  data: () => ({
    items: [
      {
        id: "tomato",
        text: "토마토",
        icon: "flaticon-tomato-o flaticon-fw",
        path: "/disease-manager/tomato",
      },
      {
        id: "strawberry",
        text: "딸기",
        icon: "flaticon-strawberry-o flaticon-fw",
        path: "/disease-manager/strawberry",
      },
      {
        id: "paprika",
        text: "파프리카",
        icon: "flaticon-capsicum-o flaticon-fw",
        path: "/disease-manager/paprika",
      },
      {
        divider: true,
      },
      {
        id: "info",
        text: "병해충 정보",
        icon: "mdi-information-outline",
        path: "/disease-manager/info",
      },
    ],
  }),
  mounted() {
    this.$nextTick(async () => {});
  },
  methods: {},
});
