import {
  getIndexByKey,
  getPreStack,
  getPreStackInstance,
  getStack,
  VuePageStack,
} from "./components/VuePageStack";
import mixin from "./mixin";
import history from "./history";
import config from "./config";
import { RouterBeforeTask } from "./router-before-task";

function hasKey(query: any, keyName: string) {
  return !!query[keyName];
}

function getKey(src: string) {
  return src.replace(/[xy]/g, function (c: string) {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

class VuePageStackPlugin {
  install(
    Vue: any = null,
    {
      router = null as any,
      routerBeforeTask: routerBeforeTask = new RouterBeforeTask(),
      name = config.componentName,
      keyName = config.keyName,
    }
  ) {
    if (!router) {
      throw Error("\n vue-router is necessary. \n\n");
    }
    Vue.component("vue-page-stack", VuePageStack(keyName));

    Vue.prototype.$pageStack = {
      getStack,
      getPreStack,
      getPreStackInstance,
    };

    mixin(router);

    function beforeEach(to: any, from: any, next: any) {
      //console.log("beforeEach");
      //console.log(getStack());

      //console.log("keyName : ", keyName);
      if (!hasKey(to.query, keyName)) {
        to.query[keyName] = getKey("xxxxxxxx");
        const replace = history.action === config.replaceName || !hasKey(from.query, keyName);
        next({
          hash: to.hash,
          path: to.path,
          name: to.name,
          params: to.params,
          query: to.query,
          meta: to.meta,
          replace: replace,
        });
      } else {
        if (!routerBeforeTask.isRouterNext()) {
          //console.log("ignore router event");
          next(false);
          return;
        }

        const index = getIndexByKey(to.query[keyName]);
        //console.log("index : ", index);
        if (index === -1) {
          routerBeforeTask.clear();
          to.params[keyName + "-dir"] = config.forwardName;
        } else {
          to.params[keyName + "-dir"] = config.backName;
        }
        next({ params: to.params });
      }
    }

    // ensure it's the first beforeEach hook
    router.beforeHooks.unshift(beforeEach);
  }
}

export default new VuePageStackPlugin();
