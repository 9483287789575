import { RouterBeforeTask } from "@/plugins/vue-page-stack/router-before-task";
import { UserModel } from "@/models/user/user.model";

/**
 * interface
 */
// Store root state
export interface RootState {
  topToolbar: TopToolbarState;
  app: AppState;
  auth: AuthState;
  storage: StorageState;
}

// Store modules state
export interface TopToolbarState {
  title: string;
}

export interface Filter {
  type: string;
  filter: any;
}

export interface SessionItem {
  type: string;
  items: any;
}

export interface StorageItem {
  type: string;
  items: any;
}

export interface StorageState {
  filters: Map<string, any>;
  sessionItems: Map<string, any>;
  storageItems: Map<string, any>;
}

export interface AppState {
  name: string;
  fromRoute?: string;
  size: WindowSize;
  routerBeforeTask: RouterBeforeTask;
  modal: {
    alert: AlertModal;
  };
  printClass: null | string;
  company: any;
  isApp: boolean;
  isMobile: boolean;
}

export interface AuthState {
  user: UserModel | null;
  sessionCheckTime: Date | null;
}

export interface WindowSize {
  width: number;
  height: number;
}

export interface Comment {
  type: CommentType;
}

export interface AlertModal {
  visible: boolean;
  title: string;
  body: string;
  showCancelButton: boolean;
  cancelButtonText: string;
  confirmButtonText: string;
  promise: {
    resolve: any;
    reject: any;
  };
  allowBackCloseEvent: boolean;
}

export interface TableRequest {
  draw: number;
  start: number;
  length: number;
  orderColumnName: string;
  order: TableOrder;
  searchColumns?: any;
}

/**
 * enum
 */
export enum CommentType {
  IMAGE = "I",
  TEXT = "T",
  SYSTEM = "S",
  LINK = "L",
  ADMIN = "A",
}

export enum UpdateEventType {
  RELOAD = "reload",
  FILTER = "filter",
  DELETE = "delete",
  UPDATE = "update",
  CONFIRM = "confirm",
  CANCEL = "cancel",
  PAGE = "page",
  TABLE = "table",
}

export enum CompanyRole {
  OWNER = "OWNER",
  ADMIN = "ADMIN",
  MANAGER = "MANAGER",
  USER = "USER",
}

export enum CompanyDetailType {
  BANK_ACCOUNT = "BANK_ACCOUNT",
  ESTIMATE_CONTENT = "ESTIMATE_CONTENT",
}

export enum EntityType {
  SCHEDULE = "WORK_SCHEDULE",
  ESTIMATE = "WORK_ESTIMATE",
  ESTIMATE_SUPPORT = "WORK_ESTIMATE_SUPPORT",
  SUPPORT_AS = "SUPPORT_AS",
}

export enum ApprovalType {
  REQUEST = "REQUEST",
  ALLOW = "ALLOW",
  DENY = "DENY",
}

export enum TableOrder {
  ASC = "asc",
  DESC = "desc",
}
