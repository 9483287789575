import core from "@/core";

class DiseaseService {
  imageAnalysis(formData) {
    return new Promise((resolve, reject) => {
      core.http
        .post(`/api/disease/analysis`, formData, {
          headers: core.http.headers.form,
        })
        .then((data) => {
          if (data.analysis) {
            resolve(data.analysis);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  getInfoUrl(name, similarity) {
    if (similarity > 0.8) {
      switch (name) {
        case "잿빛곰팡이병":
          return "https://www.nongsaro.go.kr/portal/ps/pss/pssa/sicknsSearchDtl.ps?menuId=PS00202&sKidofcomdtyTabCode=VC&sKidofcomdtyCode=VC011205&hlsctCode=&sicknsCode=D00000207&nnmyInsectCode=";
        case "잎마름병":
          return "http://www.nongsaro.go.kr/portal/ps/pss/pssa/sicknsSearchDtl.ps?menuId=PS00202&sKidofcomdtyTabCode=VC&sKidofcomdtyCode=VC010803&hlsctCode=&sicknsCode=D00001546&nnmyInsectCode=";
        case "궤양병":
          return "http://www.nongsaro.go.kr/portal/ps/pss/pssa/sicknsSearchDtl.ps?menuId=PS00202&sKidofcomdtyTabCode=VC&sKidofcomdtyCode=VC010803&hlsctCode=&sicknsCode=D00004102&nnmyInsectCode=";
        case "역병":
          return "http://www.nongsaro.go.kr/portal/ps/pss/pssa/sicknsSearchDtl.ps?menuId=PS00202&sKidofcomdtyTabCode=VC&sKidofcomdtyCode=VC010803&hlsctCode=&sicknsCode=D00001535&nnmyInsectCode=";
        case "잎굴파리":
          return "http://www.nongsaro.go.kr/portal/ps/pss/pssa/hlsctSearchDtl.ps?menuId=PS00202&sKidofcomdtyTabCode=VC&sKidofcomdtyCode=VC010803&hlsctCode=H00000486&sicknsCode=&nnmyInsectCode=";
        case "흰가루병":
          return "https://www.nongsaro.go.kr/portal/ps/pss/pssa/sicknsSearchDtl.ps?menuId=PS00202&sKidofcomdtyTabCode=VC&sKidofcomdtyCode=VC011205&hlsctCode=&sicknsCode=D00000197&nnmyInsectCode=";
        case "온실가루이":
          return "http://www.nongsaro.go.kr/portal/ps/pss/pssa/hlsctSearchDtl.ps?menuId=PS00202&sKidofcomdtyTabCode=VC&sKidofcomdtyCode=VC010803&hlsctCode=H00000295&sicknsCode=&nnmyInsectCode=";
        case "점무늬병":
          return "https://www.nongsaro.go.kr/portal/ps/pss/pssa/sicknsSearchDtl.ps?menuId=PS00202&sKidofcomdtyTabCode=VC&sKidofcomdtyCode=VC011205&hlsctCode=&sicknsCode=D00000194&nnmyInsectCode=";
        case "점박이응애":
          return "https://ncpms.rda.go.kr/npms/HlsctIstguInfoDtlR.np?hlsctIstguNo=H00000713&totalSearchYn=Y";
        case "배꼽썩음과":
          return "https://www.nongsaro.go.kr/portal/ps/psb/psbl/workScheduleDtl.ps?menuId=PS00087&cntntsNo=30649";
        default:
          return (
            "http://www.nongsaro.go.kr/portal/search/dbyhsSearch.ps?menuId=PS03717&categoryName=TOTAL&sortOrdr=02&pageIndex=1&pageSize=10&pageUnit=10&searchWord=" +
            name +
            "&option=0"
          );
      }
    } else {
      return (
        "http://www.nongsaro.go.kr/portal/search/dbyhsSearch.ps?menuId=PS03717&categoryName=TOTAL&sortOrdr=02&pageIndex=1&pageSize=10&pageUnit=10&searchWord=" +
        name +
        "&option=0"
      );
    }
  }

  getAreaList(cropCode, params?) {
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`/api/disease/area/${cropCode}`, null, params)
        .then((data) => {
          if (data.areaList) {
            resolve(data.areaList);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  getTable(params) {
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`/api/disease/analysis/table`, null, params)
        .then((data) => {
          if (data) {
            resolve(data);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  update(id, params) {
    return new Promise((resolve, reject) => {
      const strParams = JSON.stringify(params);
      core.http
        .putJson(`/api/disease/analysis/${id}`, strParams)
        .then((data) => {
          if (data.analysis) {
            resolve(data.analysis);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  getAnnotationFile(cropCode) {
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`/api/disease/analysis/annotation/${cropCode}`, null)
        .then((data) => {
          if (data.downloadUri) {
            resolve(data.downloadUri);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  getDiseaseInfo(id) {
    return new Promise((resolve, reject) => {
      core.http
        .get(`/api/disease/info/${id}`, null)
        .then((data) => {
          if (data.diseaseInfo) {
            resolve(data.diseaseInfo);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  getInfoTableList(params) {
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`/api/disease/info/table`, null, params)
        .then((data) => {
          if (data) {
            resolve(data);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  createDiseaseInfo(params) {
    const strParams = JSON.stringify(params);
    return new Promise((resolve, reject) => {
      core.http
        .postJson(`/api/disease/info`, strParams)
        .then((data) => {
          if (data.diseaseInfo) {
            resolve(data.diseaseInfo);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  updateDiseaseInfo(id, params) {
    const strParams = JSON.stringify(params);
    return new Promise((resolve, reject) => {
      core.http
        .putJson(`/api/disease/info/${id}`, strParams)
        .then((data) => {
          if (data.diseaseInfo) {
            resolve(data.diseaseInfo);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  createDiseaseInfoImage(formData) {
    return new Promise((resolve, reject) => {
      core.http
        .post(`/api/disease/info/image`, formData, {
          headers: core.http.headers.form,
        })
        .then((data) => {
          if (data.fileDetailId) {
            resolve(data.fileDetailId);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  getRequestAnalysis(id) {
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`/api/disease/request-analysis/${id}`, null)
        .then((data) => {
          if (data.requestAnalysis) {
            resolve(data.requestAnalysis);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  getRequestAnalysisTableList(params) {
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`/api/disease/request-analysis/table`, null, params)
        .then((data) => {
          if (data) {
            resolve(data);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  createRequestDiseaseAnalysisImage(formData: FormData) {
    return new Promise((resolve, reject) => {
      core.http
        .post(`/api/disease/request-analysis/image`, formData, {
          headers: core.http.headers.form,
        })
        .then((data) => {
          if (data.fileDetailId) {
            resolve(data.fileDetailId);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  createRequestDiseaseAnalysis(params) {
    const strParams = JSON.stringify(params);
    return new Promise((resolve, reject) => {
      core.http
        .postJson(`/api/disease/request-analysis`, strParams)
        .then((data) => {
          if (data.requestAnalysis) {
            resolve(data.requestAnalysis);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  updateRequestDiseaseAnalysis(id, params) {
    const strParams = JSON.stringify(params);
    return new Promise((resolve, reject) => {
      core.http
        .putJson(`/api/disease/request-analysis/${id}`, strParams)
        .then((data) => {
          if (data.requestAnalysis) {
            resolve(data.requestAnalysis);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  updateRequestDiseaseAnalysisComplete(id, complete) {
    const strParams = JSON.stringify({ complete: complete });
    return new Promise((resolve, reject) => {
      core.http
        .putJson(`/api/disease/request-analysis/${id}/complete`, strParams)
        .then((data) => {
          if (data.requestAnalysis) {
            resolve(data.requestAnalysis);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }
}

export default new DiseaseService();
