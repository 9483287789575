import AbstractTableService from "@/services/core/abstract-table.service";
import core from "@/core";

class GrowthService extends AbstractTableService {
  constructor(url: string, itemKey: string) {
    super(url, itemKey);
  }

  uploadExcel(formData: FormData) {
    return new Promise((resolve, reject) => {
      core.http
        .post(`/api/growth/upload-excel`, formData)
        .then((data) => {
          //console.log("data: ", data);
          resolve(data);
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }
}

export default new GrowthService("/api/growth", "growth");
