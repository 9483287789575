










































import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import UserService from "@/services/user/user.service";
import core from "@/core";
import UserAddEditModal from "@/modals/UserAddEditModal.vue";
import UpdateEvent from "@/models";
import { UpdateEventType } from "@/types";

export default mixins(MixinsPageForm).extend({
  name: "Users",
  components: { UserAddEditModal },
  data: () => ({
    ready: false,
    loading: false,
    headers: [
      {
        text: "번호",
        align: "start",
        sortable: true,
        value: "id",
      },
      {
        text: "ID",
        value: "username",
        sortable: true,
      },
      {
        text: "이름",
        value: "name",
        sortable: false,
      },
      {
        text: "E-Mail",
        value: "email",
        sortable: false,
      },
      {
        text: "연락처",
        value: "phone",
        sortable: false,
      },
      {
        text: "부서",
        value: "department",
        sortable: false,
      },
      {
        text: "권한",
        value: "roles",
        sortable: false,
      },
      {
        text: "생성시간",
        value: "createdAt",
        sortable: true,
      },
      { text: "Actions", value: "actions", sortable: false },
    ],
    dataList: [] as any,
    options: {} as any,
    totalDataLength: 0,
    footerProps: {
      itemsPerPageOptions: [10, 20, 50, 100],
      disablePagination: false,
      disableItemsPerPage: false,
    },
    modal: {
      addEdit: {
        visible: false,
        updateEvent: null as UpdateEvent | null,
        params: {
          type: "add",
          user: null as any,
        },
      },
    },
  }),
  watch: {
    options: {
      handler() {
        if (this.ready && !this.loading) {
          this.getDataList();
        }
      },
      deep: true,
    },
    "modal.addEdit.updateEvent"() {
      const event = this.getComponentUpdateEvent(this.modal.addEdit);
      if (event?.result === UpdateEventType.CONFIRM) {
        this.getDataList();
      }
    },
  },
  mounted() {
    this.$nextTick(async () => {
      this.ready = true;

      await this.getDataList();
    });
  },
  methods: {
    addItem() {
      const modal = this.modal.addEdit;
      modal.params.type = "add";
      modal.visible = true;
    },
    editItem(user) {
      const modal = this.modal.addEdit;
      modal.params.type = "edit";
      modal.params.user = user;
      modal.visible = true;
    },
    async deleteItem(user) {
      const result = await core.alert.show({
        title: "확인",
        body: `${user.username} 사용자 정보를 삭제하시겠습니까?`,
        showCancelButton: true,
      });
      if (result === "confirm") {
        try {
          await UserService.delete(user.id);
          await this.getDataList();
        } catch (e) {
          console.log(e);
        }
      }
    },
    async getDataList() {
      this.loading = true;

      const options = this.options;
      //console.log("options : ", options);
      const params = {
        draw: 0,
        start: (options.page - 1) * options.itemsPerPage,
        length: options.itemsPerPage,
        orderColumnName: "createdAt",
        order: "desc",
        searchColumns: {} as any,
      };
      const searchColumns = params.searchColumns;
      // const form = this.form;
      // if (this.isNotBlank(form.dateRangeText)) {
      //   const array = form.dateRangeText.split(" ~ ");
      //   searchColumns.sensingAtGreaterThanEqual = array[0] + " 00:00:00";
      //   searchColumns.sensingAtLessThan =
      //     core.date.instance(array[1]).add(1, "days").format("YYYY-MM-DD") + " 00:00:00";
      // }
      // if (this.isNotBlank(form.sensorNo)) {
      //   searchColumns.sensorNo = form.sensorNo;
      // }
      // if (this.isNotBlank(form.inFacilityId)) {
      //   searchColumns.inFacilityId = form.inFacilityId;
      // }
      // if (options.sortBy.length > 0) {
      //   params.orderColumnName = options.sortBy[0];
      // }
      // if (options.sortDesc.length > 0) {
      //   params.order = options.sortDesc[0] ? "desc" : "asc";
      // }
      const response = (await UserService.getTable(params)) as any;
      this.dataList = response.data;
      this.totalDataLength = response.recordsTotal;

      this.loading = false;
    },
    getRoleToText(user) {
      //console.log("user : ", user);
      if (user.roles) {
        let roleText = "";
        user.roles.forEach((role) => {
          if (role === "ADMIN") roleText += "관리자,";
          else if (role === "MANAGER") roleText += "매니저,";
          else if (role === "BUSINESS_PARTNER") roleText += "협력사,";
          else if (role === "DEVICE_ADMIN") roleText += "장치관리자,";
          else if (role === "FARMER") roleText += "농업인,";
          else if (role === "USER") roleText += "사용자,";
          else roleText += role + ",";
        });
        if (roleText.length > 0) {
          roleText = roleText.substr(0, roleText.length - 1);
        }
        return roleText;
      }
      return "";
    },
    getPhoneToText(user) {
      return core.utils.format.hyphenPhone(user.phone);
    },
  },
});
