interface MobileCall {
  cmd: string;
  value?: any;
}

export default class CoreMobile {
  call(params: MobileCall) {
    if (this.isApp()) {
      const userAgent = navigator.userAgent.toLowerCase();

      if (userAgent.indexOf("android") > -1) {
        const strParam = JSON.stringify(params);
        //console.log("android call : ", strParam);
        (window as any).android.call(strParam);
      } else {
        console.log("not support platform");
      }
    } else {
      console.log("is not mobile app");
    }
  }

  isApp() {
    const userAgent = navigator.userAgent.toLowerCase();
    return userAgent.indexOf("scc") > -1;
  }
}
