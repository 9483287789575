




































































































































































































































































































































































































































































































































































































































































































































































import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import CodeService from "@/services/code/code.service";
import { localize } from "vee-validate";
import Constant from "@/store/constant";
import SearchAddressModal from "@/modals/core/SearchAddressModal.vue";
import UpdateEvent from "@/models";
import { UpdateEventType } from "@/types";
import VueUploadMultipleImage from "@/components/core/VueUploadMultipleImage.vue";
import core from "@/core";
import DiseaseService from "@/services/disease/disease.service";
import { cloneDeep } from "lodash";

localize("ko", {
  messages: Constant.validate.language.korea.messages,
  names: {
    cropCode: "작물 ",
    address: "수집지역 ",
    farmerName: "농장주 ",
    kindCode: "온실형태 ",
    date: "날짜 ",
    diseaseName: "병해이름 ",
    diseaseStep: "병해단계 ",
    cropPosition: "작물상 병해 위치 ",
    housePosition: "온실내 병해 위치 ",
    image: "이미지 ",
    imageFileIdList: "이미지 ",
  },
});
export default mixins(MixinsPageForm).extend({
  name: "DiseaseManagerInfoAddEditComponent",
  components: { VueUploadMultipleImage, SearchAddressModal },
  props: {
    type: {
      type: String,
      default: "add",
    },
  },
  data: () => ({
    processing: false,
    modal: {
      searchAddress: {
        visible: false,
        params: {
          location: null as any,
          loadMyLocation: true,
        },
        updateEvent: null as UpdateEvent | null,
      },
    },
    form: {
      cropCode: "",
      latitude: null,
      longitude: null,
      address1: null,
      address2: null,
      farmerName: null,
      kindCode: null,
      date: null,
      temp: null,
      humidity: null,
      diseaseName: null,
      diseaseStep: null,
      cropPosition: null,
      housePosition: null,
      imageFileIdList: [] as any,
    } as any,
    view: {
      address: "",
    },
    datePicker: {
      visible: false,
      date: "",
    },
    currentIndexImage: null as any,
    images: [] as any,
    cropCodeList: [] as any,
    kindCodeList: [
      {
        text: "단동",
        value: "S",
      },
      {
        text: "연동",
        value: "M",
      },
    ],
    diseaseStepList: [
      {
        text: "초기",
        value: "1",
      },
      {
        text: "중기",
        value: "2",
      },
      {
        text: "말기",
        value: "3",
      },
    ],
    uploadMultipleImage: {
      imageList: [] as any,
      maxSize: 20,
      uploadImageList: [] as any,
    },
    diseaseInfo: null as any,
    ready: false,
  }),
  mounted() {
    this.$nextTick(async () => {
      if (this.type === "edit") {
        const id = Number(this.$route.query.id as any);
        try {
          core.loader.show();
          if (!isNaN(id)) {
            const diseaseInfo = (await DiseaseService.getDiseaseInfo(id)) as any;
            //console.log("diseaseInfo : ", diseaseInfo);
            for (const key of Object.keys(this.form)) {
              this.form[key] = diseaseInfo[key];
            }

            if (diseaseInfo.address2 != null) {
              this.view.address = `${diseaseInfo.address1} ${diseaseInfo.address2}`;
            } else {
              this.view.address = `${diseaseInfo.address1}`;
            }

            diseaseInfo.imageList.forEach((image, index) => {
              console.log("image : ", image);
              const isDefault = index === 0;

              this.uploadMultipleImage.imageList.push({
                path: image.downloadUri,
                default: isDefault,
                highlight: 1,
                id: image.id,
              });
              this.uploadMultipleImage.uploadImageList = this.uploadMultipleImage.imageList;
            });
            this.diseaseInfo = diseaseInfo;
          } else {
            console.log("id : ", id);
            await this.notFound();
            return;
          }
        } catch (e) {
          console.log(e);
          await this.notFound();
          return;
        }
      }
      core.loader.hide();
      this.cropCodeList = await CodeService.getCropCodeList();
      this.ready = true;
    });
  },
  watch: {
    "form.cropCode"(cropCode) {
      if (this.ready) {
        this.form.cropPosition = null;
      }
    },
    "modal.searchAddress.updateEvent"(event) {
      if (event != null) {
        //console.log("event : ", event);
        if (event.result === UpdateEventType.CONFIRM) {
          const item = event.item;
          if (item.address2 != null) {
            this.view.address = `${item.address1} ${item.address2}`;
          } else {
            this.view.address = `${item.address1}`;
          }
          this.form.address1 = item.address1;
          this.form.address2 = item.address2;
          if (this.form.address2 == null) this.form.address2 = "";
          this.form.latitude = item.latitude;
          this.form.longitude = item.longitude;
        }
      }
    },
  },

  methods: {
    async submit() {
      if (await this.validate()) {
        const uploadImageList = this.uploadMultipleImage.uploadImageList;
        const imageTotalSize = uploadImageList.length;
        if (this.type === "add") {
          if (uploadImageList.length == 0) {
            await core.alert.show({ title: "확인", body: "이미지 파일은 필수입니다" });
            return;
          }
        }
        this.processing = true;

        const imageFileIdList = [] as any;
        let count = 0;
        for (const image of uploadImageList) {
          try {
            if (image.id != null) {
              imageFileIdList.push(image.id);
            } else {
              const formData = new FormData();
              formData.append("imageFile", image.blob, image.name);
              core.loader.show(`이미지 업로드 ${++count}/${imageTotalSize}`);
              const fileDetailId = await DiseaseService.createDiseaseInfoImage(formData);
              imageFileIdList.push(fileDetailId);
            }
          } catch (e) {
            console.log(e);
            await core.alert.show({
              title: "알림",
              body: "이미지 업로드 실패!",
            });
            this.processing = false;
            return;
          }
        }

        // 병해충 정보 저장
        const params = cloneDeep(this.form);
        params.imageFileIdList = imageFileIdList;
        console.log("params : ", params);
        try {
          if (this.type === "add") {
            const diseaseInfo = await DiseaseService.createDiseaseInfo(params);
            console.log("create, diseaseInfo : ", diseaseInfo);
          } else if (this.type === "edit") {
            const diseaseInfo = await DiseaseService.updateDiseaseInfo(this.diseaseInfo.id, params);
            console.log("update, diseaseInfo : ", diseaseInfo);
          } else {
            console.log("invalid type : ", this.type);
          }
          this.$router.back();
        } catch (e) {
          console.log(e);
        }
        core.loader.hide();
      }
    },
    changeHighlight(currentIndex) {
      this.currentIndexImage = currentIndex;
      let arr = this.images;
      this.images = [];
      arr.map((item, index) => {
        if (currentIndex === index) {
          item.highlight = 1;
        } else {
          item.highlight = 0;
        }
        return item;
      });
      this.images = arr;
    },
    searchAddress() {
      const modal = this.modal.searchAddress;
      //console.log("form :", this.form);
      if (this.form.latitude != null) {
        modal.params.location = {
          latitude: this.form.latitude,
          longitude: this.form.longitude,
          address1: this.form.address1,
          address2: this.form.address2,
          level: 2,
        };
        //console.log("modal params : ", modal.params);
      }
      modal.visible = true;
    },

    uploadImageSuccess(index, fileList) {
      this.uploadMultipleImage.uploadImageList = fileList;
    },
    changedImageFileList(index, fileList) {
      this.uploadMultipleImage.uploadImageList = fileList;
    },
    beforeRemove(index, done, fileList) {
      console.log("beforeRemove");
      done();
      this.uploadMultipleImage.uploadImageList = fileList;
    },
    editImage(index, fileList) {
      console.log("edit image", index, fileList);
      this.uploadMultipleImage.uploadImageList = fileList;
      delete fileList[index].id;
    },
    limitExceeded() {
      console.log("limitExceeded");
      core.alert.show({
        title: "알림",
        body: `최대 업로드 수(${this.uploadMultipleImage.maxSize})를 초과하였습니다.`,
      });
    },

    isSelectedCrop(crop) {
      let cropCode = null as any;
      this.cropCodeList.some((_crop) => {
        if (_crop.enName === crop) {
          cropCode = _crop;
          return true;
        }
      });

      if (cropCode != null) {
        return this.form.cropCode === cropCode.code;
      }
      return false;
    },
    changeCropPosition(position) {
      this.form.cropPosition = String(position);
      //console.log("change crop position : ", position);
    },
    getCropPositionStyle(position) {
      position = String(position);
      //console.log("get crop position : ", position);
      if (this.form.cropPosition === position) {
        return "border: 2px solid #38ff00; background: rgba(0, 0, 0, 0.8)";
      }
      return "";
    },
    changeHousePosition(position) {
      this.form.housePosition = String(position);
    },
    getHousePositionStyle(position) {
      position = String(position);
      if (this.form.housePosition === position) {
        return "border: 2px solid #38ff00; background: rgba(0, 0, 0, 0.8)";
      }
      return "";
    },
  },
});
