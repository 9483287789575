







































import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import SelectFileModal from "@/modals/SelectFileModal.vue";
import OpenDataService from "@/services/opendata/open-data.service";
import core from "@/core";

export default mixins(MixinsPage).extend({
  name: "DataManagementView",
  components: { SelectFileModal },
  data: () => ({
    id: 0,
    data: null as any,
    visible: {
      editButton: false,
    },
  }),
  mounted() {
    this.$nextTick(async () => {
      this.id = parseInt(this.$route.params.id);
      try {
        this.data = await OpenDataService.get(this.id);
      } catch (e) {
        console.log(e);
        await this.notFound();
        return;
      }

      const user = await this.$store.getters["auth/user"]();
      if (user != null && user.roles != null) {
        let hasManagerRole = false;
        user.roles.forEach((role) => {
          if (role === "MANAGER" || role === "ADMIN") {
            hasManagerRole = true;
          }
        });

        if (hasManagerRole) {
          this.visible.editButton = true;
        }
      }
    });
  },
  methods: {
    async deleteItem() {
      const result = await core.alert.show({
        title: "확인",
        body: `정보를 삭제하시겠습니까?`,
        showCancelButton: true,
      });
      if (result === "confirm") {
        try {
          await OpenDataService.delete(this.id);
          this.$router.back();
        } catch (e) {
          console.log(e);
        }
      }
    },
    download(item) {
      window.open(item.link);
    },
    bytesToHuman(bytes) {
      const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes === 0) return "0 Bytes";
      const i = parseInt(String(Math.floor(Math.log(bytes) / Math.log(1024))), 10);
      if (i === 0) return `${bytes} ${sizes[i]}`;
      return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
    },
  },
});
