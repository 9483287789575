import core from "@/core";
import { TableRequest } from "@/types";

class GreenHouseService {
  getTable(params: TableRequest) {
    //console.log("this.url : ", this.url);
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`/api/green-house/table`, null, params)
        .then((data) => {
          this.convertData(data.data);
          resolve(data);
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  convertData(dataList) {
    dataList.forEach((data) => {
      const display = (data.display = {} as any);
      display.outTemp = data.xouttemp;
      display.outWindDirection = data.xwinddirec;
      display.outWindSpeed = data.xwindsp;
      display.outTemp = data.xouttemp;
      display.solarRadiation = data.xsunadd;

      display.dewPointTemp = data.xdhum;
      display.inTemp = data.xintemp1;
      display.inHumidity = data.xinhum1;
      display.inCo2 = data.xco2;
      display.inPowerUsage = Math.floor(Math.random() * 5) + 149;
      display.inNutrientSupply = Math.floor(Math.random() * 2) + 143;

      display.control = {};
      display.control.light = data.xlightrun !== 0;
      display.control.pump = data.xpumprun1 !== 0 || data.xpumprun2 !== 0;
      display.control.fan = data.xfan1run !== 0 || data.xfan2run !== 0 || data.xass1run !== 0;
      display.control.air =
        data.xheatandcool1run !== 0 ||
        data.xheatandcool2run !== 0 ||
        data.xheatandcool3run !== 0 ||
        data.xheatandcool4run !== 0 ||
        data.xheatandcool5run !== 0;
      display.control.bo = data.xborun !== 0;
      display.control.fog = data.xass3run !== 0;
      display.color = "";
      display.colorClass = "";
      display.name = "";
    });
  }

  getWeather() {
    //console.log("this.url : ", this.url);
    return new Promise((resolve, reject) => {
      core.http
        .get(`/api/green-house/weather`, null, null)
        .then((data) => {
          resolve(data);
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }
}

export default new GreenHouseService();
