















import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import core from "@/core";
import OwncloudService from "@/services/owncloud/owncloud.service";

export default mixins(MixinsPageForm).extend({
  name: "Cloud",
  data: () => ({
    loading: true,
    owncloudLogoutUrl: "",
  }),
  mounted() {
    this.$nextTick(async () => {
      // this.ready = true;

      try {
        const owncloud = this.$refs.owncloud as any;
        const vm = this;
        const attachUnload = async () => {
          vm.loading = false;
          // await core.http.get("/owncloud-manager/auth");
          const iframeLocation = owncloud.contentDocument.location;
          const iframeDocument = owncloud.contentWindow.document;
          const pathname = iframeLocation.pathname;
          console.log("onload pathname : ", pathname);
          if (pathname.indexOf("/owncloud/index.php/login") > -1) {
            // if (iframeItems.loginPageCount > 10) {
            //   console.log("login error");
            //   return;
            // }
            console.log("login page");
            // const form = iframeDocument.querySelector("form[name=login]");
            // form.style.display = "none";
            //
            // const userEl = iframeDocument.getElementById("user");
            // const passwordEl = iframeDocument.getElementById("password");
            // if (iframeItems.owncloudUser != null) {
            //   userEl.value = iframeItems.owncloudUser.username;
            //   passwordEl.value = iframeItems.owncloudUser.password;
            //   iframeDocument.querySelector(".login-button").click();
            // }
            // console.log("login click");
            // iframeItems.loginPageCount++;
          } else if (pathname.indexOf("/owncloud/index.php/apps/files") > -1) {
            const settingsEl = iframeDocument.getElementById("settings");
            if (settingsEl != null) {
              settingsEl.style.display = "none";
            }
            const notificationsEl = iframeDocument.querySelector(".notifications");
            if (notificationsEl != null) {
              notificationsEl.style.display = "none";
            }
            const menuEl = iframeDocument.querySelector(".menutoggle");
            if (menuEl != null) {
              menuEl.style.display = "none";
            }
            // iframeDocument.getElementById("expand").style.display = "none";
            const name = iframeDocument.getElementById("expandDisplayName").innerHTML.trim();
            // console.log("name : ", name);
            vm.owncloudLogoutUrl = iframeDocument.getElementById("logout").href;
            const closeWizard = iframeDocument.getElementById("closeWizard");
            if (closeWizard != null) {
              closeWizard.click();
            }
          }
          //console.log("href : ", iframe.location);

          // const win = owncloud.contentWindow;
          // console.log("win : ", win);
        };
        owncloud.addEventListener("load", attachUnload);

        if (await OwncloudService.owncloudLogin()) {
          owncloud.src = "/owncloud/index.php/apps/files";
        }
        // const directoryItems = ;
        // const result = await core.http.get("/api/user/owncloud");
        // iframeItems.owncloudUser = result.owncloud;
        // await attachUnload();
        //console.log("result : ", result);
      } catch (e) {
        console.log(e);
        await core.alert.show({
          title: "알림",
          body: "파일 서버 접속 오류가 발생하였습니다.",
        });
        this.loading = false;
      }
    });
  },
  destroyed() {
    //console.log("destroyed");
    OwncloudService.owncloudLogout();
  },
  methods: {},
});
