














































































































































import mixins from "vue-typed-mixins";
import MixinsComponent, { ComponentProperties } from "@/mixins/component";
import UpdateEvent from "@/models";
import core from "@/core";
import CoreKakaoMap from "@/core/core-kakao-map";
import { UpdateEventType } from "@/types";
import Constant from "@/store/constant";

declare let kakao: any;

export interface Location {
  address1: string;
  address2: string | null | undefined;
  latitude: number;
  longitude: number;
}

export default mixins(MixinsComponent).extend({
  name: "SearchAddressComponent",
  props: {
    properties: {
      type: Object as () => ComponentProperties,
      default: () => {
        return {
          params: {
            // 초기 위치 정보
            location: {
              address1: "",
              address2: "",
              latitude: 0,
              longitude: 0,
              level: 2,
            },
            loadMyLocation: false,
          },
          // 업데이트 이벤트 처리용 변수
          updateEvent: null as any as UpdateEvent | null,
        };
      },
    },
  },
  data: () => ({
    // viewType map or search
    viewType: "map",
    // update 이후 이벤트 처리
    updateViewType: null as string | null,
    search: {
      loading: false,
      readonly: false,
      request: {
        page: 1,
        size: 10,
        query: "",
        loadNextPage: false,
      },
      response: {
        meta: {},
        list: [],
      } as any,
    },
    map: {
      addressText: null as string | null,
      default: {
        address: "선택된 위치가 없습니다",
        latitude: 37.5653888,
        longitude: 126.9783866,
        level: 6,
      },
      findLocation: false,
    },
    selectedItem: null as Location | null,
    kakaoMap: null as CoreKakaoMap | null,
    isHttps: false,
  }),
  mounted() {
    this.$nextTick(async () => {
      this.isHttps = window.location.protocol.indexOf("https") > -1;

      const params = this.properties.params;
      if (params == null) {
        console.log("is not found params");
        return;
      }
      const location = params.location;
      if (location != null) {
        if (location.level == null || location.level < 1) location.level = 2;
        this.initMap(location, false);
      } else {
        const defaultLocation = this.map.default;
        const location = {
          address1: defaultLocation.address,
          latitude: defaultLocation.latitude,
          longitude: defaultLocation.longitude,
          level: defaultLocation.level,
        };
        this.initMap(location, this.isHttps);
      }
    });
  },

  watch: {
    "search.request.loadNextPage"(loadNextPage) {
      if (loadNextPage) {
        this.submit();
      }
    },
  },
  updated() {
    const updateViewType = this.updateViewType;
    this.updateViewType = null;
    const kakaoMap = this.kakaoMap as CoreKakaoMap;
    if (updateViewType === "map") {
      const location = this.selectedItem;
      kakaoMap.relayout();
      if (location != null) {
        kakaoMap.setCenterAndLevel(location?.latitude, location?.longitude, 2);
      }
    }
  },
  methods: {
    confirm() {
      this.setPropsUpdateEvent(UpdateEventType.CONFIRM, this.selectedItem);
    },
    loadNextPage() {
      this.search.request.loadNextPage = true;
    },
    submit() {
      //console.log("submit");
      if (this.viewType === "search") {
        const kakaoMap = this.kakaoMap as CoreKakaoMap;
        const search = this.search;
        search.readonly = true;

        const query = search.request.query;
        if (query.length > 0) {
          this.search.loading = true;
          if (search.request.loadNextPage) {
            search.request.page++;
          } else {
            search.request.page = 1;
            search.response.list = [];
            search.response.meta = {};
          }
          kakaoMap
            .searchKeyword(search.request)
            .then((res: any) => {
              if (search.request.loadNextPage) {
                res.list.forEach((data) => {
                  search.response.list.push(data);
                });
                search.response.meta = res.meta;
                search.request.loadNextPage = false;
              } else {
                search.response = res;
              }
              search.readonly = false;
              search.loading = false;
            })
            .catch((reason) => {
              console.log("error : ", reason);
              search.readonly = false;
              search.loading = false;
              search.request.loadNextPage = false;
            });
        } else {
          console.log("invalid query : ", query);
        }
      } else {
        console.log("invalid viewType : ", this.viewType);
      }
    },
    changeView(viewType) {
      this.viewType = viewType;
      //console.log("changeView : ", viewType);

      if (viewType === "map") {
        // 지도 레이아웃이 렌더링 될때 처리하기 위함
        this.updateViewType = "map";
        this.search.readonly = true;
      } else if (viewType === "search") {
        this.search.readonly = false;
      } else {
        console.log("unknown viewType : ", viewType);
      }
    },
    selectItem(item) {
      console.log("selectItem : ", item);

      const location = {
        address:
          item.roadAddressName != null && item.roadAddressName.length > 0
            ? item.roadAddressName
            : item.addressName,
        latitude: item.latitude,
        longitude: item.longitude,
        buildingName: item.placeName != null ? item.placeName : "",
        fullAddress: "",
      };
      if (location.buildingName.length > 0) {
        location.fullAddress = `${location.address}(${location.buildingName})`;
      } else {
        location.fullAddress = location.address;
      }

      this.selectedItem = {
        address1: location.address,
        address2: location.buildingName,
        latitude: location.latitude,
        longitude: location.longitude,
      };
      this.changeView("map");
      this.map.addressText = location.fullAddress as string;
    },
    moveCurrentPosition() {
      if (this.map.findLocation) {
        this.map.findLocation = false;
      } else {
        this.map.findLocation = true;

        const kakaoMap = this.kakaoMap as CoreKakaoMap;
        core.location
          .currentPosition()
          .then((location: any) => {
            //console.log('location : ', location);
            this.map.default.latitude = location.latitude;
            this.map.default.longitude = location.longitude;

            if (this.map.findLocation) {
              this.map.findLocation = false;
              kakaoMap
                .changeAddressByLocation(location.latitude, location.longitude, false)
                .then((data) => {
                  if (data != null) {
                    this.map.addressText = data.fullAddress;
                    this.selectedItem = {
                      address1: data.address,
                      address2: data.buildingName,
                      latitude: data.latitude,
                      longitude: data.longitude,
                    };
                    kakaoMap.setCenter(data.latitude, data.longitude);
                  }
                })
                .catch((reason) => {
                  console.log(reason);
                });
            }
          })
          .catch((reason) => {
            this.map.findLocation = false;
          });
      }
    },
    initMap(params: any, isHttps) {
      //console.log("init map");
      const mapData = this.map;
      let address = params.address1;
      if (params.address2 != null && params.address2.length > 0) {
        address += `(${params.address2})`;
      }
      mapData.addressText = address;
      const mapElement = this.$refs.map;
      // const mapChangeEvent = {
      //   preStatus: "",
      //   preDate: new Date(),
      // };

      const locationItem = {
        changeTime: null as Date | null,
        interval: null as any,
        latitude: null as number | null | undefined,
        longitude: null as number | null | undefined,
      };

      const vm = this;
      const kakaoMap = (this.kakaoMap = new CoreKakaoMap(
        {
          map: {
            element: mapElement,
            option: {
              center: new kakao.maps.LatLng(params.latitude, params.longitude),
              level: params.level,
            },
            view: "selectLocation",
          },
          callback: {
            centerChanged(loc) {
              if (
                locationItem.latitude != null &&
                locationItem.longitude == loc.latitude &&
                locationItem.longitude == loc.longitude
              ) {
                console.log("동일 위치 중앙이동");
                return;
              }
              locationItem.latitude = loc.latitude;
              locationItem.longitude = loc.longitude;
              locationItem.changeTime = new Date();

              if (locationItem.interval == null) {
                mapData.addressText = "지도 이동 중";
                locationItem.interval = setInterval(() => {
                  const curTime = new Date();
                  if (curTime.getTime() - (locationItem.changeTime as Date).getTime() > 100) {
                    clearInterval(locationItem.interval as number);
                    locationItem.interval = null;
                    locationItem.changeTime = null;

                    kakaoMap
                      .changeAddressByLocation(
                        locationItem.latitude as number,
                        locationItem.longitude as number,
                        false
                      )
                      .then((location) => {
                        if (location != null) {
                          mapData.addressText = location.fullAddress;

                          vm.selectedItem = {
                            address1: location.address,
                            address2: location.buildingName,
                            latitude: location.latitude,
                            longitude: location.longitude,
                          };
                        }
                      })
                      .catch((reason) => {
                        console.log(reason);
                      });
                  }
                }, 500);
              }
            },
            dragEnd() {
              // 드래그 종료시
              locationItem.changeTime = new Date(new Date().getTime() - 5000);
            },
            zoomChanged() {
              // 줌 변경시
              locationItem.changeTime = new Date(new Date().getTime() - 5000);
            },
          },
        },
        core,
        Constant.kakao.restApiKey
      ));

      if (this.properties.params.loadMyLocation && isHttps) {
        core.alert
          .show({
            title: "확인",
            body: "현재 내 위치로 이동하시겠습니까?",
            showCancelButton: true,
          })
          .then((result) => {
            if (result === "confirm") {
              this.moveCurrentPosition();
            }
          });
      }
    },
  },
});
