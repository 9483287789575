import core from "@/core";
import Vue from "vue";
import UpdateEvent from "@/models";

/**
 * 공통 처리
 */
export default Vue.extend({
  data: () => ({
    // 업데이트 이벤트 처리용 변수
    updateEvent: null as any as UpdateEvent | null,
    dateFormatPattern: "YYYY-MM-DD",
    dateTimeFormatPattern: "YYYY-MM-DD HH:mm",
  }),
  methods: {
    isBlank(str) {
      return core.utils.validate.isBlank(str);
    },
    isNotBlank(str) {
      return core.utils.validate.isNotBlank(str);
    },
    moneyFormat(str: string) {
      return core.utils.format.moneyKor(str);
    },
    dateElapsedTimeFormat(date: string) {
      return core.date.format.elapsedTime(date);
    },
    dateFormat(date: string) {
      return core.date.instance(date).format(this.dateFormatPattern);
    },
    dateTimeFormat(date: string) {
      return core.date.instance(date).format(this.dateTimeFormatPattern);
    },
    phoneFormat(phone: string) {
      return core.utils.format.hyphenPhone(phone);
    },
  },
});
