































































































































































































































































































































































































import mixins from "vue-typed-mixins";
import MixinsComponent from "@/mixins/component";
import GreenHouseService from "@/services/greenhouse/green-house.service";
import { TableOrder } from "@/types";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import core from "@/core";
import store from "@/store";
import TranspirationCalculator from "@/core/transpiration-calculator";

export default mixins(MixinsComponent).extend({
  name: "GreenHouseStatusComponent",
  props: {
    properties: {
      type: Object,
      default: () => {
        return {
          params: {
            devId: 0,
          },
        };
      },
    },
  },
  data: () => ({
    app: store.state.app,
    title: "",
    statusText: "온실 상황이 정상입니다",
    devId: -1,
    data: null as any,
    dataList: null as any,
    orgDataList: null as any,
    displayData: {
      inTemp: null as any,
      inHumidity: null as any,
      inCo2: null as any,
      inPowerUsage: null as any,
      inNutrientSupply: null as any,
      dewPointTemp: null as any,
      outTemp: null as any,
      outWindSpeed: null as any,
      outWindDirection: null as any,
      solarRadiation: null as any,

      control: {
        light: false,
        pump: false,
        fan: false,
        air: false,
        bo: false,
        fog: false,
      },
    },
    chart: {
      status: null as any,
      statusHeight: 100,
      control: null as any,
      controlHeight: 100,
    },
    sparkline: {
      temp: [] as any,
      humidity: [] as any,
      co2: [] as any,
      power: [] as any,
      nutrientSupply: [] as any,
    },
    isDestroyed: false,
    timeoutMillis: 10000,
  }),
  beforeDestroy() {
    this.isDestroyed = true;
  },
  mounted() {
    this.resizeEvent();

    this.$nextTick(async () => {
      this.devId = this.properties.params.devId;

      this.createControlChart();
      this.createStatusChart();

      await this.getDataList();
      // await this.getLatestData();

      // xouttemp 외부온도
      // xwinddirec 풍향
      // xwindsp 풍속
      // xintemp1 실내온도
      // xinhum1 실내습도
      // xco2 co2
      // xsunadd 누적일사량
      // xdhum 이슬점
      // 임시 : 전력사용량
      //sparkline.power.push(Math.floor(Math.random() * 5) + 149)
      // 임시 : 양액공급량
      //sparkline.flow.push(Math.floor(Math.random() * 2) + 143)

      // 제어 불가
      // stat.light = data[data.length - 1]["xlightrun"] ? true : false;
      // stat.pump = (data[data.length - 1]["xpumprun1"] || data[data.length - 1]["xpumprun2"]) ? true : false;
      // stat.fan = (data[data.length - 1]["xfan1run"] || data[data.length - 1]["xfan2run"] || data[data.length - 1]["xass1run"]) ? true : false;
      // stat.air = (data[data.length - 1]["xheatandCool1run"] || data[data.length - 1]["xheatandCool2run"] || data[data.length - 1]["xheatandCool3run"] || data[data.length - 1]["xheatandCool4run"] || data[data.length - 1]["xheatandCool5run"]) ? true : false;
      // stat.bo = data[data.length - 1]["xborun"] ? true : false;
      // stat.fog = data[data.length - 1]["xass3run"] ? true : false;

      this.updateControlChart(this.data);

      this.updateStatusChart(this.dataList);

      const loader = this.$refs.loader as HTMLElement;
      loader.style.display = "none";

      this.resizeEvent();

      this.loadData();
    });
  },
  watch: {
    "app.size"(size) {
      this.resizeEvent();
    },
    async "properties.params.devId"(devId) {
      this.devId = devId;
    },
    async devId(devId) {
      //console.log("changed devId : ", devId);
      const loader = this.$refs.loader as HTMLElement;
      loader.style.display = "block";
      this.devId = devId;
      this.title = "온실" + this.devId;
      this.resetData();

      await this.getDataList();
      this.updateControlChart(this.data);
      this.updateStatusChart(this.dataList);
      loader.style.display = "none";
      this.resizeEvent();
    },
  },
  methods: {
    resetData() {
      this.statusText = "";
      this.data = null as any;
      this.dataList = null as any;
      this.orgDataList = null as any;
      this.sparkline.temp = [];
      this.sparkline.humidity = [];
      this.sparkline.co2 = [];
      this.sparkline.power = [];
      this.sparkline.nutrientSupply = [];
      for (const key of Object.keys(this.displayData)) {
        const value = this.displayData[key];
        if (key === "control") {
          for (const subKey of Object.keys(value)) {
            value[subKey] = false;
          }
        } else {
          this.displayData[key] = null;
        }
      }
      this.chart.status.data = [];
      this.chart.control.data = [];
    },
    loadData() {
      if (!this.isDestroyed) {
        setTimeout(async () => {
          if (!this.isDestroyed) {
            console.log("load data");
            if (await this.getDataList()) {
              this.updateControlChart(this.data);

              this.updateStatusChart(this.dataList);
            }
            console.log("updated data");

            this.loadData();
          } else {
            console.log("destroyed");
          }
        }, this.timeoutMillis);
      }
    },
    async getDataList() {
      const cycle = 15;
      const searchMoment = core.date.instance().add(-8, "hours");
      const cycleCount = parseInt(String(searchMoment.minute() / cycle));
      const minute = cycleCount * cycle;
      searchMoment.set("minutes", minute);
      // 테스트 코드
      // if (this.data == null) {
      //   searchMoment.add(-15, "minutes");
      // }
      const strSearchDate = searchMoment.format("YYYY-MM-DD HH:mm:00");
      //console.log("strSearchDate : ", strSearchDate);
      const params = {
        draw: 0,
        start: 0,
        length: 1000,
        orderColumnName: "createdAt",
        order: TableOrder.ASC,
        searchColumns: {
          devId: this.devId,
          createdAtGreaterThan: strSearchDate,
        },
      } as any;

      if (this.data != null) {
        params.searchColumns.idGreaterThan = this.data.id;
      }

      const result = (await GreenHouseService.getTable(params)) as any;
      // 평균데이터 생성
      if (result.data.length > 0) {
        let dataList = result.data;
        const data = dataList[dataList.length - 1];

        let updatedData = false;
        if (this.data != null) {
          if (this.data.id < data.id) {
            //console.log("pre data id : ", this.data.id);
            //console.log("new data id : ", data.id);
            this.data = data;
            updatedData = true;
          }
        } else {
          this.data = data;
          updatedData = true;
        }

        if (updatedData) {
          this.updateDisplayData(this.data);

          if (this.orgDataList == null) {
            this.orgDataList = dataList;
          } else {
            dataList.forEach((_data) => {
              this.orgDataList.push(_data);
            });

            // 검색시간보다 오래된 데이터 삭제
            for (let i = this.orgDataList.length - 1; i >= 0; i--) {
              const _data = this.orgDataList[i];
              const createdAtMoment = core.date.instance(_data.createdAt);
              if (createdAtMoment.isBefore(searchMoment)) {
                this.orgDataList.splice(i, 1);
                //console.log("데이터 삭제 : ", _data);
              }
            }
            dataList = this.orgDataList;
            //console.log("dataList : ", dataList);
          }

          // sparkline data 생성
          const sparkline = this.sparkline;
          sparkline.temp = [];
          sparkline.humidity = [];
          sparkline.co2 = [];
          sparkline.power = [];
          sparkline.nutrientSupply = [];
          for (let i = dataList.length - 1; i >= 0; i--) {
            const _data = dataList[i];
            sparkline.temp.push(_data.xintemp1);
            sparkline.humidity.push(_data.xinhum1);
            sparkline.co2.push(_data.xintemp1);
            sparkline.power.push(Math.floor(Math.random() * 5) + 149);
            sparkline.nutrientSupply.push(Math.floor(Math.random() * 2) + 143);
            if (sparkline.temp.length > 15) {
              break;
            }
          }
          sparkline.temp.reverse();
          sparkline.humidity.reverse();
          sparkline.co2.reverse();
          sparkline.power.reverse();
          sparkline.nutrientSupply.reverse();

          const map = new Map();
          const sensingTimeList = [] as string[];
          dataList.forEach((data) => {
            // 시간단위 센서 데이터 저장
            const createdAtMoment = core.date.instance(data.createdAt);

            const cycleCount = parseInt(String(createdAtMoment.minute() / cycle));
            const minute = cycleCount * cycle;
            //console.log("minute : ", minute);
            createdAtMoment.set("minutes", minute);

            const sensingTime = createdAtMoment.format("YYYY-MM-DD HH:mm:00");
            //console.log("sensingTime : ", sensingTime);

            // const sensingTime = core.date.instance(data.createdAt).format("YYYY-MM-DD HH:mm:00");
            if (!map.has(sensingTime)) {
              sensingTimeList.push(sensingTime);
              map.set(sensingTime, []);
            }

            // 데이터 추가
            const dataList = map.get(sensingTime);
            dataList.push(data);
          });
          //console.log("sensingTimeList : ", sensingTimeList);

          const avgDataList = [] as any;
          sensingTimeList.forEach((sensingTime) => {
            const dataList = map.get(sensingTime);
            const data = {
              date: core.date.instance(sensingTime).toDate(),
              strDate: sensingTime,
              temp: 0,
              humidity: 0,
              co2: 0,
            };
            dataList.forEach((_data) => {
              data.temp += _data.xintemp1;
              data.humidity += _data.xinhum1;
              data.co2 += _data.xco2;
            });
            data.temp = Number((data.temp / dataList.length).toFixed(1));
            data.humidity = Number((data.humidity / dataList.length).toFixed(1));
            data.co2 = Number((data.co2 / dataList.length).toFixed(1));
            avgDataList.push(data);
          });
          this.dataList = avgDataList;
          //console.log("dataList : ", this.dataList);
          return true;
        }
        //console.log("dataList : ", this.dataList);
        return false;
      }
    },
    updateDisplayData(data) {
      const displayData = this.displayData;
      displayData.outTemp = data.xouttemp;
      displayData.outWindDirection = data.xwinddirec;
      console.log("displayData.outWindDirection : ", displayData.outWindDirection);
      displayData.outWindSpeed = data.xwindsp;
      displayData.outTemp = data.xouttemp;
      displayData.solarRadiation = data.xsunadd;

      displayData.dewPointTemp = data.xdhum;
      displayData.inTemp = data.xintemp1;
      displayData.inHumidity = data.xinhum1;
      displayData.inCo2 = data.xco2;
      displayData.inPowerUsage = Math.floor(Math.random() * 5) + 149;
      displayData.inNutrientSupply = Math.floor(Math.random() * 2) + 143;

      displayData.control.light = data.xlightrun !== 0;
      displayData.control.pump = data.xpumprun1 !== 0 || data.xpumprun2 !== 0;
      displayData.control.fan = data.xfan1run !== 0 || data.xfan2run !== 0 || data.xass1run !== 0;
      displayData.control.air =
        data.xheatandcool1run !== 0 ||
        data.xheatandcool2run !== 0 ||
        data.xheatandcool3run !== 0 ||
        data.xheatandcool4run !== 0 ||
        data.xheatandcool5run !== 0;
      displayData.control.bo = data.xborun !== 0;
      displayData.control.fog = data.xass3run !== 0;
      //console.log(displayData.control);

      // stat.light = data[data.length - 1]["xlightrun"] ? true : false;
      // stat.pump = (data[data.length - 1]["xpumprun1"] || data[data.length - 1]["xpumprun2"]) ? true : false;
      // stat.fan = (data[data.length - 1]["xfan1run"] || data[data.length - 1]["xfan2run"] || data[data.length - 1]["xass1run"]) ? true : false;
      // stat.air = (data[data.length - 1]["xheatandCool1run"] || data[data.length - 1]["xheatandCool2run"] || data[data.length - 1]["xheatandCool3run"] || data[data.length - 1]["xheatandCool4run"] || data[data.length - 1]["xheatandCool5run"]) ? true : false;
      // stat.bo = data[data.length - 1]["xborun"] ? true : false;
      // stat.fog = data[data.length - 1]["xass3run"] ? true : false;

      const tc = TranspirationCalculator.newInstance({
        dryBulbTemp: displayData.inTemp,
        relativeHumidity: displayData.inHumidity,
      });
      //console.log("tc : ", tc);

      const recommend = tc.recommend;

      if (displayData.inTemp == recommend.temp && displayData.inHumidity == recommend.humidity) {
        this.statusText = "온실 상황이 정상입니다";
      } else {
        let text = "";
        if (displayData.inTemp < recommend.temp) {
          text += `온도를 ${recommend.temp}℃ 까지 높이거나, `;
        } else if (displayData.inTemp > recommend.temp) {
          text += `온도를 ${recommend.temp}℃ 까지 낮추거나, `;
        }

        if (displayData.inHumidity < recommend.humidity) {
          text += `습도를 ${recommend.humidity}% 까지 높여야 합니다. `;
        } else if (displayData.inHumidity > recommend.humidity) {
          text += `습도를 ${recommend.humidity}% 까지 낮춰야 합니다.`;
        }
        this.statusText = text;
      }
    },
    updateStatusChart(dataList) {
      const chart = this.chart.status;
      if (chart.data.length > 0) {
        if (dataList.length != chart.data.length) {
          chart.data = dataList;
          //console.log("시간 변경되어 차트 전체 데이터 변경");
        } else {
          const data = dataList[dataList.length - 1];
          const chartData = chart.data[chart.data.length - 1];
          if (data.strDate !== chartData.strDate) {
            chart.data = dataList;
            //console.log("시간 변경되어 차트 전체 데이터 변경");
          } else {
            // 최종 데이터만 변경
            chartData.temp = data.temp;
            chartData.humidity = data.humidity;
            chartData.co2 = data.co2;
            chart.invalidateData();
            //console.log("최종 데이터 변경");
          }
        }
      } else {
        chart.data = dataList;
        //console.log("차트 전체 데이터 변경");
      }
    },
    updateControlChart(data) {
      const chart = this.chart.control;

      chart.data = [
        {
          category: "천창",
          left: parseInt(data.xwinvol1_1) * -1,
          right: parseInt(data.xwinvol1_2),
        },
        {
          category: "공조외부창",
          left: parseInt(data.xwinvol2_1) * -1,
          right: parseInt(data.xwinvol2_2),
        },
        {
          category: "공조내부창",
          left: parseInt(data.xwinvol3_1) * -1,
          right: parseInt(data.xwinvol3_2),
        },
        {
          category: "내부차광커튼",
          left: parseInt(data.xcur1vol) * -1,
          right: parseInt(data.xcur1vol),
        },
      ];
    },
    createControlChart() {
      const el = this.$refs.controlChart as any;
      const chart = am4core.create(el, am4charts.XYChart);

      // Create axes
      var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "category";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.inversed = true;
      categoryAxis.renderer.minGridDistance = 20;
      categoryAxis.renderer.axisFills.template.disabled = false;
      categoryAxis.renderer.axisFills.template.fillOpacity = 0.05;
      categoryAxis.renderer.labels.template.fill = am4core.color("#fff");

      var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
      valueAxis.min = -100;
      valueAxis.max = 100;
      valueAxis.renderer.minGridDistance = 50;
      valueAxis.renderer.ticks.template.length = 5;
      valueAxis.renderer.ticks.template.disabled = false;
      valueAxis.renderer.ticks.template.strokeOpacity = 0.4;
      valueAxis.renderer.labels.template.fill = am4core.color("#fff");
      valueAxis.renderer.labels.template.adapter.add("text", function (text) {
        return text + "%";
      });

      // Legend
      chart.legend = new am4charts.Legend();
      chart.legend.position = "top";

      // Use only absolute numbers
      chart.numberFormatter.numberFormat = "#.#s";

      // Create series
      function createSeries(field, name, color) {
        var series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueX = field;
        series.dataFields.categoryY = "category";
        series.stacked = true;
        series.name = name;
        series.stroke = color;
        series.fill = color;
        if (field == "left") {
          series.hiddenInLegend = true;
        }
        var label = series.bullets.push(new am4charts.LabelBullet());
        label.label.text = "{valueX}%";
        // label.label.fill = am4core.color("#fff");
        label.label.strokeWidth = 0;
        label.label.truncate = false;
        label.label.hideOversized = true;
        label.locationX = 0.5;
        return series;
      }

      var interfaceColors = new am4core.InterfaceColorSet();
      var positiveColor = interfaceColors.getFor("positive");
      var negativeColor = interfaceColors.getFor("negative");

      createSeries("left", "개도율", positiveColor);
      createSeries("right", "개도율", positiveColor);

      chart.legend.events.on("layoutvalidated", function (event) {
        chart.legend.itemContainers.each((container: any) => {
          if (container.dataItem.dataContext.name == "Never") {
            container.toBack();
          }
        });
      });

      chart.legend.labels.template.fill = am4core.color("#fff");
      chart.legend.valueLabels.template.fill = am4core.color("#fff");
      this.chart.control = chart;
    },
    createStatusChart() {
      const el = this.$refs.statusChart as any;

      // Create chart instance
      const chart = am4core.create(el, am4charts.XYChart);

      chart.colors.step = 2;
      chart.maskBullets = false;

      // Add data
      chart.data = [];
      chart.dateFormatter.inputDateFormat = "yyyy-MM-dd HH:mm:ss";

      // Create axes
      const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.renderer.grid.template.location = 0;
      dateAxis.renderer.fullWidthTooltip = true;
      dateAxis.baseInterval = {
        timeUnit: "minute",
        count: 15,
      };
      dateAxis.renderer.labels.template.fill = am4core.color("#fff");

      const tempAxis = chart.yAxes.push(new am4charts.ValueAxis());
      tempAxis.title.text = "온도/습도";
      tempAxis.title.fill = am4core.color("#fff");
      tempAxis.min = 0;
      tempAxis.max = 100;
      tempAxis.renderer.labels.template.fill = am4core.color("#fff");

      const co2Axis = chart.yAxes.push(new am4charts.ValueAxis());
      co2Axis.title.text = "CO₂";
      co2Axis.title.fill = am4core.color("#fff");
      co2Axis.renderer.opposite = true;
      co2Axis.max = 1000;
      co2Axis.min = 0;
      co2Axis.renderer.labels.template.fill = am4core.color("#fff");

      /*
      var HumiAxis = chart.yAxes.push(new am4charts.ValueAxis());
      HumiAxis.max = 100;
      HumiAxis.min = 0;
      HumiAxis.syncWithAxis = co2Axis;
      */
      // Create series
      const co2Series = chart.series.push(new am4charts.ColumnSeries());
      co2Series.dataFields.valueY = "co2";
      co2Series.dataFields.dateX = "date";
      co2Series.yAxis = co2Axis;
      co2Series.tooltipText = "CO₂ : {valueY} ppm ";
      co2Series.name = "CO₂";
      co2Series.columns.template.width = am4core.percent(75);
      co2Series.columns.template.fillOpacity = 0.7;
      co2Series.columns.template.propertyFields.strokeDasharray = "dashLength";
      co2Series.columns.template.propertyFields.fillOpacity = "alpha";
      co2Series.showOnInit = true;

      const co2State = co2Series.columns.template.states.create("hover");
      co2State.properties.fillOpacity = 0.9;

      const tempSeries = chart.series.push(new am4charts.LineSeries());
      tempSeries.dataFields.valueY = "temp";
      tempSeries.dataFields.dateX = "date";
      tempSeries.yAxis = tempAxis;
      tempSeries.name = "실내온도";
      tempSeries.strokeWidth = 2;
      tempSeries.propertyFields.strokeDasharray = "dashLength";
      tempSeries.tooltipText = "실내온도 : {valueY} ℃";
      tempSeries.showOnInit = true;

      const tempBullet = tempSeries.bullets.push(new am4charts.Bullet());
      const tempRectangle = tempBullet.createChild(am4core.Rectangle);
      tempBullet.horizontalCenter = "middle";
      tempBullet.verticalCenter = "middle";
      tempBullet.width = 7;
      tempBullet.height = 7;
      tempRectangle.width = 7;
      tempRectangle.height = 7;

      const tempState = tempBullet.states.create("hover");
      tempState.properties.scale = 1.2;

      const humiSeries = chart.series.push(new am4charts.LineSeries());
      humiSeries.dataFields.valueY = "humidity";
      humiSeries.dataFields.dateX = "date";
      humiSeries.yAxis = tempAxis;
      humiSeries.name = "실내습도";
      humiSeries.strokeWidth = 2;
      humiSeries.propertyFields.strokeDasharray = "dashLength";
      humiSeries.tooltipText = "실내 습도: {valueY} %";
      humiSeries.showOnInit = true;

      var HumiBullet = humiSeries.bullets.push(new am4charts.CircleBullet());
      HumiBullet.circle.fill = am4core.color("#fff");
      HumiBullet.circle.strokeWidth = 2;

      var HumiState = HumiBullet.states.create("hover");
      HumiState.properties.scale = 1.2;

      var HumiLabel = humiSeries.bullets.push(new am4charts.LabelBullet());
      HumiLabel.label.horizontalCenter = "left";
      HumiLabel.label.dx = 14;

      // Add legend
      chart.legend = new am4charts.Legend();
      chart.legend.position = "top";
      chart.legend.labels.template.fill = am4core.color("#fff");
      chart.legend.valueLabels.template.fill = am4core.color("#fff");

      // Add cursor
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.fullWidthLineX = true;
      chart.cursor.xAxis = dateAxis;
      chart.cursor.lineX.strokeOpacity = 0;
      chart.cursor.lineX.fill = am4core.color("#000");
      chart.cursor.lineX.fillOpacity = 0.1;

      this.chart.status = chart;
    },
    resizeEvent() {
      //console.log("resize event");
      const maxHeight = this.app.size.height - 220;
      //console.log("maxHeight : ", maxHeight);
      const wrapEl = this.$refs.wrap as HTMLElement;
      // const height = wrapEl.clientHeight;
      // console.log("height : ", height);

      {
        const elList = wrapEl.querySelectorAll(".left .card-row");
        let elHeight = 0;
        elList.forEach((el) => {
          elHeight += el.clientHeight;
        });
        //console.log("elHeight : ", elHeight);
        let chartHeight = maxHeight - elHeight;
        if (this.app.size.width > 960) {
          if (chartHeight < 385) {
            chartHeight = 385;
          }
        } else {
          if (chartHeight < 300) {
            chartHeight = 300;
          }
        }
        //console.log("chartHeight : ", chartHeight);
        this.chart.statusHeight = chartHeight;
      }

      {
        const elList = wrapEl.querySelectorAll(".right .card-row");
        let elHeight = 0;
        elList.forEach((el) => {
          elHeight += el.clientHeight;
        });
        //console.log("elHeight : ", elHeight);
        let chartHeight = maxHeight - elHeight;
        if (chartHeight < 250) {
          chartHeight = 250;
        }
        //console.log("chartHeight : ", chartHeight);
        this.chart.controlHeight = chartHeight;
      }
    },
  },
});
