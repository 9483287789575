import CodeService from "@/services/code/code.service";
import core from "@/core";

class FacilityService {
  getList(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const cropCodeList = (await CodeService.getCropCodeList()) as any;
      core.http
        .getJson(`/api/facility/all`, { ignoreAlertModal: true })
        .then((data) => {
          if (data.facilityList) {
            const facilityList = data.facilityList;
            facilityList.forEach((facility) => {
              facility.inFacilityList.forEach((inFacility) => {
                facility.cropCode = inFacility.cropCode;
                facility.dongType = inFacility.kindCode;
              });
              for (let i = 0; i < cropCodeList.length; i++) {
                if (facility.cropCode === cropCodeList[i].code) {
                  facility.cropName = cropCodeList[i].koName;
                }
              }
              facility.dongSize = facility.inFacilityList.length;
              facility.value = facility.id;
              facility.label = facility.name;
            });
            resolve(facilityList);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }
}

export default new FacilityService();
