





































































































































































import DiseaseService from "@/services/disease/disease.service";
import mixins from "vue-typed-mixins";
import core from "@/core";
import UpdateEvent from "@/models";
import { UpdateEventType } from "@/types";

export default mixins().extend({
  name: "DiseaseAreaGalleryComponent",
  props: {
    properties: {
      type: Object,
      default: () => {
        return {
          areaText: "전국",
          searchColumns: {
            status: "DISEASE",
          },
          updateEvent: null as UpdateEvent | null,
        };
      },
    },
  },
  data() {
    return {
      isMobile: false,
      ready: false,
      imageWidth: 250,
      view: {
        leftScrollButton: false,
        rightScrollButton: false,
      },
      updateViewType: null as string | null,
      table: {
        event: {
          reloadFlag: false,
          nextLoadFlag: false,
        },
        request: {
          draw: 0,
          start: 0,
          length: 6,
          orderColumnName: "id",
          order: "desc",
          searchColumns: {},
        },
        response: {
          draw: 0,
          recordsFiltered: 0,
          recordsTotal: 0,
          data: [] as any,
        },
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.ready = true;
      this.table.event.reloadFlag = true;
      const platform = core.utils.platform();
      if (platform === "android" || platform === "ios") {
        this.isMobile = true;
      }
      this.resizeEvent();
      window.addEventListener("resize", this.resizeEvent);
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeEvent);
  },
  watch: {
    "properties.searchColumns"(searchColumns) {
      // console.log("changed searchColumns : ", searchColumns);
      this.reloadTable();
    },
    "table.event.reloadFlag"(reloadFlag) {
      if (reloadFlag) {
        this.table.event.reloadFlag = false;
        if (this.ready) {
          const searchColumns = this.properties.searchColumns;
          if (searchColumns != null) {
            this.getTableList(searchColumns);
          }
        }
      }
    },
    "table.event.nextLoadFlag"() {
      if (this.table.event.nextLoadFlag) {
        if (this.ready) {
          const searchColumns = this.properties.searchColumns;
          if (searchColumns != null) {
            this.getTableList(searchColumns);
          }
        }
      }
    },
  },
  updated() {
    const updateViewType = this.updateViewType;
    this.updateViewType = null;
    if (updateViewType === "table") {
      this.onScroll();
    }
  },
  methods: {
    selectImage(item) {
      //console.log("select : ", item);
      this.properties.updateEvent = new UpdateEvent(UpdateEventType.CONFIRM, "", item);
    },
    getThumbImageUrl(item) {
      if (this.properties.searchColumns.status === "DISEASE") {
        return item.thumbAnalysisImageUrl;
      }
      return item.thumbImageUrl;
    },
    getImageUrl(item) {
      if (this.properties.searchColumns.status === "DISEASE") {
        return item.analysisImageUrl;
      }
      return item.imageUrl;
    },
    resizeEvent() {
      const el = this.$refs.body as any;
      const parentEl = el.parentElement;
      el.style.width = parentEl.clientWidth - 40 + "px";
    },
    getAddress(item) {
      if (item.sidoName != null && item.sggName != null) {
        return item.sidoName + " " + item.sggName;
      }
      return "지역 정보 없음";
    },
    getImageTitle($el) {
      const id = parseInt($el.alt);
      let item = null as any;
      this.table.response.data.some((_item: any) => {
        if (_item.id === id) {
          item = _item;
          return true;
        }
      });
      if (item != null) {
        if (item.analysisResultList) {
          let html =
            "<div class='toggle-btn'><i class='fa fa-info' aria-hidden='true'></i> 분석정보확인</div><div class='viewer-title-content'>";
          for (let i = 0; i < item.analysisResultList.length; i++) {
            const analysisResult = item.analysisResultList[i];
            html += `<p><a href="${this.getAnalysisInfoUrl(analysisResult)}" target="_blank">${
              i + 1
            }. ${analysisResult.name}${
              analysisResult.numberOfDetections ? "(" + analysisResult.numberOfDetections + ")" : ""
            }</a></p>`;
          }
          html += "</div>";

          const $rootEl = $el.parentElement.parentElement;
          const $titleEl = $rootEl.querySelector(".viewer-title");
          $titleEl.classList.remove("active");
          setTimeout(() => {
            $titleEl.innerHTML = html;
            $rootEl.querySelector(".toggle-btn").addEventListener("click", () => {
              console.log("click");
              if ($titleEl.classList.contains("active")) {
                $titleEl.classList.remove("active");
              } else {
                $titleEl.classList.add("active");
              }
            });
          }, 50);
          // console.log(text);
          // return text;
          return "";
        }
      } else {
        return "";
      }
    },
    getDate(at) {
      if (core.utils.validate.isBlank(at)) {
        return "";
      }
      return core.date.instance(at).format("YYYY년 MM월 DD일(ddd)");
    },
    getDiseaseName(item) {
      if (item.analysisResultList != null) {
        if (item.analysisResultList.length > 0) {
          return item.analysisResultList[0].name;
        }
      } else if (item.annotations != null) {
        if (item.annotations.length > 0) {
          return item.annotations[0].name;
        }
      }
      return "판독필요";
    },
    onScroll() {
      const element = this.$refs["gallery"] as any;
      const $el = element.$el;
      const scrollWidth = $el.scrollWidth;
      const clientWidth = $el.clientWidth;
      const width = scrollWidth - clientWidth;
      const scrollLeft = Math.ceil($el.scrollLeft);

      // console.log(`width : ${width}, scrollLeft : ${scrollLeft}`);

      // console.log("horizontal : ", horizontal);
      if (scrollLeft === 0) {
        this.view.leftScrollButton = false;
        if (width === 0) {
          this.view.rightScrollButton = false;
        } else {
          this.view.rightScrollButton = true;
        }
      } else if (scrollLeft === width) {
        this.view.leftScrollButton = true;
        this.view.rightScrollButton = false;
        const table = this.table;
        if (!table.event.nextLoadFlag && table.request.start < table.response.recordsTotal) {
          this.table.event.nextLoadFlag = true;
        }

        // 테이블 정보 요청
      } else {
        this.view.leftScrollButton = true;
        this.view.rightScrollButton = true;
      }
    },
    moveGallery(direction) {
      const element = this.$refs["gallery"] as any;
      const $el = element.$el;
      const scrollWidth = $el.scrollWidth;
      const clientWidth = $el.clientWidth;
      const width = scrollWidth - clientWidth;
      const scrollLeft = $el.scrollLeft;
      const imageWidth = this.imageWidth + 20;

      const imageCount = Math.ceil(scrollLeft / imageWidth) + (direction === "right" ? 1 : -1);
      //console.log("imageCount : ", imageCount);

      let x = imageCount * imageWidth;
      if (x < 0) x = 0;
      else if (x > width) x = width;
      $el.scrollLeft = x;
    },

    getAnalysisInfoUrl(analysisResult) {
      return DiseaseService.getInfoUrl(analysisResult.name, analysisResult.similarity);
    },

    reloadTable() {
      const table = this.table;
      table.event.reloadFlag = true;
      table.request.start = 0;
      table.response.data = [];
      table.response.recordsFiltered = 0;
      table.response.recordsTotal = 0;
      table.response.draw = 0;
      const element = this.$refs["gallery"] as any;
      const $el = element.$el;
      $el.scrollLeft = 0;
    },

    async getTableList(searchColumns) {
      core.loader.show("데이터 요청중...");
      const params = this.table.request;
      params.searchColumns = searchColumns;

      const response = (await DiseaseService.getTable(params)) as any;
      response.data.forEach((data) => {
        this.table.response.data.push(data);
      });
      this.table.response.recordsFiltered = response.recordsFiltered;
      this.table.response.recordsTotal = response.recordsTotal;
      this.table.response.draw = response.draw;

      this.table.request.start = this.table.response.data.length;
      this.updateViewType = "table";
      this.table.event.nextLoadFlag = false;

      core.loader.hide();
    },
  },
});
