

































































import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import UpdateEvent from "@/models";
import SelectFileModal from "@/modals/SelectFileModal.vue";
import { localize } from "vee-validate";
import Constant from "@/store/constant";
import OwncloudService from "@/services/owncloud/owncloud.service";
import _ from "lodash";
import OpenDataService from "@/services/opendata/open-data.service";

localize("ko", {
  messages: Constant.validate.language.korea.messages,
  names: {
    title: "제목 ",
    content: "내용 ",
  },
});
export default mixins(MixinsPageForm).extend({
  name: "DataManagementAdd",
  components: { SelectFileModal },
  data: () => ({
    fileList: [] as any,
    modal: {
      selectFile: {
        visible: false,
        updateEvent: null as UpdateEvent | null,
      },
    },
    processing: false,
    form: {
      title: "",
      content: "",
    },
  }),
  mounted() {
    this.$nextTick(async () => {});
  },
  watch: {
    "modal.selectFile.updateEvent"(event) {
      //console.log("event : ", event);
      if (event != null) {
        const item = event.item;
        if (item != null) {
          const existsFileList = [] as any;
          const newFileList = [] as any;
          item.forEach((item) => {
            let exists = false;
            this.fileList.some((file) => {
              if (file.filename === item.filename) {
                exists = true;
                existsFileList.push(file);
                return true;
              }
            });
            if (!exists) {
              newFileList.push(item);
            }
          });
          newFileList.forEach((newFile) => {
            this.fileList.push(newFile);
          });
        }
      }
    },
  },
  destroyed() {
    console.log("destroyed");
  },
  deactivated() {
    console.log("deactivated");
  },
  methods: {
    addFiles() {
      const modal = this.modal.selectFile;
      modal.visible = true;
    },
    removeFile(item) {
      const fileList = this.fileList;
      for (let i = 0; i < fileList.length; i++) {
        const file = fileList[i];
        if (file.filename === item.filename) {
          fileList.splice(i, 1);
        }
      }
    },
    async submit() {
      if (await this.validate()) {
        this.processing = true;

        const params = _.cloneDeep(this.form) as any;
        params.fileList = [] as any;

        // 파일공개 링크 추가
        const fileList = this.fileList;
        if (fileList.length > 0) {
          for (let i = 0; i < fileList.length; i++) {
            const file = fileList[i] as any;
            //console.log("file : ", file.filename);
            const publicLink = await OwncloudService.getPublicLink(file.filename);
            //console.log("publicLink : ", publicLink);
            params.fileList.push({
              filename: file.basename,
              link: publicLink,
              size: file.size,
            });
          }
        }
        //console.log("params : ", params);

        try {
          const result = await OpenDataService.create(params);
          console.log("result : ", result);
          this.$router.back();
        } catch (e) {
          console.log("error : ", e);
        }

        this.processing = false;
      }
    },
  },
});
