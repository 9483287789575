





















































































































































































































import mixins from "vue-typed-mixins";
import MixinsComponent from "@/mixins/component";
import GreenHouseService from "@/services/greenhouse/green-house.service";
import store from "@/store";
import { cloneDeep } from "lodash";

export default mixins(MixinsComponent).extend({
  name: "GreenHouseTotalStatusComponent",
  props: {
    properties: {
      type: Object,
      default: () => {
        return {
          itemList: [
            {
              text: "전체",
              color: "blue",
              dark: true,
              active: false,
              devId: 0,
              data: null as any,
            },
            {
              text: "온실1",
              color: "blue",
              dark: true,
              active: false,
              devId: 1,
              data: null as any,
            },
            {
              text: "온실2",
              color: "blue",
              dark: true,
              active: false,
              devId: 2,
              data: null as any,
            },
            {
              text: "온실3",
              color: "blue",
              dark: true,
              active: false,
              devId: 3,
              data: null as any,
            },
            {
              text: "온실4",
              color: "blue",
              dark: true,
              active: false,
              devId: 4,
              data: null as any,
            },
            {
              text: "온실5",
              color: "blue",
              dark: true,
              active: false,
              devId: 5,
              data: null as any,
            },
          ],
          selectedButtonItem: null as any,
          params: {
            devId: 0,
            updated: false,
          },
        };
      },
    },
  },
  data: () => ({
    app: store.state.app,
    statusText: "",
    displayData: {
      inTemp: null as any,
      inHumidity: null as any,
      inCo2: null as any,
      inPowerUsage: null as any,
      inNutrientSupply: null as any,
      dewPointTemp: null as any,
      outTemp: null as any,
      outWindSpeed: null as any,
      outWindDirection: null as any,
      solarRadiation: null as any,

      control: {
        light: false,
        pump: false,
        fan: false,
        air: false,
        bo: false,
        fog: false,
      },
      inEnvList: [] as any,
    },
    rowHeight: 350,
    ready: false,
  }),
  mounted() {
    this.$nextTick(() => {
      const dataList = [] as any;
      this.properties.itemList.forEach((item) => {
        if (item.data != null) {
          dataList.push(item.data);
        }
      });
      if (dataList.length > 0) {
        this.updateData(dataList);
      }
    });
  },
  watch: {
    "app.size"(size) {
      this.resizeEvent();
    },
    "properties.params.updated"(updated) {
      //console.log("updated : ", updated);
      this.properties.params.updated = false;
      if (updated) {
        const dataList = [] as any;
        this.properties.itemList.forEach((item) => {
          if (item.data != null) {
            dataList.push(item.data);
          }
        });
        if (dataList.length > 0) {
          this.updateData(dataList);
        }
      }
    },
  },
  methods: {
    async updateData(dataList) {
      //console.log("dataList : ", dataList);

      const displayData = cloneDeep(this.displayData);
      displayData.inEnvList = [] as any;
      for (const key of Object.keys(displayData)) {
        if (key !== "control" && key !== "inEnvList") {
          displayData[key] = 0;
        }
      }

      dataList.forEach((data) => {
        displayData.inEnvList.push(data.display);

        for (const key of Object.keys(displayData)) {
          if (key !== "control" && key !== "inEnvList") {
            displayData[key] += data.display[key];
          }
        }
      });

      for (const key of Object.keys(displayData)) {
        if (key !== "control" && key !== "inEnvList") {
          displayData[key] = (displayData[key] / 5).toFixed(1);
        }
      }
      this.displayData = displayData;

      try {
        const weatherResult = (await GreenHouseService.getWeather()) as any;
        const xml = weatherResult.content;
        const parser = new DOMParser();
        const dom = parser.parseFromString(xml, "text/xml");
        const cc = dom.getElementsByTagName("wfSv1");
        if (cc.length > 0) {
          const content = cc[0].innerHTML;
          this.statusText = content
            .split("□")[1]
            .split("(종합) ")[1]
            .split("○")[0]
            .replace("\r\n", "");

          //console.log("content : ", content);
        }
      } catch (e) {
        console.log(e);
      }

      if (!this.ready) {
        this.ready = true;
        const loader = this.$refs.loader as HTMLElement;
        loader.style.display = "none";
      }
      this.resizeEvent();
    },
    resizeEvent() {
      const maxHeight = this.app.size.height - 185;
      const wrapEl = this.$refs.wrap as HTMLElement;
      {
        const elList = wrapEl.querySelectorAll(".card-row");
        let elHeight = 0;
        elList.forEach((el) => {
          elHeight += el.clientHeight;
        });
        //console.log("elHeight : ", elHeight);
        let rowHeight = maxHeight - elHeight;
        if (this.app.size.width < 960) {
          rowHeight = 0;
        } else {
          if (rowHeight < 0) {
            rowHeight = 0;
          } else if (rowHeight < 180) {
            rowHeight = 180;
          }
        }
        console.log("rowHeight : ", rowHeight);
        this.rowHeight = rowHeight;
      }
    },
  },
});
