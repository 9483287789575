import qs from "qs";
import core from "@/core";
import { UserModel } from "@/models/user/user.model";

class UserService {
  register(params) {
    const strParam = JSON.stringify(params);
    return new Promise((resolve, reject) => {
      core.http
        .postJson(`/api/user/register`, strParam)
        .then((data) => {
          //console.log('result: ', data);
          resolve(data);
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  login(params) {
    const strParam = qs.stringify(params);
    return new Promise((resolve, reject) => {
      core.http
        .post(`/api/login`, strParam)
        .then((data) => {
          //console.log('result: ', data);
          resolve(data);
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  logout() {
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`/api/logout`)
        .then((data) => {
          // console.log('result: ', data);
          resolve(data);
        })
        .catch((reason) => {
          // console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  getMyInfo(): Promise<UserModel> {
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`/api/user/me`, { ignoreAlertModal: true })
        .then((data: any) => {
          //console.log('result: ', data);
          if (data.user != null) {
            resolve(data.user as UserModel);
          } else {
            reject({ message: "not found item" });
          }
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  updatePassword(currentPassword: string, newPassword: string, newConfirmPassword: string) {
    const strParam = JSON.stringify({
      currentPassword: currentPassword,
      newPassword: newPassword,
      newConfirmPassword: newConfirmPassword,
    });
    return new Promise((resolve: any, reject) => {
      core.http
        .putJson(`/api/user/me/password`, strParam)
        .then((data) => {
          //console.log('result: ', data);
          resolve(data);
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  getTable(params) {
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`/api/user/table`, null, params)
        .then((data) => {
          resolve(data);
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  create(params) {
    return new Promise((resolve, reject) => {
      const strParam = JSON.stringify(params);
      core.http
        .postJson(`/api/user`, strParam)
        .then((data) => {
          resolve(data);
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  update(id, params) {
    return new Promise((resolve, reject) => {
      const strParam = JSON.stringify(params);
      core.http
        .putJson(`/api/user/${id}`, strParam)
        .then((data) => {
          resolve(data);
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  updateMyInfo(params) {
    return new Promise((resolve, reject) => {
      const strParam = JSON.stringify(params);
      core.http
        .putJson(`/api/user/me`, strParam)
        .then((data) => {
          resolve(data);
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  delete(id) {
    return new Promise((resolve, reject) => {
      core.http
        .delete(`/api/user/${id}`)
        .then((data) => {
          resolve(data);
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }
}

export default new UserService();
