class Config {
  componentName = "vue-page-stack";
  keyName = "stack-key";
  pushName = "push";
  goName = "go";
  replaceName = "replace";
  backName = "back";
  forwardName = "forward";
}

export default new Config();
