
























































import mixins from "vue-typed-mixins";
import MixinsComponent from "@/mixins/component";
import OwncloudService from "@/services/owncloud/owncloud.service";
import core from "@/core";
import UpdateEvent from "@/models";
import { UpdateEventType } from "@/types";

export default mixins(MixinsComponent).extend({
  name: "SelectFileComponent",
  data: () => ({
    loading: true,
    disk: {
      isRootPath: false,
      path: "",
      folderList: [] as any,
      fileList: [] as any,
      selectedFileList: [] as any,
    },
  }),
  computed: {},
  mounted() {
    this.$nextTick(async () => {
      console.log("mounted");
      await this.getDataList("/");
    });
  },
  methods: {
    bytesToHuman(bytes) {
      const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes === 0) return "0 Bytes";
      const i = parseInt(String(Math.floor(Math.log(bytes) / Math.log(1024))), 10);
      if (i === 0) return `${bytes} ${sizes[i]}`;
      return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
    },
    date(date) {
      return core.date.instance(date).toDate().toLocaleString("ko");
    },
    selectFile(file) {
      console.log("select file : ", file);
      if (file.type === "file") {
        file.selected = !file.selected;
        if (file.selected) {
          this.disk.selectedFileList.push(file);
          this.properties.updateEvent = new UpdateEvent(
            UpdateEventType.CONFIRM,
            "",
            this.disk.selectedFileList
          );
        } else {
          const fileList = this.disk.selectedFileList;
          for (let i = 0; i < fileList.length; i++) {
            const _file = fileList[i];
            if (_file.filename === file.filename) {
              fileList.splice(i, 1);
              break;
            }
          }
          this.properties.updateEvent = new UpdateEvent(
            UpdateEventType.CONFIRM,
            "",
            this.disk.selectedFileList
          );
        }
      }
    },
    async selectAction(file) {
      console.log("action file : ", file);
      if (file.type === "directory") {
        await this.getDataList(file.filename);
      }
    },
    levelUp() {
      // if this a not root directory
      if (this.disk.path) {
        // calculate up directory path
        const pathUp = this.disk.path.split("/").slice(0, -1).join("/");
        this.getDataList(pathUp);
      }
    },
    async getDataList(path) {
      this.loading = true;
      const disk = this.disk;

      disk.selectedFileList = [];
      this.properties.updateEvent = new UpdateEvent(
        UpdateEventType.CONFIRM,
        "",
        this.disk.selectedFileList
      );

      try {
        const list = (await OwncloudService.getDirectoryContents(path)) as any;
        //console.log("list : ", list);

        const folderList = [] as any;
        const fileList = [] as any;

        list.forEach((file) => {
          if (file.type === "directory") {
            folderList.push(file);
          } else {
            fileList.push(file);
          }
          file.selected = false;
        });

        disk.path = path;
        disk.folderList = folderList;
        disk.fileList = fileList;

        disk.isRootPath = path.length === 0 || path === "/";

        console.log("diskPath : ", disk.path, ", rootPath : ", disk.isRootPath);
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    changedFileList(list) {
      const folderList = [] as any;
      const fileList = [] as any;

      list.forEach((file) => {
        if (file.type === "directory") {
          folderList.push(file);
        } else {
          fileList.push(file);
        }
        file.selected = false;
      });
      return { folderList: folderList, fileList: fileList };
    },
    confirm() {
      this.properties.updateEvent = new UpdateEvent(
        UpdateEventType.CONFIRM,
        "",
        this.disk.selectedFileList
      );
    },
  },
});
