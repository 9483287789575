import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import NotSupportBrowser from "@/views/errors/NotSupportBrowser.vue";
import NotFound from "@/views/errors/NotFound.vue";
import Home from "@/views/Home.vue";
import Logout from "@/views/Logout.vue";
import core from "@/core";
import store from "@/store";
import Login from "@/views/Login.vue";
import Constant from "@/store/constant";
import Register from "@/views/Register.vue";
import DigitalAgriculture from "@/views/DigitalAgriculture.vue";
import Disease from "@/views/Disease.vue";
import DataManagement from "@/views/DataManagement.vue";
import DataManagementAdd from "@/views/data-management/Add.vue";
import DataManagementEdit from "@/views/data-management/Edit.vue";
import DataManagementView from "@/views/data-management/View.vue";
import DataStatus from "@/views/DataStatus.vue";
import Users from "@/views/Users.vue";
import Sitemap from "@/views/Sitemap.vue";
import UserMe from "@/views/user/Me.vue";
import UserPassword from "@/views/user/Password.vue";
import Cloud from "@/views/Cloud.vue";
import DiseaseManager from "@/views/DiseaseManager.vue";
import UserMeInfo from "@/views/user/UserMeInfo.vue";
import GrowthManagement from "@/views/GrowthManagement.vue";
import GrowthManagementAdd from "@/views/growth-management/Add.vue";
import GrowthManagementEdit from "@/views/growth-management/Edit.vue";


Vue.use(VueRouter);

const appName = Constant.appName;

export enum MenuType {
  NAVIGATION = "navigation",
  NONE = "none",
}

export interface SubMenu {
  text: SubTextMenu | null | undefined;
  icon: SubIconMenu | null | undefined;
}

export interface SubIconMenu {
  visible: boolean;
  list: Menu[] | null;
}

export interface SubTextMenu {
  visible: boolean;
  width: number;
  list: Menu[] | null;
}

export interface Menu {
  icon: string;
  text: string;
  id: string;
  visible: boolean;
  event: boolean;
}

export interface IRouteMeta {
  title: string;
  roles: string[] | null;
  companyOptions: string[] | null;
  menuType: MenuType;
  pageStack: boolean;
}

export class RouteMeta implements IRouteMeta {
  title: string;
  roles: string[] | null;
  companyOptions: string[] | null;
  menuType: MenuType;
  pageStack: boolean;

  constructor(params: IRouteMeta) {
    this.title = params.title;
    this.roles = params.roles;
    this.companyOptions = params.companyOptions;
    this.menuType = params.menuType;
    this.pageStack = params.pageStack;
  }
}

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: "/home",
    meta: new RouteMeta({
      title: "",
      roles: ["manager"],
      companyOptions: null,
      menuType: MenuType.NONE,
      pageStack: false,
    }),
  },
  {
    path: "/register",
    name: "register",
    component: Register,
    meta: new RouteMeta({
      title: "회원가입",
      roles: null,
      companyOptions: null,
      menuType: MenuType.NONE,
      pageStack: false,
    }),
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: new RouteMeta({
      title: "로그인",
      roles: null,
      companyOptions: null,
      menuType: MenuType.NONE,
      pageStack: false,
    }),
  },
  {
    path: "/logout",
    name: "logout",
    component: Logout,
    meta: new RouteMeta({
      title: "로그아웃",
      roles: ["manager"],
      companyOptions: null,
      menuType: MenuType.NONE,
      pageStack: false,
    }),
  },
  {
    path: "/error/not-support-browser",
    name: "notSupportBrowser",
    component: NotSupportBrowser,
    meta: new RouteMeta({
      title: "",
      roles: null,
      companyOptions: null,
      menuType: MenuType.NONE,
      pageStack: false,
    }),
  },
  {
    path: "*",
    name: "notFound",
    component: NotFound,
    meta: new RouteMeta({
      title: "",
      roles: null,
      companyOptions: null,
      menuType: MenuType.NONE,
      pageStack: false,
    }),
  },
  {
    path: "/home",
    name: "home",
    component: Home,
    meta: new RouteMeta({
      title: "Home",
      roles: ["manager"],
      companyOptions: null,
      menuType: MenuType.NAVIGATION,
      pageStack: false,
    }),
  },
  {
    path: "/digital-agriculture",
    redirect: "/digital-agriculture/green-house/status",
    meta: new RouteMeta({
      title: "디지털농업",
      roles: ["manager"],
      companyOptions: null,
      menuType: MenuType.NAVIGATION,
      pageStack: false,
    }),
  },
  {
    path: "/digital-agriculture/:path(green-house)/:subPath(status|monitoring)",
    name: "digitalAgricultureSubPath",
    component: DigitalAgriculture,
    meta: new RouteMeta({
      title: "디지털농업",
      roles: ["manager"],
      companyOptions: null,
      menuType: MenuType.NAVIGATION,
      pageStack: false,
    }),
  },
  {
    path: "/digital-agriculture/:path(smartfarm2nd|aqua|electrical)",
    name: "digitalAgriculturePath",
    component: DigitalAgriculture,
    meta: new RouteMeta({
      title: "디지털농업",
      roles: ["manager"],
      companyOptions: null,
      menuType: MenuType.NAVIGATION,
      pageStack: false,
    }),
  },
  {
    path: "/disease",
    redirect: "/disease/tomato",
    meta: new RouteMeta({
      title: "병해진단",
      roles: ["manager"],
      companyOptions: null,
      menuType: MenuType.NAVIGATION,
      pageStack: false,
    }),
  },
  {
    path: "/disease/:path(tomato|strawberry|paprika|analysis-request)",
    name: "diseasePath",
    component: Disease,
    meta: new RouteMeta({
      title: "병해진단",
      roles: ["manager"],
      companyOptions: null,
      menuType: MenuType.NAVIGATION,
      pageStack: false,
    }),
  },
  {
    path: "/disease/:path(analysis-request)/:subPath(add|edit|\\d+)",
    name: "diseasePathSubPath",
    component: Disease,
    meta: new RouteMeta({
      title: "병해진단",
      roles: ["manager"],
      companyOptions: null,
      menuType: MenuType.NAVIGATION,
      pageStack: false,
    }),
  },
  {
    path: "/disease-manager",
    redirect: "/disease-manager/tomato",
    meta: new RouteMeta({
      title: "병해진단전문가",
      roles: ["manager"],
      companyOptions: null,
      menuType: MenuType.NAVIGATION,
      pageStack: false,
    }),
  },
  {
    path: "/growth-management",
    name: "growthManagement",
    component: GrowthManagement,
    meta: new RouteMeta({
      title: "생육관리",
      roles: ["manager"],
      companyOptions: null,
      menuType: MenuType.NAVIGATION,
      pageStack: false,
    }),
  },
  // {
  //   path: "/growth-management/add",
  //   name: "growthManagementAdd",
  //   component: GrowthManagementAdd,
  //   meta: new RouteMeta({
  //     title: "생육관리 - 추가",
  //     roles: ["manager"],
  //     companyOptions: null,
  //     menuType: MenuType.NAVIGATION,
  //     pageStack: false,
  //   }),
  // },
  // {
  //   path: "/growth-management/edit",
  //   name: "growthManagementEdit",
  //   component: GrowthManagementEdit,
  //   meta: new RouteMeta({
  //     title: "생육관리 - 수정",
  //     roles: ["manager"],
  //     companyOptions: null,
  //     menuType: MenuType.NAVIGATION,
  //     pageStack: false,
  //   }),
  // },
  {
    path: "/disease-manager/:path(tomato|strawberry|paprika|info)",
    name: "diseaseManagerPath",
    component: DiseaseManager,
    meta: new RouteMeta({
      title: "병해진단전문가",
      roles: ["manager"],
      companyOptions: null,
      menuType: MenuType.NAVIGATION,
      pageStack: false,
    }),
  },
  {
    path: "/disease-manager/:path(info)/:subPath(add|edit|\\d+)",
    name: "diseaseManagerInfoSubPath",
    component: DiseaseManager,
    meta: new RouteMeta({
      title: "병해진단전문가",
      roles: ["manager"],
      companyOptions: null,
      menuType: MenuType.NAVIGATION,
      pageStack: false,
    }),
  },
  {
    path: "/data-status/:path(green-house|smartfarm2nd|disease|aqua|milking|file-manager|env-control-status|env-power)",
    name: "dataStatus",
    component: DataStatus,
    meta: new RouteMeta({
      title: "데이터현황",
      roles: ["manager"],
      companyOptions: null,
      menuType: MenuType.NAVIGATION,
      pageStack: false,
    }),
  },
  {
    path: "/data-management",
    name: "dataManagement",
    component: DataManagement,
    meta: new RouteMeta({
      title: "데이터관리",
      roles: ["manager"],
      companyOptions: null,
      menuType: MenuType.NAVIGATION,
      pageStack: false,
    }),
  },
  {
    path: "/data-management/add",
    name: "dataManagementAdd",
    component: DataManagementAdd,
    meta: new RouteMeta({
      title: "데이터관리 - 추가",
      roles: ["manager"],
      companyOptions: null,
      menuType: MenuType.NAVIGATION,
      pageStack: false,
    }),
  },
  {
    path: "/data-management/edit",
    name: "dataManagementEdit",
    component: DataManagementEdit,
    meta: new RouteMeta({
      title: "데이터관리 - 수정",
      roles: ["manager"],
      companyOptions: null,
      menuType: MenuType.NAVIGATION,
      pageStack: false,
    }),
  },
  {
    path: "/data-management/:id(\\d+)",
    name: "dataManagementView",
    component: DataManagementView,
    meta: new RouteMeta({
      title: "데이터관리",
      roles: ["manager"],
      companyOptions: null,
      menuType: MenuType.NAVIGATION,
      pageStack: false,
    }),
  },
  {
    path: "/users",
    name: "users",
    component: Users,
    meta: new RouteMeta({
      title: "사용자관리",
      roles: ["manager"],
      companyOptions: null,
      menuType: MenuType.NAVIGATION,
      pageStack: false,
    }),
  },
  {
    path: "/user/me",
    name: "userMe",
    component: UserMe,
    meta: new RouteMeta({
      title: "내 정보",
      roles: ["manager"],
      companyOptions: null,
      menuType: MenuType.NAVIGATION,
      pageStack: false,
    }),
  },
  {
    path: "/user/me/info",
    name: "userMeInfo",
    component: UserMeInfo,
    meta: new RouteMeta({
      title: "회원정보 수정",
      roles: ["manager"],
      companyOptions: null,
      menuType: MenuType.NAVIGATION,
      pageStack: false,
    }),
  },
  {
    path: "/user/me/password",
    name: "userPassword",
    component: UserPassword,
    meta: new RouteMeta({
      title: "비밀번호 변경",
      roles: ["manager"],
      companyOptions: null,
      menuType: MenuType.NAVIGATION,
      pageStack: false,
    }),
  },
  {
    path: "/cloud",
    name: "cloud",
    component: Cloud,
    meta: new RouteMeta({
      title: "파일관리",
      roles: ["manager"],
      companyOptions: null,
      menuType: MenuType.NAVIGATION,
      pageStack: false,
    }),
  },
  {
    path: "/sitemap",
    name: "sitemap",
    component: Sitemap,
    meta: new RouteMeta({
      title: "사이트맵",
      roles: ["manager"],
      companyOptions: null,
      menuType: MenuType.NAVIGATION,
      pageStack: false,
    }),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const routeMap = new Map();
routes.forEach((data) => {
  if (data.children != null) {
    const path = data.path;
    data.children.forEach((data) => {
      routeMap.set(path + data.path, data);
    });
  } else {
    routeMap.set(data.path, data);
  }
});

function routerResult(next, path: string) {
  next({ path: path });
  const route = routeMap.get(path);
  return route.meta;
}

async function beforeEach(to, from, next) {
  const state = store.state;
  if (state.auth.user) {
    // 로그인 중인 사용자
    if (to.meta.roles != null) {
      // 로그인 사용자 접근 가능 페이지일 경우 이동
      next();
    } else {
      // 비로그인 사용자 접근 가능 페이지일 경우 이동
      if (to.path.toLowerCase() === "/login") {
        if (core.utils.validate.isBlank(to.query.v)) {
          // 로그인중인 사용자가 로그인 페이지 접근시 이동
          return routerResult(next, "/");
        } else {
          // 로그인중인 사용자가 자동인증 정보파라미터를 포함해서 들어왔을 경우 로그인 페이지로 이동
          next();
        }
      }
      // else if (to.path.toLowerCase() === "/home"){
      //
      //   next();
      // }
      else {
        next();
      }
    }
  } else {
    // 비로그인 중인 사용자 세션 확인
    const user = await store.getters["auth/user"]();
    if (user != null) {
      // 세션이 존재하면 로그인중인 사용자로 변경되어 재호출 처리
      return await beforeEach(to, from, next);
    } else {
      if (to.meta.roles != null) {
        // 로그인 사용자 접근 가능 페이지일 경우 메인 페이지로 이동
        return routerResult(next, "/login");
      } else {
        next();
      }
    }
  }

  return to.meta;
}

router.beforeEach(async (to, from, next) => {
  // console.log("from   : ", from);
  // console.log("to     : ", to);

  if (store.state.app.printClass) {
    await store.dispatch("app/clearPrint");
  }

  if (
    (from.path === "/home" && to.path === "/login") ||
    (from.path === "/login" && to.path === "/login")
  ) {
    window.history.back();
    return;
  }

  // console.log("from " + from.path + " -> to : " + to.path);
  const meta = await beforeEach(to, from, next);
  //console.log("meta : ", meta);
  if (meta != null && meta.title != null) {
    if (meta.title.length > 0) {
      document.title = `${appName} - ${meta.title}`;
    } else {
      document.title = appName;
    }
  }

  if (meta != null && meta.menuType !== MenuType.NONE) {
    await store.dispatch("topToolbar/changeTitle", meta.title);
  }
});

export default router;
