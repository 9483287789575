




























import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import store from "@/store";
import { SubMenu } from "@/router";

export default mixins(MixinsPageForm).extend({
  name: "UserMe",
  components: {},
  data: () => ({
    app: store.state.app,
    iconSubMenu: { edit: null as SubMenu | null },
  }),
  mounted() {
    this.$nextTick(() => {});
  },
});
