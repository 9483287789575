






import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import core from "@/core";
import DataStatusUtils from "@/core/data-status-utils";

import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { cloneDeep } from "lodash";

export default mixins(MixinsPageForm).extend({
  name: "Home",
  components: {},
  data: () => ({
    chartData: {
      // // 첨단스마트온실
      // highTechDigitalGreenHouse: {
      //   name: "첨단스마트온실",
      //   dataCount: 5,
      //   imageCount: 0,
      //   tooltipText: "온도, 습도, CO2, 전력사용량, 양액 공급량",
      //   path: "/data-status/green-house",
      // },
      // // 2세대 스마트팜
      // smartfarm2nd: {
      //   name: "2세대 스마트팜",
      //   dataCount: 21,
      //   imageCount: 0,
      //   tooltipText:
      //     "온도, 습도, CO2, 일사량, 근권부 온도, 근권부 습도, 배액 EC, 배액 PH, 양액 EC, 양액 PH, 양액 공급유량, 양액 공급성분농도",
      //   path: "/data-status/smartfarm2nd",
      // },
      // disease: {
      //   name: "병해진단",
      //   dataCount: 0,
      //   imageCount: 2,
      //   tooltipText: "토마토, 딸기",
      //   path: "/data-status/disease",
      // },
      // aqua: {
      //   name: "아쿠아포닉스",
      //   dataCount: 6,
      //   imageCount: 0,
      //   tooltipText: "온도, 습도, PH, EC, DO, ORP",
      //   path: "/data-status/aqua",
      // },
    } as any,
  }),
  mounted() {
    //console.log("chartData : ", this.chartData);
    this.$nextTick(async () => {
      core.loader.show();

      await DataStatusUtils.reload();

      for (const key of Object.keys(DataStatusUtils.items)) {
        this.chartData[key] = cloneDeep(DataStatusUtils.items[key]);
      }

      const createMainChart = this.createMainChart;
      setTimeout(() => {
        createMainChart();
        core.loader.hide();
      }, 10);
    });
  },
  methods: {
    createMainChart() {
      console.log("createMainChart");
      const $el = this.$refs.mainChart as HTMLElement;
      am4core.useTheme(am4themes_animated);
      const chart = am4core.create($el, am4charts.TreeMap);

      chart.hiddenState.properties.opacity = 0; // this makes initial fade in effect

      const chartData = [] as any;
      for (const key of Object.keys(this.chartData)) {
        //console.log("key : ", key);
        const item = this.chartData[key];
        //console.log("item : ", item);
        item.count = item.imageCount + item.dataCount;
        chartData.push({
          name: item.name,
          dataSize: this.getDataCountLabelTextFormat(item),
          value: item.count + 1,
          url: item.path,
          tooltipText: item.tooltipText,
        });
      }

      chart.data = chartData;
      chart.colors.step = 2;

      // define data fields
      const dataFields = chart.dataFields as any;
      dataFields.value = "value";
      dataFields.name = "name";
      dataFields.dataSize = "dataSize";
      dataFields.url = "url";
      // dataFields.nameFontSize = "nameFontSize";
      // dataFields.dataFontSize = "dataFontSize";
      dataFields.children = "children";
      dataFields.color = "color";
      chart.layoutAlgorithm = chart.binaryTree;

      chart.zoomable = false;

      // level 0 series template
      const level0SeriesTemplate = chart.seriesTemplates.create("0");
      const level0ColumnTemplate = level0SeriesTemplate.columns.template;

      level0SeriesTemplate.strokeWidth = 2;
      level0ColumnTemplate.column.cornerRadius(5, 5, 5, 5);
      level0ColumnTemplate.tooltipText = "[bold]{tooltipText}";

      // by default only current level series bullets are visible, but as we need brand bullets to be visible all the time, we modify it's hidden state
      level0SeriesTemplate.bulletsContainer.hiddenState.properties.opacity = 1;
      level0SeriesTemplate.bulletsContainer.hiddenState.properties.visible = false;

      // create hover state
      const columnTemplate = level0SeriesTemplate.columns.template;
      const hoverState = columnTemplate.states.create("hover");
      level0SeriesTemplate.columns.template.url = "#";
      level0SeriesTemplate.columns.template.events.on(
        "hit",
        function (ev: any) {
          const item = ev.target.dataItem.dataContext;
          this.$router.push(item.url);
        },
        this
      );

      const bullet1 = level0SeriesTemplate.bullets.push(new am4charts.LabelBullet());
      bullet1.label.text = "[bold font-size: 36px]{name}\n[normal][font-size: 16px]{dataSize}";
      bullet1.label.fill = am4core.color("#ffffff");
      bullet1.label.horizontalCenter = "left";
      bullet1.label.verticalCenter = "top";
      bullet1.label.url = "{url}";

      // The url is a simple property, it's not really something that requires the
      // power of formatting. If we want to utilize label data or properties, we can
      // do so through adapters on the url property itself.
      bullet1.label.adapter.add("url", function (url, label) {
        const query = "";
        const data = label.dataItem;
        return url + query;
      });

      bullet1.adapter.add("locationX", function (x, target: any) {
        const value = target.dataItem.value;
        const BASE_SIZE = 0.03,
          BIG_TREEMAP_DATA_COUNT = 13;
        if (value < BIG_TREEMAP_DATA_COUNT) {
          return BASE_SIZE;
        }
        return BASE_SIZE - 0.01;
      });

      bullet1.adapter.add("locationY", function (y, target: any) {
        const value = target.dataItem.value;
        const BASE_SIZE = 0.91,
          TREEMAP_DATA_COUNT = {
            SMALL: 4,
            BIG: 13,
          };

        if (value < TREEMAP_DATA_COUNT["SMALL"]) {
          return BASE_SIZE;
        }

        if (value < TREEMAP_DATA_COUNT["BIG"]) {
          return BASE_SIZE + 0.04;
        }
        return BASE_SIZE + 0.07;
      });

      // darken
      hoverState.adapter.add("fill", function (fill, target: any) {
        if (fill instanceof am4core.Color) {
          const BRIGHTENESS_SIZE = -0.35; // 1 to -1
          return am4core.color(am4core.colors.brighten(fill.rgb, BRIGHTENESS_SIZE));
        }
        return fill;
      });
    },

    getDataCountLabelTextFormat(item) {
      let text = "";
      if (item.dataCount > 0) {
        text += "데이터 " + item.dataCount + "종";
      }
      if (item.imageCount > 0) {
        if (item.dataCount > 0) text += ",";
        text += "이미지 " + item.imageCount + "종";
      }
      return text;
    },
  },
});
