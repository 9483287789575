


























































































































import core from "@/core";
import EXIF from "exif-js";
import CoreKakaoMap from "@/core/core-kakao-map";
import DiseaseService from "@/services/disease/disease.service";
import mixins from "vue-typed-mixins";
import SearchAddressModal from "@/modals/core/SearchAddressModal.vue";
import Constant from "@/store/constant";
import UpdateEvent from "@/models";
import { UpdateEventType } from "@/types";
import { cloneDeep } from "lodash";

export default mixins().extend({
  name: "DiseaseImageComponent",
  components: {
    SearchAddressModal,
  },
  props: {
    properties: {
      type: Object,
      default: () => {
        return {
          cropCode: "",
          engine: {
            visible: false,
            versionList: [],
          },
        };
      },
    },
  },
  data() {
    return {
      engine: {
        visible: false,
        model: 0,
        versionList: [] as any,
      } as any,
      image: {
        status: "",
        name: "",
        src: "",
        clazz: "",
        item: null as any,
      },
      analysis: null as any,
      modal: {
        searchAddress: {
          visible: false,
          params: {
            type: "add",
            item: null,
            loadMyLocation: false,
          },
          updateEvent: null as UpdateEvent | null,
        },
      },
    };
  },
  mounted() {
    this.engine = cloneDeep(this.properties.engine);
    this.engine.model = 1;
  },
  watch: {
    engineVersion(engineVersion) {
      console.log("engineVersion : ", engineVersion);
    },
    "properties.cropCode"(cropCode) {
      this.clearImage();
    },

    "modal.searchAddress.updateEvent"(event) {
      if (event != null) {
        console.log("event : ", event);
        if (event.result === UpdateEventType.CONFIRM) {
          const item = event.item;
          const image = this.image;
          image.item.latitude = item.latitude;
          image.item.longitude = item.longitude;
          image.item.address1 = item.address1;
          image.item.address2 = item.address2;
          if (image.item.address2 == null) {
            image.item.address2 = "";
          }
          this.onAnalysisRequest();
        }
      }
    },
  },
  methods: {
    clearImage() {
      this.image.status = "";
      this.image.name = "";
      this.image.src = "";
      this.image.clazz = "";
      this.image.item = null;
      this.analysis = null;
    },
    onDrop(event) {
      this.inputImageFile(event.dataTransfer.files);
    },
    onChangeImageFile(e) {
      this.inputImageFile(e.target.files);
    },
    inputImageFile(files) {
      if (files.length) {
        let file = files[0];
        if (!/^image\//.test(file.type)) {
          alert("이미지 파일만 등록이 가능합니다");
          return false;
        }
        this.image.name = file.name;
        this.preview(file);
      } else {
        this.clearImage();
      }
    },
    async preview(file) {
      const image = this.image;
      core.loader.show("이미지 크기 변환중...");
      const item = (image.item = await core.utils.image.getImageBlob(file, 1980)) as any;
      //console.log("image : ", image);
      if (item.width < item.height) {
        image.clazz = "vertical";
      } else {
        image.clazz = "";
      }
      image.src = item.dataUri;
      image.status = "ready";
      core.loader.hide();

      function convertGpsDmsToDegree(degrees, minutes, seconds, direction) {
        let dd = degrees + minutes / 60 + seconds / (60 * 60);

        if (direction === "S" || direction === "W") {
          dd = dd * -1;
        } // Don't do anything for N or E
        return dd;
      }

      EXIF.getData(file, () => {
        const allMetaData = EXIF.getAllTags(this);
        //console.log("allMetaData : ", allMetaData);
        const gpaLatitude = allMetaData.GPSLatitude;
        const gpaLongitude = allMetaData.GPSLongitude;
        if (gpaLatitude != null && gpaLongitude != null) {
          const latDegree = gpaLatitude[0].numerator / gpaLatitude[0].denominator;
          const latMinute = gpaLatitude[1].numerator / gpaLatitude[1].denominator;
          const latSecond = gpaLatitude[2].numerator / gpaLatitude[2].denominator;
          const latDirection = allMetaData.GPSLatitudeRef;
          item.latitude = Number(
            convertGpsDmsToDegree(latDegree, latMinute, latSecond, latDirection).toFixed(7)
          );

          const lngDegree = gpaLongitude[0].numerator / gpaLongitude[0].denominator;
          const lngMinute = gpaLongitude[1].numerator / gpaLongitude[1].denominator;
          const lngSecond = gpaLongitude[2].numerator / gpaLongitude[2].denominator;
          const lngDirection = allMetaData.GPSLongitudeRef;
          item.longitude = Number(
            convertGpsDmsToDegree(lngDegree, lngMinute, lngSecond, lngDirection).toFixed(7)
          );
        }
      });
    },
    async onAnalysisRequest() {
      const image = this.image;
      //console.log("image : ", image);
      if (image.item.latitude == null || image.item.longitude == null) {
        //console.log("위치 지정 필요");
        const result = await core.alert.show({
          title: "알림",
          body: "사진 촬영 위치를 선택해주세요",
          showCancelButton: true,
          confirmButtonText: "계속",
        });
        if (result === "confirm") {
          this.modal.searchAddress.params.loadMyLocation = true;
          this.modal.searchAddress.visible = true;
        }
        return;
      }
      core.loader.show("병해 영상 분석 중...");
      try {
        const formData = new FormData();
        formData.append("fileObj", image.item.blob, image.item.filename);
        formData.append("latitude", image.item.latitude);
        formData.append("longitude", image.item.longitude);
        if (image.item.address1 == null) {
          // 주소 가져오기
          const kakaoMap = new CoreKakaoMap({}, core, Constant.kakao.restApiKey);
          const result = (await kakaoMap.findAddressByLatAndLng(
            image.item.latitude,
            image.item.longitude
          )) as any;
          image.item.address1 = result.address;
          image.item.address2 = result.buildingName;
          if (image.item.address2 == null) image.item.address2 = "";
          //console.log("result : ", result);
        }
        formData.append("address1", image.item.address1);
        formData.append("address2", image.item.address2);
        formData.append("cropCode", this.properties.cropCode);
        if (this.engine.visible) {
          const item = this.engine.versionList[this.engine.model];
          formData.append("engine", item.id);
        }
        const analysis = (await DiseaseService.imageAnalysis(formData)) as any;

        image.status = "result";
        this.analysis = analysis;
        // 테스트용 코드
        // if (analysis.analysisImageUrl == null) {
        //   analysis.analysisImageUrl = analysis.imageUrl;
        // }
      } catch (e) {
        console.log(e);
      }

      core.loader.hide();
    },
    getAnalysisInfoUrl(analysisResult) {
      return DiseaseService.getInfoUrl(analysisResult.name, analysisResult.similarity);
    },
  },
});
