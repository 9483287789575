










































































































































































import core from "@/core";
import DiseaseService from "@/services/disease/disease.service";
import mixins from "vue-typed-mixins";
import _ from "lodash";

export default mixins().extend({
  name: "DiseaseImageAnnotationComponent",
  components: {},
  props: {
    properties: {
      type: Object,
      default: () => {
        return {
          cropCode: "",
          analysis: null,
        };
      },
    },
  },
  data() {
    return {
      analysis: null as any,
      drawing: {
        isDrawing: false,
        isStarted: false,
        imageWidth: 0,
        imageHeight: 0,
        startX: 0,
        startY: 0,
        width: 0,
        height: 0,
      },
      popover: {
        addButton: {
          header: "Popover!",
          content: "이 버튼으로 주석을 추가할 수 있습니다.",
          active: true,
          placement: "bottom-end",
        },
      },
      resultStatus: "ANNOTATION",
      resultStatusList: [
        { label: "주석", value: "ANNOTATION" },
        { label: "병충해 없음", value: "NONE" },
      ],
    };
  },
  watch: {
    "properties.cropCode"(cropCode) {
      this.clearImage();
    },

    "properties.analysis"(analysis) {
      if (analysis != null) {
        //console.log("change analysis : ", JSON.stringify(analysis));
        const drawing = this.drawing;
        drawing.isDrawing = false;
        drawing.startX = 0;
        drawing.startY = 0;
        drawing.width = 0;
        drawing.height = 0;
        drawing.isStarted = false;
        drawing.imageWidth = 0;
        drawing.imageHeight = 0;

        if (analysis.annotations == null) analysis.annotations = [];
        this.analysis = _.cloneDeep(analysis);
        //console.log(this.analysis);

        this.changedAnalysis();
      }
    },
  },
  methods: {
    clearImage() {
      this.analysis = null;
      this.properties.analysis = null;
      this.changeDrawing("cancel");
    },
    getImageClass() {
      if (this.analysis != null && this.analysis.clazz != null) {
        return this.analysis.clazz;
      }
      return "";
    },
    changeDrawing(result) {
      const drawing = this.drawing;
      let isDrawing = false;
      if (result === "add") {
        isDrawing = true;
      } else if (result === "confirm") {
        // 추가
        this.addAnnotation();
      } else {
        if (drawing.isDrawing) {
          this.changedAnalysis();
        }
      }
      drawing.isDrawing = isDrawing;
      if (!isDrawing) {
        drawing.startX = 0;
        drawing.startY = 0;
        drawing.width = 0;
        drawing.height = 0;
      }
    },
    async save() {
      try {
        const analysis = this.analysis;

        const params = {
          resultStatus: this.resultStatus,
          annotations: [],
        };
        if (params.resultStatus === "ANNOTATION") {
          params.annotations = analysis.annotations;
        }
        const resultAnalysis = (await DiseaseService.update(analysis.id, params)) as any;
        const propsAnalysis = this.properties.analysis;
        for (const key of Object.keys(resultAnalysis)) {
          propsAnalysis[key] = resultAnalysis[key];
        }
        this.properties.analysis = null;
        this.properties.analysis = propsAnalysis;
        await core.alert.show({
          title: "알림",
          body: "저장되었습니다",
        });

        if (this.resultStatus === "NONE") {
          this.clearImage();
          this.properties.callback = { type: "DELETE", item: propsAnalysis };
        }
      } catch (e) {
        console.log(e);
      }
    },
    removeAnnotation(item) {
      let index = null;
      const annotations = this.analysis.annotations;
      annotations.some((annotation, _index) => {
        if (annotation.id === item.id) {
          index = _index;
          return true;
        }
      });
      if (index != null) {
        annotations.splice(index, 1);
        annotations.forEach((annotation, _index) => {
          annotation.id = _index + 1;
        });
        console.log("changed annotations");
        this.changedAnalysis();
      } else {
        console.log("not found annotation index : ", index);
      }
      //console.log("annotation length : ", analysis.annotations.length);
    },
    addAnnotation() {
      const drawing = this.drawing;

      let id = 1;
      const annotations = this.analysis.annotations;
      annotations.forEach((annotation) => {
        id = annotation.id + 1;
      });

      const annotation = {
        id: id,
        name: "",
        minX: drawing.startX,
        minY: drawing.startY,
        maxX: drawing.startX + drawing.width,
        maxY: drawing.startY + drawing.height,
      };

      if (annotation.minX > annotation.maxX) {
        const temp = annotation.minX;
        annotation.minX = annotation.maxX;
        annotation.maxX = temp;
      }

      if (annotation.minY > annotation.maxY) {
        const temp = annotation.minY;
        annotation.minY = annotation.maxY;
        annotation.maxY = temp;
      }

      annotations.push(annotation);

      this.drawAnnotation(annotation);
    },
    getLineWidth() {
      const drawing = this.drawing;
      let maxSize = drawing.imageWidth;
      if (maxSize < drawing.imageHeight) {
        maxSize = drawing.imageHeight;
      }
      return parseInt(String(maxSize / 200));
    },
    getFontSize() {
      const drawing = this.drawing;
      let maxSize = drawing.imageWidth;
      if (maxSize < drawing.imageHeight) {
        maxSize = drawing.imageHeight;
      }
      return parseInt(String(maxSize / 20));
    },
    drawAnnotation(item) {
      const lineWidth = this.getLineWidth();
      const fontSize = this.getFontSize();

      const canvas = this.$refs.canvas as any;
      const ctx = canvas.getContext("2d");
      ctx.strokeStyle = "black";
      ctx.fillStyle = "black";
      ctx.rect(item.minX, item.minY, item.maxX - item.minX, item.maxY - item.minY);
      ctx.lineWidth = lineWidth;
      ctx.stroke();
      ctx.font = fontSize + "px Arial";

      ctx.fillText("ID " + item.id, item.minX - (lineWidth + 1), item.minY - (lineWidth + 5));
    },
    changedAnalysis() {
      const analysis = this.analysis;
      const canvas = this.$refs.canvas as any;
      const drawingCanvas = this.$refs.drawingCanvas as any;
      const resultStatus = analysis.resultStatus;
      if (resultStatus === "ANNOTATION" || resultStatus === "NONE") {
        this.resultStatus = resultStatus;
      } else {
        this.resultStatus = "ANNOTATION";
      }
      // const drawingCanvas = this.$refs.drawingCanvas;
      const ctx = canvas.getContext("2d") as any;
      // this.drawing.offsetX = $el.offsetLeft
      // this.drawing.offsetY = $el.offsetTop;

      const image = new Image();

      const drawing = this.drawing;
      const drawAnnotation = this.drawAnnotation;
      image.onload = function (e) {
        ctx.canvas.width = image.width;
        ctx.canvas.height = image.height;
        drawingCanvas.width = image.width;
        drawingCanvas.height = image.height;
        if (image.width < image.height) {
          analysis.clazz = "vertical";
        } else {
          analysis.clazz = "";
        }
        drawing.imageWidth = image.width;
        drawing.imageHeight = image.height;
        //console.log(`width : ${image.width}, height : ${image.height}`);
        // drawingCanvas.width = image.width;
        // drawingCanvas.height = image.height;
        // $el.width = image.width;
        // $el.height = image.height;
        ctx.drawImage(image, 0, 0);
        if (analysis.annotations != null) {
          analysis.annotations.forEach((label) => {
            // label.id, label.minX, label.maxX, label.minY, label.maxY
            drawAnnotation(label);
          });
        }
      };
      image.style.display = "inline";
      image.src = analysis.imageUrl;
    },
    handleMouseDown(e) {
      e.preventDefault();
      e.stopPropagation();
      const drawing = this.drawing;
      if (!drawing.isDrawing) {
        return;
      }
      // save the starting x/y of the rectangle
      const canvas = this.$refs.drawingCanvas as any;
      drawing.startX = parseInt(String((e.offsetX / canvas.clientWidth) * drawing.imageWidth));
      drawing.startY = parseInt(String((e.offsetY / canvas.clientHeight) * drawing.imageHeight));

      // set a flag indicating the drag has begun
      drawing.isStarted = true;
    },

    handleMouseMove(e) {
      const drawing = this.drawing;
      // if we're not dragging, just return
      if (!drawing.isStarted) {
        return;
      }

      // Put your mousemove stuff here
      const canvas = this.$refs.drawingCanvas as any;
      const ctx = canvas.getContext("2d");
      const lineWidth = this.getLineWidth();
      ctx.lineWidth = lineWidth;

      // get the current mouse position
      const mouseX = parseInt(String((e.offsetX / canvas.clientWidth) * drawing.imageWidth));
      const mouseY = parseInt(String((e.offsetY / canvas.clientHeight) * drawing.imageHeight));

      // clear the canvas
      ctx.clearRect(0, 0, drawing.imageWidth, drawing.imageHeight);

      // calculate the rectangle width/height based
      // on starting vs current mouse position
      drawing.width = mouseX - drawing.startX;
      drawing.height = mouseY - drawing.startY;

      // draw a new rect from the start position
      // to the current mouse position
      ctx.strokeRect(drawing.startX, drawing.startY, drawing.width, drawing.height);
    },

    handleMouseUp(e) {
      e.preventDefault();
      e.stopPropagation();

      const drawing = this.drawing;

      // the drag is over, clear the dragging flag
      drawing.isStarted = false;
    },

    async handleMouseOut(e) {
      e.preventDefault();
      e.stopPropagation();

      const drawing = this.drawing;

      // the drag is over, clear the dragging flag
      drawing.isStarted = false;
    },
  },
});
